import { api, timezone } from "@/config/config";

export const constants = {
  timezone: timezone ?? "America/New_York",
  currencySymbol: "$",
  googleApiKey: "AIzaSyD6d1bH_S6ZZd8W5ap6Y964poiri-Fs7Os",

  upload_action_url: api.host + "api/image-upload",

  // AUTH URL
  loginUrl: "api/login",
  logoutUrl: "api/logout",
  userAfterIdleValidation: "api/user-validation",
  loginIntoOtherEmployeeAccountUrl: "api/login-into-other-employee-account",
  refreshTokenUrl: "api/refresh-token",
  loginUserDetailsUrl: "api/login-user-details",
  forgotPasswordUrl: "api/forgot-password",
  resetPasswordUrl: "api/reset-password",
  changePasswordUrl: "api/change-password",
  resendOtpUrl: "api/resend-otp",
  sendOfferLetterOtpUrl: "api/send-offer-letter-otp",
  offerLetterOtpVerificationUrl: "api/offer-letter-otp-verification",
  offerLetterPreviewUrl: "api/offer-letter-preview",
  offerLetterSignUrl: "api/offer-letter-sign",
  offerLetterDeclineUrl: "api/offer-letter-decline",
  me: "api/me",
  employeePasswordReset: "api/employee-password-reset",
  hippaComplianceAgreed: "api/hippa-compliance-agreed",
  exportHippaComplincePdf: "api/export-hippa-compliance-pdf",
  handbookAgreed: "api/handbook-agreed",
  agreementAcceptUrl: "api/agreement-accept",
  exportHandbookPdf: "api/export-handbook-pdf",


  //DAYNAMIC_FORM
  CompetencyChecklistStore: "api/competency-checklist-store",
  CompetencyChecklistPDf: "api/competency-checklist-pdf",

  // CREDENTIAL APPROVALS
  credentialApprovalsDatatableUrl: "api/employee-certificates",
  credentialApprovalsApprovalUrl: "api/employee-certificate/approve",
  credentialApprovalsssndlApprovalUrl: "api/employee-certificate/ssn-dl-approve",
  credentialApprovalsUpdateUrl: "api/employee-certificate/update",

  // DASHBOARD URL
  getCertificateExpirationsUrl: "api/get-certificate-expirations",
  chatGPT: 'api/chat-gpt/ask',
  chatHistory: 'api/chat-gpt/history',
  chatGPTExportHistory: 'api/chat-gpt/export-history',

  // GET DATA URL
  getAnnouncements: "api/get-announcements",
  getDashboardUrl: "api/get-dashboard-data",
  thingsToDoApi: "api/admin/things-to-do",
  thingsToDoMissingBackgroundCheckExcel:
    "api/admin/things-to-do/missing-background-check-and-drug-document-employees/excel",
  getDashboardCaseReportChartDataUrl:
    "api/get-dashboard-case-report-chart-data",
  getDashboardExpenseChartDataUrl: "api/get-dashboard-expense-chart-data",
  getDashboardServiceByStatesChartDataUrl:
    "api/get-dashboard-service-by-states-chart-data",
  getDashboardEmployeeChartDataUrl: "api/get-dashboard-employee-chart-data",
  getDashboardStateWiseDataUrl:
    "api/get-dashboard-state-wise-data-for-country-chart",
  getDashboardDueAndUpcomingPeUrl:
    "api/get-dashboard-due-and-upcoming-pe",
  getCompaniesUrl: "api/get-companies",
  getCertificationTypesUrl: "api/get-certification-types",
  getRolesUrl: "api/get-roles",
  getAccessRoutesUrl: "api/get-access-routes",
  getManufacturersUrl: "api/get-manufacturers",
  getProductCategoriesUrl: "api/get-product-categories",
  getUnitOfMeasurementsUrl: "api/get-unit-of-measurements",
  getProductsUrl: "api/get-products",
  getServicesUrl: "api/get-services",
  getHospitalsUrl: "api/get-hospitals",
  getExpenseTypesUrl: "api/get-expense-types",
  getShiftTypesUrl: "api/get-shift-types",
  getDistanceUrl: "api/get-distancematrix-distance",
  getHospitalWiseProductsUrl: "api/get-hospital-wise-products",
  getRemainingPtoUrl: "api/get-remaining-pto",
  getCaseServicesUrl: "api/get-case-services",
  getHospitalWiseCaseServicesUrl: "api/get-hospital-wise-case-services",
  getEmployeesUrl: "api/get-employees",
  getPendingCountsForAdmin: "api/get-pending-counts-for-admin",
  storeEmployeeCertificate: "api/employee/store-certificate",
  getEmployeeCertificates: "api/employee/get-certificates",
  deleteEmployeeCertificates: "api/employee/delete-certificate",
  checkEmployeeProfileStatusUrl: "api/employee/check-profile-status",
  getEmployeeJobs: "api/get-employee-jobs",
  getEmployeeLastEvaluationUrl:
    "api/get-employee-last-annual-performance-evaluation",
  getQuickbooksData: "api/get-quickbooks-data",
  checkThirdPartyLogins: "api/check-third-party-logins",
  getAssetManufacturersUrl: "api/get-asset-manufacturers",
  getAssetCategoriesUrl: "api/get-asset-categories",
  getAssetProductsUrl: "api/get-asset-products",
  getAssetInventoryUrl: "api/get-asset-inventory",
  downloadFile: "api/download-file",
  getHospitalPersonnelsUrl: "api/get-hospital-personnels",
  getEmployeePositionsUrl: "api/get-employee-positions",
  getHospitalTemplates: "api/get-hospital-templates",
  getPolicyGroups: "api/get-policy-groups",
  getOnboardingDocuments: "api/get-onboarding-documents",

  // COMPANIES URL
  companyDatatableUrl: "api/companies",
  companyStoreUrl: "api/company/store",
  companyChangeStatusUrl: "api/company/change-status",
  companyBulkActionUrl: "api/companies/bulk-action",

  // HOSPITAL URL
  hospitalDatatableUrl: "api/hospitals",
  facilityDatatableUrl: "api/facilities",
  hospitalStoreUrl: "api/hospital/store",
  hospitalChangeStatusUrl: "api/hospital/change-status",
  hospitalBulkActionUrl: "api/hospitals/bulk-action",
  hospitalDetailUrl: "api/hospital/detail",
  hospitalCaseServicePricesUrl: "api/hospital/case-service-prices",
  hospitalManufacturersUrl: "api/hospital/manufacturers",
  hospitalGetManufacturers:"api/hospital/get-manufacturers",
  hospitalProductExcelExportUrl: "api/hospital/export-hospital-product-excel",
  hospitalProductExcelImportUrl: "api/hospital/import-hospital-product-excel",
  hospitalProductImportFromHospitalUrl: "api/hospital/product-import-from-hospital",
  hospitalExpensesUrl: "api/hospital/expenses",
  hospitalTimeTracksUrl: "api/hospital/time-tracks",
  hospitalGetNotificationUrl: "api/hospital/get-notification",
  hospitalStoreNotificationUrl: "api/hospital/store-notification",
  hospitalAssetExportExcelUrl: "api/hospital/asset/export-excel",
  hospitalExportExcelUrl: "api/hospitals/export-excel",
  hospitalStoreOnboardingUrl: "api/hospital/store-onboarding",
  hospitalAnalyticsUrl: "api/hospital/analytics",
  hospitalAnalyticDueInvoiceListUrl: "api/hospital/analytic/due-invoice-list",
  hospitalSalesPerformanceAnalyticsUrl:
    "api/hospital/analytic/sale-performance",
  hospitalClassWiseProfitAndLossAnalyticsUrl:
    "api/hospital/analytic/class-wise-profit-and-loss",
  hospitalNoOfCaseReportsPerMonthUrl: "api/hospital/analytic/no-of-case-reports-per-month",

  // HOSPITAL CASE SERVICE URL
  hospitalCaseServicesUrl: "api/hospital/case-services",
  hospitalCaseServiceStoreUrl: "api/hospital/case-service/store",
  hospitalCaseServiceDeleteUrl: "api/hospital/case-service/delete",
  hospitalCaseServicePdfExport: "api/hospital/case-service/pdf",
  hospitalCaseServiceExcelExport: "api/hospital/case-service/excel",

  // HOSPITAL PRODUCT PRICE URL
  hospitalProductPricesUrl: "api/hospital/product-prices",
  hospitalProductPriceStoreUrl: "api/hospital/product-price/store",
  hospitalProductPriceDeleteUrl: "api/hospital/product-price/delete",

  // HOSPITAL CONTRACTS
  hospitalContractsUrl: "api/hospital/contracts",
  hospitalContractStoreUrl: "api/hospital/contract/store",
  hospitalContractDeleteUrl: "api/hospital/contract/delete",

  // HOSPITAL PERSONNEL URL
  hospitalPersonnelsUrl: "api/hospital/personnels",
  hospitalPersonnelStoreUrl: "api/hospital/personnel/store",
  hospitalPersonnelDeleteUrl: "api/hospital/personnel/delete",
  hospitalPersonnelSampleFileUrl: "api/hospital/personnel/sample-file",
  hospitalPersonnelBulkUploadUrl: "api/hospital/personnel/bulk-upload",

  // HOSPITAL FTP
  hospitalFtpUrl: "api/hospital/ftp",
  hospitalFtpStoreUrl: "api/hospital/ftp/store",

  // HOSPITAL PERSONNEL URL
  hospitalOnboardingAttachmentsUrl: "api/hospital/onboarding-attachments",
  hospitalOnboardingAttachmentStoreUrl:
    "api/hospital/onboarding-attachment/store",
  hospitalOnboardingAttachmentDeleteUrl:
    "api/hospital/onboarding-attachment/delete",

  // CLINICAL SITE URL
  clinicalSiteDatatableUrl: "api/clinical-sites",

  // CERTIFICATION TYPE URL
  certificationTypeDatatableUrl: "api/certification-types",
  certificationTypeStoreUrl: "api/certification-type/store",
  certificationTypeChangeStatusUrl: "api/certification-type/change-status",
  certificationTypeBulkActionUrl: "api/certification-types/bulk-action",

  // HOSPITAL TEMPLATES URL
  hospitalTemplatesDatatableUrl: "api/hospital-templates",
  hospitalTemplatesStoreUrl: "api/hospital-template/store",
  hospitalTemplatesChangeStatusUrl: "api/hospital-template/delete",
  hospitalTemplatesShowUrl: "api/hospital-template/show",

  // EXPENSE TYPE URL
  expenseTypeDatatableUrl: "api/expense-types",
  expenseTypeStoreUrl: "api/expense-type/store",
  expenseTypeChangeStatusUrl: "api/expense-type/change-status",
  expenseTypeBulkActionUrl: "api/expense-types/bulk-action",

  // SHIFT TYPE URL
  shiftTypeDatatableUrl: "api/shift-types",
  shiftTypeStoreUrl: "api/shift-type/store",
  shiftTypeChangeStatusUrl: "api/shift-type/change-status",
  shiftTypeBulkActionUrl: "api/shift-types/bulk-action",

  // MASTER HANDBOOK URL
  masterHandbookDatatableUrl: "api/master-handbooks",
  masterHandbookStoreUrl: "api/master-handbook/store",
  masterHandbookChangeStatusUrl: "api/master-handbook/change-status",
  masterHandbookBulkActionUrl: "api/master-handbooks/bulk-action",
  employeeHandbookUrl: "api/employee-handbook",

  // ROLE URL
  roleDatatableUrl: "api/roles",
  roleStoreUrl: "api/role/store",
  roleChangeStatusUrl: "api/role/change-status",
  roleBulkActionUrl: "api/roles/bulk-action",

  // MANUFACTURER URL
  manufacturerDatatableUrl: "api/manufacturers",
  manufacturerStoreUrl: "api/manufacturer/store",
  manufacturerChangeStatusUrl: "api/manufacturer/change-status",
  manufacturerBulkActionUrl: "api/manufacturers/bulk-action",

  // PRODUCT URL
  productDatatableUrl: "api/products",
  productStoreUrl: "api/product/store",
  productBulkUploadSampleFileUrl: "api/product/bulk-upload/sample-file",
  productBulkUploadUrl: "api/product/bulk-upload",
  productChangeStatusUrl: "api/product/change-status",
  productBulkActionUrl: "api/products/bulk-action",

  // PRODUCT CATEGORY URL
  productCategoryDatatableUrl: "api/product-categories",
  productCategoryStoreUrl: "api/product-category/store",
  productCategoryChangeStatusUrl: "api/product-category/change-status",
  productCategoryBulkActionUrl: "api/product-categories/bulk-action",

  // UNIT OF MEASUREMENT URL
  uomDatatableUrl: "api/unit-of-measurements",
  uomStoreUrl: "api/unit-of-measurement/store",
  uomChangeStatusUrl: "api/unit-of-measurement/change-status",
  uomBulkActionUrl: "api/unit-of-measurements/bulk-action",

  // ORDERS URL FOR EMPLOYEE
  orderDatatableUrl: "api/orders",
  orderStoreUrl: "api/order/store",
  orderViewUrl: "api/order/view",
  orderDeleteUrl: "api/order/delete",
  orderProductMarkAsReceivedUrl: "api/order/product/mark-as-received",
  orderProductRevertReceivedUrl: "api/order/product/revert-received",

  // ORDER DISPATCH FOR EMPLOYEE
  dispatchedOrdersDatatableUrl: "api/dispatched-orders",
  dispatchedOrderItemReceivedUrl: "api/dispatched-order/item-received",

  // ORDERS URL FOR ADMIN
  adminOrderDatatableUrl: "api/admin/orders",
  adminOrderViewUrl: "api/admin/order/view",
  adminOrderUpdateUrl: "api/admin/order/update",
  adminOrderDispatchUrl: "api/admin/order/dispatch",
  adminOrderRejectUrl: "api/admin/order/reject",
  adminOrderStatusChangeUrl: "api/admin/order/status-change",
  adminOrderBulkActionUrl: "api/admin/order/bulk-action",
  adminOrderprocessMailUrl: "api/admin/order/send-order-process-mail",
  adminDispatchOrderReminderMail:
    "api/admin/order/send-dispatched-order-remainder-mail",
  adminOrderFlagUrl: "api/admin/order/flag",
  adminOrderUnflagUrl: "api/admin/order/unflag",
  adminOrderExportExcelUrl: "api/admin/orders/export-excel",
  adminOrderAddNoteUrl: "api/admin/order/add-note",

  // ADMIN ORDER DISPATCH
  adminDispatchedOrdersDatatableUrl: "api/admin/dispatched-orders",
  adminDispatchedOrderItemReceivedUrl:
    "api/admin/dispatched-order/item-received",
  adminDispatchedOrderCreatePurchaseOrderUrl:
    "api/admin/dispatched-order/create-purchase-order",

  // EMAIL TEMPLATES URL
  emailTemplatesDatatableUrl: "api/email-templates",
  emailTemplateStoreUrl: "api/email-template/store",
  emailTemplateViewUrl: "api/email-template/view",
  emailTemplateChangeStatusUrl: "api/email-template/change-status",

  // CALENDAR EVENTS URL
  calendarEventsUrl: "api/calendar-events",
  calendarEventDatatableUrl: "api/calendar-event-datatable",
  calendarEventStoreUrl: "api/calendar-event/store",
  calendarEventViewUrl: "api/calendar-event/view",
  calendarEventUpdateUrl: "api/calendar-event/update",
  calendarEventBookUrl: "api/calendar-event/book",
  calendarEventChangeStatusUrl: "api/calendar-event/change-status",
  calendarEventBulkActionUrl: "api/calendar-event/bulk-action",

  // EMPLOYEE URL
  employeeDatatableUrl: "api/employees",
  employeeStoreUrl: "api/employee/store",
  employeeUpdateEmailUrl: "api/employee/update-email",
  employeeHospitalStoreUrl: "api/employee/hospital-store",
  employeeShowUrl: "api/employee/show",
  employeeHospitalShowUrl: "api/employee/hospital-show",
  employeeChangeStatusUrl: "api/employee/change-status",
  employeeTerminateUrl: "api/employee/terminate",
  employeeBulkActionsUrl: "api/employees/bulk-action",
  // importEmployeeFromGustoInitUrl: "api/import-employee-from-gusto-init",
  // syncEmployeeFromGustoInitUrl: "api/sync-employee-from-gusto-init",
  employeeExpensesUrl: "api/employee/expenses",
  employeeTimeTracksUrl: "api/employee/time-tracks",
  employeeJobStoreUrl: "api/employee/job/store",
  employeeJobDeleteUrl: "api/employee/job/delete",
  employeeSendWelcomeEmailUrl: "api/employee/send-welcome-email",
  employeeGetNotificationUrl: "api/employee/get-notification",
  employeeStoreNotificationUrl: "api/employee/store-notification",
  employeeSendCredentialRemainderMailUrl:
    "api/employee/send-credential-remainder-mail",
  employeeExportExcelUrl: "api/employee/export-excel",
  employeeDownloadCredentialingZipUrl:
    "api/employee/download-credentialing-zip",
  employeeDownloadCredentialingPdfUrl:
    "api/employee/download-credentialing-pdf",
  employeeDownloadAttestationPdfUrl: "api/employee/download-attestation-pdf",
  employeeAnalyticsPdfUrl: "api/employee/analytics",
  employeeDownloadDocumentsZipUrl: "api/employee/download-documents-zip",
  employeeOnboardingLogs: "api/onboarding-logs",
  employeeOnboardingStore: "api/onboarding-log/store",
  employeeOnboardingLogsSign: "api/onboarding-logs/signed-and-agreed",
  employeeDocuments: "api/documents",
  employeeDocumentDelete: "api/document/delete",
  employeeDocumentStore: "api/document/store",
  employeeDocumentUpdate: "api/document/update",
  employeeRehire: "api/employee/rehire",

  // COMPETENCY ASSESSMENT
  employeeCompetencyAssessmentUrl: "api/employee-assessments",
  employeeCompetencyAssessmentPreview: "api/employee-assessment/preview",
  employeeCompetencyAssessmentStore: "api/employee-assessment/store",
  employeeCompetencyAssessmentSendMail: "api/employee-assessment/send-mail",
  employeeCompetencyAssessmentShow: "api/employee-assessment/show",
  employeeCompetencyAssessmentDelete: "api/employee-assessment/delete",
  employeeCompetencyAssessmentSubmitFeedback:
    "api/employee-assessment/feedback",
  employeeCompetencyAssessmentPdfPreview: "api/employee-assessment/pdf-preview",


  // COMPETENCY FORM
  CompentencyFormUrl: "api/compentency-form/index",
  CompentencyFormPreviewPdf: "api/compentency-form/preview-pdf",
  CompentencyFormShow: "api/compentency-form/show",
  CompentencyFormFormSubmit: "api/compentency-form/form-submit",
  CompentencyFormDelete: "api/compentency-form/delete",
  CompentencyFormSendMail: "api/compentency-form/send-mail",
  // GetManagers: "api/get-managers",

  // COMPETENCY EXAM
  CompentencyExamUrl: "api/compentency-exam/index",
  CompentencyExamPreviewPdf: "api/compentency-exam/preview-pdf",
  CompentencyExamShow: "api/compentency-exam/show",
  CompentencyExamFormSubmit: "api/compentency-exam/form-submit",
  CompentencyExamDelete: "api/compentency-exam/delete",
  CompentencyExamSendMail: "api/compentency-exam/send-mail",

  // COMPLETENESS OF PERSONNEL FILE
  employeeCompletenessOfPersonnelFileUrl: "api/completeness-of-personnel-files",

  // EMPLOYEE MANAGER URL
  getEmployeeManagersUrl: "api/employee-managers",
  employeeManagerStoreUrl: "api/employee-manager/store",
  employeeManagerRemoveUrl: "api/employee-manager/remove",
  employeeManagerDashboardWidgetUrl: "api/employee-manager/dashboard-widget",

  // GUSTO
  gustoLoginUrl: "api/gusto-login",
  importEmployeeFromGustoUrl: "api/import-employee-from-gusto",
  syncEmployeeFromGustoUrl: "api/sync-employee-from-gusto",

  // QUICKBOOKS
  quickbooksLoginUrl: "api/quickbooks-login",
  syncVendorFromQuickbooksUrl: "api/sync-vendors",
  getAccountFromQuickbooksUrl: "api/get-accounts",
  uploadExpensesOnQuickbooksUrl: "api/upload-expenses",
  updateExpensesFromQuickbooksUrl: "api/update-expenses",
  uploadTimesheetOnQuickbooksUrl: "api/upload-timesheets",
  updateTimesheetFromQuickbooksUrl: "api/update-timesheets",
  uploadInvoiceOnQuickbooksUrl: "api/upload-invoices",
  uploadMonthlyInvoiceOnQuickbooksUrl: "api/upload-monthly-invoices",
  sendInvoiceOnQuickbooksUrl: "api/send-invoices",

  // syncVendorFromQuickbooksInitUrl: "api/sync-vendors-init",
  // getAccountFromQuickbooksInitUrl: "api/get-accounts-init",
  // uploadExpensesOnQuickbooksInitUrl: "api/upload-expenses-init",
  // updateExpensesFromQuickbooksInitUrl: "api/update-expenses-init",
  // uploadTimesheetOnQuickbooksInitUrl: "api/upload-timesheets-init",
  // updateTimesheetFromQuickbooksInitUrl: "api/update-timesheets-init",

  // EMPLOYEE ROLE AND RIGHTS UPDATE URLS
  updateRoleAndRightsUrl: "api/employee/update-role-and-rights",

  // ONBOARDING EMPLOYEE URL
  onboardingPersonalUrl: "api/onboarding-person/personal-details",
  onboardingAddressUrl: "api/onboarding-person/address-details",
  onboardingPositionUrl: "api/onboarding-person/position-details",
  onboardingEmploymentUrl: "api/onboarding-person/employment-details",
  onboardingPayUrl: "api/onboarding-person/pay-details",
  onboardingEmployeeUrl: "api/onboarding-person-details",

  // INSPERITY URLS FROM OUR BACKEND
  insLocationsUrl: "api/insperity/locations",
  insReportingLocationsUrl: "api/insperity/reporting-locations",
  insDeliveryLocationsUrl: "api/insperity/delivery-locations",
  insWorkersCompCodeUrl: "api/insperity/workers-comp-code",
  insJobFunctionsUrl: "api/insperity/job-functions",
  insJobCategoriesUrl: "api/insperity/job-categories",
  insSyncEmployeesUrl: "api/insperity/sync-employees",

  // EXPENSE URL FOR EMPLOYEES
  expenseDatatableUrl: "api/expenses",
  expenseStoreUrl: "api/expense/store",
  expenseShowUrl: "api/expense/show",
  expenseDeleteUrl: "api/expense/delete",
  expenseCheckDateUrl: "api/expense/check-date",

  // EXPENSE URL FOR ADMIN
  adminExpenseDatatableUrl: "api/admin/expenses",
  adminExpenseShowUrl: "api/admin/expense/show",
  adminExpenseUpdateUrl: "api/admin/expense/update",
  adminExpenseChangeStatusUrl: "api/admin/expense/change-status",
  adminExpenseBulkActionsUrl: "api/admin/expenses/bulk-action",
  adminExpenseChangeQuickbooksClassUrl: "api/admin/expense/change-quickbooks-class",
  adminExpenseExportPdfUrl: "api/admin/expenses/export-pdf",
  adminExpenseExportExcelUrl: "api/admin/expenses/export-excel",
  adminExpenseAddQuickbooksBillIdUrl: "api/admin/expense/add-quickbooks-bill-id",
  adminExpenseStatusChangeToPendingUrl: "api/admin/expense/status-change-to-pending",

  // EMPLOYEE TIME TRACK URL FOR EMPLOYEE
  employeeTimeTrackDatatableUrl: "api/employee-time-tracks",
  employeeTimeTrackStoreUrl: "api/employee-time-track/store",
  employeeTimeTrackShowUrl: "api/employee-time-track/show",
  employeeTimeTrackDeleteUrl: "api/employee-time-track/delete",
  employeeTimeTrackCheckDateUrl: "api/employee-time-track/check-date",

  // EMPLOYEE TIME TRACK URL FOR ADMIN
  adminEmployeeTimeTrackDatatableUrl: "api/admin/employee-time-tracks",
  adminEmployeeTimeTrackShowUrl: "api/admin/employee-time-track/show",
  adminEmployeeTimeTrackUpdateUrl: "api/admin/employee-time-track/update",
  adminEmployeeTimeTrackChangeStatusUrl: "api/admin/employee-time-track/change-status",
  adminEmployeeTimeTrackBulkActionUrl: "api/admin/employee-time-tracks/bulk-action",
  adminEmployeeTimeTrackExportPdfUrl: "api/admin/employee-time-tracks/export-pdf",
  adminEmployeeTimeTrackExportExcelUrl: "api/admin/employee-time-tracks/export-excel",
  adminEmployeeTimeTrackSendPayrollNotificationUrl: "api/admin/employee-time-track/send-payroll-notification",
  adminEmployeeTimeTrackStatusChangeToPendingUrl: "api/admin/employee-time-track/status-change-to-pending",

  // TIME TRACKING BILLS URL FOR ADMIN
  adminBillPayrollDatesUrl: "api/admin/bill-payroll-dates",
  adminBillDatatableUrl: "api/admin/bills",
  adminBillExportExcelUrl: "api/admin/bill/export-excel",
  adminAllBillsExportExcelUrl: "api/admin/all-bills/export-excel",

  // SERVICES URL
  serviceDatatableUrl: "api/services",
  serviceStoreUrl: "api/service/store",
  serviceShowUrl: "api/service/show",
  serviceChangeStatusUrl: "api/service/change-status",
  serviceBulkActionUrl: "api/service/bulk-action",

  // CASE SERVICES URL
  caseServiceDatatableUrl: "api/case-services",
  caseServiceStoreUrl: "api/case-service/store",
  caseServiceChangeStatusUrl: "api/case-service/change-status",
  caseServiceBulkActionUrl: "api/case-service/bulk-action",

  // PAID TIME OFF URL
  paidTimeOffDatatableUrl: "api/paid-time-offs",
  paidTimeOffStoreUrl: "api/paid-time-off/store",
  paidTimeOffShowUrl: "api/paid-time-off/show",
  paidTimeOffCancelUrl: "api/paid-time-off/cancel",
  paidTimeOffDeleteUrl: "api/paid-time-off/delete",

  // PAID TIME OFF URL FOR ADMIN
  adminPaidTimeOffDatatableUrl: "api/admin/paid-time-offs",
  adminPaidTimeOffCalendarUrl: "api/admin/paid-time-offs/calendar-view",
  adminPaidTimeOffStatusChangeUrl: "api/admin/paid-time-off/status-change",
  adminPaidTimeOffApproveCancellationUrl: "api/admin/paid-time-off/approve-cancellation",
  adminPaidTimeOffDeleteUrl: "api/admin/paid-time-off/delete",

  // PAID TIME OFF URL FOR MANAGER
  managerPaidTimeOffDatatableUrl: "api/manager/paid-time-offs",
  managerPaidTimeOffCalendarUrl: "api/manager/paid-time-offs/calendar-view",
  managerPaidTimeOffStatusChangeUrl: "api/manager/paid-time-off/status-change",
  managerPaidTimeOffApproveCancellationUrl: "api/manager/paid-time-off/approve-cancellation",
  managerPaidTimeOffDeleteUrl: "api/manager/paid-time-off/delete",

  // INCIDENT URL
  incidentDatatableUrl: "api/incidents",
  incidentStoreUrl: "api/incident/store",
  incidentShowUrl: "api/incident/show",
  incidentChangeStatusUrl: "api/incident/change-status",
  incidentBulkActionsUrl: "api/incidents/bulk-action",
  incidentPdfDownloadUrl: "api/incident/pdf-download",

  // CASE REPORT URL
  caseReportAddNote : "api/case-report/add-note",
  caseReportUpdateNoteUrl : "api/case-report/edit-note",
  caseReportMarkAsResolveNote : "api/case-report/mark-as-resolve-note",
  caseReportDatatableForAdminUrl: "api/case-reports-for-admin",
  caseReportDatatableForEmployeeUrl: "api/case-reports-for-employee",
  caseReportDatatableUrl: "api/case-reports",
  caseReportStoreUrl: "api/case-report/store",
  caseReportUploadAttachmentsUrl: "api/case-report/upload-attachments",
  caseReportShowUrl: "api/case-report/show",
  caseReportChangeStatusUrl: "api/case-report/change-status",
  caseReportUpdateCaseServicePricesUrl: "api/case-report/update-case-service-prices",
  caseReportExportPdfUrl: "api/case-reports/export-pdf",
  caseReportExportExcelUrl: "api/case-reports/export-excel",
  caseReportStoreLogUrl: "api/case-report/store-log",
  caseReportStoreATXChecklistUrl: "api/case-report/store-atx-checklist",
  caseReportStoreATXRecordUrl: "api/case-report/store-atx-record",
  caseReportStoreCPBChecklistUrl: "api/case-report/store-cpb-checklist",
  caseReportStoreCPBRecordUrl: "api/case-report/store-cpb-record",
  caseReportStoreHIPECPreChecklistUrl:
    "api/case-report/store-hipec-pre-checklist",
  caseReportStoreHIPECPostChecklistUrl:
    "api/case-report/store-hipec-post-checklist",
  caseReportStoreHIPECRecordUrl: "api/case-report/store-hipec-record",
  caseReportStoreHIPECTimerRecordUrl:
    "api/case-report/store-hipec-timer-record",
  caseReportStoreECMOChecklistUrl: "api/case-report/store-ecmo-checklist",
  caseReportStoreECMORecordUrl: "api/case-report/store-ecmo-record",
  caseReportStoreECMOTimerRecordUrl: "api/case-report/store-ecmo-timer-record",
  caseReportStoreNRPPreProcedureChecklistUrl:
    "api/case-report/store-nrp-pre-procedure-checklist",
  caseReportStoreNRPInitiationChecklistUrl:
    "api/case-report/store-nrp-initiation-checklist",
  caseReportStoreNRPRecordUrl: "api/case-report/store-nrp-record",
  caseReportCompleteLiveChartingUrl: "api/case-report/complete-live-charting",
  caseReportUploadPDFOnServerUrl: "api/case-report/upload-pdf-on-server",
  caseReportExportBillingPdfUrl: "api/case-report/export-billing-pdf",
  caseReportShiftChangeUrl: "api/case-report/shift-change",
  caseReportSendReminderMailUrl: "api/case-report/send-reminder-mail",
  caseReportFlagUrl: "api/case-report/flag",
  caseReportUnflagUrl: "api/case-report/unflag",
  caseReportMarkAsUncompleteLiveChartingUrl:
    "api/case-report/mark-as-uncomplete-live-charting",
  caseReportAuditLogsUrl: "api/case-report/audit-logs",

  // CELL SAVER QC URL
  cellSaverQCDatatableUrl: "api/cell-saver-qcs",
  cellSaverQCStoreUrl: "api/cell-saver-qc/store",
  cellSaverQCShowUrl: "api/cell-saver-qc/show",
  cellSaverQCChangeStatusUrl: "api/cell-saver-qc/change-status",
  cellSaverQCBulkActionsUrl: "api/cell-saver-qcs/bulk-action",
  cellSaverQCExportPdfUrl: "api/cell-saver-qc/export-pdf",

  // EQUIPMENT MAINTENANCE URL
  equipmentMaintenanceDatatableUrl: "api/equipment-maintenances",
  equipmentMaintenanceStoreUrl: "api/equipment-maintenance/store",
  equipmentMaintenanceUploadFileUrl: "api/equipment-maintenance/upload-file",
  equipmentMaintenanceShowUrl: "api/equipment-maintenance/show",
  equipmentMaintenanceChangeStatusUrl:
    "api/equipment-maintenance/change-status",
  equipmentMaintenanceBulkActionsUrl: "api/equipment-maintenances/bulk-action",
  equipmentMaintenanceExportPdfUrl: "api/equipment-maintenance/export-pdf",

  // BIOMED SERVICE RECORD URL
  biomedServiceRecordDatatableUrl: "api/biomed-service-records",
  biomedServiceRecordStoreUrl: "api/biomed-service-record/store",
  biomedServiceRecordShowUrl: "api/biomed-service-record/show",
  biomedServiceRecordChangeStatusUrl: "api/biomed-service-record/change-status",
  biomedServiceRecordBulkActionsUrl: "api/biomed-service-records/bulk-action",
  biomedServiceRecordExportPdfUrl: "api/biomed-service-record/export-pdf",
  biomedServiceByAssetInventoryRecordExportPdfUrl: "api/biomed-service-record/export-pdf-by-asset-inventory",

  // POLICY AND PROCEDURE MANUAL URL
  policyAndProcedureManualStoreUrl: "api/policy-and-procedure-manual/store",
  policyAndProcedureManualShowUrl: "api/policy-and-procedure-manual/show",

  // SETTING
  settingStoreUrl: "api/setting",
  getSettingUrl: "api/get-setting",
  checkMasterPasswrodSettingUrl: "api/check-master-password",

  // REPORT
  reportPayroll: "api/report/payroll",
  reportAdpPayroll: "api/report/adp-payroll",
  reportTimeTracking: "api/report/time-tracking",
  reportCaseReportBilling: "api/report/case-report-billing",
  reportCaseReport: "api/report/case-report",
  cellSaverQualityReport: "api/report/cell-saver-quality-report",
  openHeartQualityReport: "api/report/open-heart-quality-report",
  nrpQualityReport: "api/report/nrp-qi-report",
  hospitalWiseProductInventory: "api/report/hospital-wise-product-inventory",
  caseReportPdfsReport: "api/report/case-report-pdfs",
  caseReportPdfsCreateReport: "api/report/case-report-pdfs/create",

  // PERFORMANCE EVALUATION FOR ADMIN
  adminPerformanceEvaluationsUrl: "api/admin/performance-evaluations",
  adminPerformanceEvaluationStoreUrl: "api/admin/performance-evaluation/store",
  adminPerformanceEvaluationShowUrl: "api/admin/performance-evaluation/show",
  adminPerformanceEvaluationUpdateUrl:
    "api/admin/performance-evaluation/update",
  adminPerformanceEvaluationCommentStoreUrl:
    "api/admin/performance-evaluation/comment/store",
  adminPerformanceEvaluationExportPdfUrl:
    "api/admin/performance-evaluation/export-pdf",
  adminPerformanceEvaluationSendRemainderUrl:
    "api/admin/performance-evaluation/send-remainder",


  // COMPETENCY FORM FOR ADMIN
  adminCompentencyFormUrl: "api/admin/compentency-form/index",
  adminCompentencyFormPreviewPdf: "api/admin/compentency-form/preview-pdf",
  adminCompentencyFormStore: "api/admin/compentency-form/store",
  adminCompentencyFormSubmit: "api/admin/compentency-form/form-submit",
  adminCompentencyFormDelete: "api/admin/compentency-form/delete",
  adminCompentencyFormSendMail: "api/admin/compentency-form/send-mail",
  GetManagers: "api/get-managers",


  // COMPETENCY EXAM FORM FOR ADMIN
  adminCompentencyExamUrl: "api/admin/compentency-exam/index",
  adminCompentencyExamPreviewPdf: "api/admin/compentency-exam/preview-pdf",
  adminCompentencyExamStore: "api/admin/compentency-exam/store",
  adminCompentencyExamShow: "api/manager/compentency-exam/show",
  adminCompentencyExamSubmit: "api/admin/compentency-exam/form-submit",
  adminCompentencyExamDelete: "api/admin/compentency-exam/delete",
  adminCompentencyExamSendMail: "api/admin/compentency-exam/send-mail",



  // PERFORMANCE EVALUATION FOR EMPLOYEE
  performanceEvaluationsUrl: "api/performance-evaluations",
  performanceEvaluationShowUrl: "api/performance-evaluation/show",
  performanceEvaluationUpdateUrl: "api/performance-evaluation/update",
  performanceEvaluationCommentStoreUrl:
    "api/performance-evaluation/comment/store",
  performanceEvaluationExportPdfUrl: "api/performance-evaluation/export-pdf",

  // MISSING ITEM ORDERS FOR ADMIN
  adminMissingItemOrdersUrl: "api/admin/missing-item-orders",
  adminMissingItemOrderUpdateUrl: "api/admin/missing-item-order-update",

  // INVENTORY LOGS FOR ADMIN
  adminInventoryLogsUrl: "api/admin/inventory-logs",

  // BIN TO BIN PRODUCTS
  binToBinProductStoreUrl: "api/bin-to-bin/store",

  // ASSET MANUFACTURER
  assetManufacturerDatatableUrl: "api/asset-manufacturers",
  assetManufacturerStoreUrl: "api/asset-manufacturer/store",
  assetManufacturerChangeStatusUrl: "api/asset-manufacturer/change-status",

  // ASSET CATEGORY
  assetCategoryDatatableUrl: "api/asset-categories",
  assetCategoryStoreUrl: "api/asset-category/store",
  assetCategoryChangeStatusUrl: "api/asset-category/change-status",

  // ASSET PRODUCT
  assetProductDatatableUrl: "api/asset-products",
  assetProductStoreUrl: "api/asset-product/store",
  assetProductChangeStatusUrl: "api/asset-product/change-status",

  // ASSET INVENTORY
  assetInventoryDatatableUrl: "api/asset-inventory",
  assetInventoryBulkStoreUrl: "api/asset-inventory/bulk-store",
  assetInventoryUpdateUrl: "api/asset-inventory/update",
  assetInventoryChangeStatusUrl: "api/asset-inventory/change-status",
  assetInventorySearchUrl: "api/asset-inventory/search",
  assetInventoryExcelExportUrl: "api/asset-inventory/excel-export",

  // ASSET INVENTORY HOSPITAL
  assetInventoryHospitalUrl: "api/asset-inventory-hospital",
  assetInventoryHospitalSingleUrl: "api/asset-inventory-hospital/single",
  hospitalDisposableTemplate: "api/hospital/dispostable-templates",
  hospitalDisposableTemplateStore: "api/hospital/dispostable-template/store",
  hospitalDisposableTemplateDelete: "api/hospital/dispostable-template/delete",
  assetInventoryHospitalCartUrl: "api/asset-inventory-hospital/cart",
  assetInventoryHospitalAddDeviceInCartUrl:
    "api/asset-inventory-hospital/add-device-in-cart",
  replaceAssetInventoryHospitalUrl: "api/asset-inventory-hospital/replace",
  returnAssetInventoryHospitalUrl: "api/asset-inventory-hospital/return",
  cartReturnAssetInventoryHospitalUrl:
    "api/asset-inventory-hospital/cart-return",
  cartCartPdfExportAssetInventoryHospitalUrl:
    "api/asset-inventory-hospital/cart-combined-record-pdf-export",
  getCartAssetInventoryHospitalUrl: "api/asset-inventory-hospital/get-carts",

  // ANNOUNCEMENT
  announcementDatatableUrl: "api/announcements",
  announcementStoreUrl: "api/announcement/store",
  announcementChangeStatusUrl: "api/announcement/change-status",
  announcementChangeOrderUrl: "api/announcement/change-order",

  // ADMIN ANNOUNCEMENT NEW
  adminAnnouncementDatatableUrl: "api/notifications",
  adminAnnouncementStoreUrl: "api/notification/store",
  getNotifications: "api/get-notifications",
  adminAnnouncementDeleteUrl: "api/notification/delete",

  // RECENT UPDATE
  adminRecentUpdateDatatableUrl: "api/admin/recent-updates",
  adminRecentUpdateStoreUrl: "api/admin/recent-update/store",
  adminRecentUpdateChangeStatusUrl: "api/admin/recent-update/change-status",

  // KNOWLEDGE BASE VIDEO
  kbVideoUrl: "api/kb/videos",
  kbVideoStoreUrl: "api/kb/video/store",
  kbVideoDeleteUrl: "api/kb/video/delete",
  openHeartQiPdf: "api/form-download/open-heart-qi-pdf",

  // KNOWLEDGE BASE DEVICE IFUs
  kbDeviceIfusUrl: "api/kb/device-ifus",

  // CRM URL
  // STAGES OF LEAD
  crmGetStagesOfLeadUrl: "api/crm/setting/get-stages-of-lead",
  crmStagesOfLeadDatatableUrl: "api/crm/setting/stages-of-lead",
  crmStageOfLeadStoreUrl: "api/crm/setting/stage-of-lead/store",
  crmStageOfLeadChangeStatusUrl: "api/crm/setting/stage-of-lead/change-status",

  // SALES TEAM
  crmGetSalesPersonsrl: "api/crm/setting/get-sales-persons",
  crmSalesTeamDatatableUrl: "api/crm/setting/sales-team",
  crmSalesTeamStoreUrl: "api/crm/setting/sales-team/store",
  crmSalesTeamRemoveUrl: "api/crm/setting/sales-team/remove",

  // LEAD CATEGORY
  crmGetLeadCategoryUrl: "api/crm/get-lead-categories",

  // LEAD SOURCE
  crmGetLeadSourceUrl: "api/crm/get-lead-sources",

  // LEAD
  crmLeadDatatableUrl: "api/crm/leads",
  crmLeadStoreUrl: "api/crm/lead/store",
  crmLeadShowUrl: "api/crm/lead/show",
  crmLeadChangeStatusUrl: "api/crm/lead/change-status",

  // LEAD ATTACHMENT
  crmGetLeadAttachmentUrl: "api/crm/lead-attachments",
  crmLeadAttachmentStoreUrl: "api/crm/lead-attachment/store",
  crmLeadAttachmentRemoveUrl: "api/crm/lead-attachment/remove",

  // LEAD FOLLOW UP
  crmGetLeadFollowUpUrl: "api/crm/lead-follow-ups",
  crmLeadFollowUpStoreUrl: "api/crm/lead-follow-up/store",
  crmLeadFollowUpRemoveUrl: "api/crm/lead-follow-up/remove",

  // LEAD TASK
  crmGetLeadTaskUrl: "api/crm/lead-tasks",
  crmLeadTaskStoreUrl: "api/crm/lead-task/store",
  crmLeadTaskRemoveUrl: "api/crm/lead-task/remove",
  crmLeadTaskCompleteUrl: "api/crm/lead-task/complete",

  // LOGIN LOG
  loginLogDatatableUrl: "api/login-logs",
  assetInventoryHospitalLogDatatableUrl: "api/asset-inventory-hospital-logs",
  caseReportLogDatatableUrl: "api/case-report-logs",
  activityLogDatatableUrl: "api/activity-logs",

  // CASE REPORT URL FOR MANAGER
  managerCaseReportDatatableUrl: "api/manager/case-reports",

  // TIMESHEET URL FOR MANAGER
  managerTimesheetDatatableUrl: "api/manager/timesheets",
  managerTimesheetDownloadExcelUrl: "api/manager/timesheets/download-excel",

  // ORDER URL FOR MANAGER
  managerOrderDatatableUrl: "api/manager/orders",

  // ORDER URL FOR MANAGER
  managerPerformanceEvaluationsUrl: "api/manager/performance-evaluations",

  // COMPETENCY FORM FOR MANAGER
  managerCompentencyFormUrl: "api/manager/compentency-form/index",
  managerCompentencyFormPreviewPdf: "api/manager/compentency-form/preview-pdf",
  managerCompentencyFormStore: "api/manager/compentency-form/store",
  managerCompentencyFormDelete: "api/manager/compentency-form/delete",
  managerCompentencyFormSendMail: "api/manager/compentency-form/send-mail",
  managerCompentencyFormShow: "api/manager/compentency-form/show",
  managerCompentencyFormSubmit: "api/manager/compentency-form/form-submit",

  // COMPETENCY Exam FOR MANAGER
  managerCompentencyExamUrl: "api/manager/compentency-exam/index",
  managerCompentencyExamPreviewPdf: "api/manager/compentency-exam/preview-pdf",
  managerCompentencyExamStore: "api/manager/compentency-exam/store",
  managerCompentencyExamDelete: "api/manager/compentency-exam/delete",
  managerCompentencyExamSendMail: "api/manager/compentency-exam/send-mail",
  managerCompentencyExamShow: "api/manager/compentency-exam/show",
  managerCompentencyExamSubmit: "api/manager/compentency-exam/form-submit",

  // EMPLOYEE URL FOR MANAGER
  managerEmployeeUrl: "api/manager/employees",
  managerEmployeeShowUrl: "api/manager/employee/show",

  // HR INCIDENT URL
  hrIncidentDatatableUrl: "api/hr-incidents",
  hrIncidentStoreUrl: "api/hr-incident/store",
  hrIncidentShowUrl: "api/hr-incident/show",
  hrIncidentDeleteUrl: "api/hr-incident/delete",
  hrIncidentStoreFollowupUrl: "api/hr-incident/store-followup",
  hrIncidentDownloadPdfUrl: "api/hr-incident/download-pdf",

  // HR DASHBOARD
  hrTopFteEmployeeListUrl: "api/hr/top-fte-employee-list",
  hrHiresByMonthUrl: "api/hr/hires-by-month",
  hrTopDeclineReasonsUrl: "api/hr/top-decline-reasons",
  hrHiresBySourceUrl: "api/hr/hires-by-source",
  hrCandidateStatusUrl: "api/hr/candidate-status",

  // FINANCE DASHBOARD
  financeDashboardUrl: "api/finance/dashboard",
  financeDashboardAnalyticsUrl: "api/finance/dashboard/analytics",
  financeDashboardLast3MonthIncomeUrl:
    "api/finance/dashboard/last-3-month-income",
  financeDashboardIncomeByTypeUrl: "api/finance/dashboard/income-by-type",
  financeDashboardInOutCashFlowUrl: "api/finance/dashboard/in-out-cash-flow",
  financeProfitAndLoss: "api/finance/profit-and-loss",
  financeBalanceSheet: "api/finance/balance-sheet",
  financeCompanyrevenue: "api/finance/company-revenue",
  financeUnpaidBills: "api/finance/unpaid-bills",
  financeUnpaidBillAttachments: "api/finance/unpaid-bill/attachments",
  financeQuickbookSalesData: "api/finance/quickbook-sales-data",

  // EMPLOYEE POSITION URL
  employeePositionsUrl: "api/admin/employee-positions",
  employeePositionStoreUrl: "api/admin/employee-position/store",
  employeePositionShowUrl: "api/admin/employee-position/show",
  employeePositionStoreOfferLetterTemplateUrl:
    "api/admin/employee-position/store/offer-letter-template",
  employeePositionOfferLetterPdfUrl:
    "api/admin/employee-position/offer-letter-pdf",

  // ONBOARDING DOCUMENT URL
  onboardingDocumentsUrl: "api/admin/onboarding-documents",
  onboardingDocumentStoreUrl: "api/admin/onboarding-document/store",
  onboardingDocumentShowUrl: "api/admin/onboarding-document/show",
  onboardingDocumentPDFPreviewUrl: "api/admin/onboarding-document/pdf-preview",

  // POLICY RULE DOCUMENTS
  policyRuleDocumentsUrl: "api/admin/policy-rule-documents",
  policyRuleDocumentStoreUrl: "api/admin/policy-rule-document/store",
  policyRuleDocumentShowUrl: "api/admin/policy-rule-document/show",
  policyRuleDocumentPDFPreviewUrl: "api/admin/policy-rule-document/pdf-preview",
  policyRuleDocumentCertificatePreviewUrl:
    "api/admin/policy-rule-document/certificate-preview",
  policyRuleDocumentChangeStatusUrl: "api/admin/policy-rule-document/change-status",

  // CANDIDATE URL
  candidatesUrl: "api/admin/candidates",
  candidateStoreUrl: "api/admin/candidate/store",
  candidateShowUrl: "api/admin/candidate/show",
  candidateSendToApprovalUrl: "api/admin/candidate/send-to-approval",
  candidateOfferLetterCreateUrl: "api/admin/candidate/offer-letter-create",
  candidateOfferLetterPreviewUrl: "api/admin/candidate/offer-letter-preview",
  candidateSendOfferLetterUrl: "api/admin/candidate/send-offer-letter",
  candidateSignOfferLetterUrl: "api/admin/candidate/sign-offer-letter",
  candidateDeleteUrl: "api/admin/candidate/delete",

  // POLICY RULE DOCUMENT
  policyRuleDocumentSign: "api/policy-rule-document/signed-and-agreed",

  employeePermissionsStore: "api/employee/permission/store",

  // TIME OFF URL
  timeOffStatisticsUrl: "api/time-off-statistics",

  // ADP URL
  adpFetchDataUrl: "api/adp/fetch-data",
  adpGetDataUrl: "api/adp/get-data",
  adpSyncEmployeeUrl: "api/adp/sync-employees",

  // FEEDBACK URL
  adminFeedbackDatatableUrl: "api/admin/feedback",
  feedbackStoreUrl: "api/feedback/store",
  adminFeedbackDeleteUrl: "api/admin/feedback/delete",

  formI9: "api/form-i9/get-form-data",
  formI9Datatable: "api/form-i9/index",
  formI9Preview: "api/form-i9/preview",
  formI9Store: "api/form-i9/store",
  formI9Update: "api/form-i9/update",
  formI9Show: "api/form-i9/show",
  formI9Comment: "api/form-i9/comment",

  formW9: "api/form-w9/get-form-data",
  formW9Datatable: "api/form-w9/index",
  formW9Preview: "api/form-w9/preview",
  formW9Store: "api/form-w9/store",
  formW9Update: "api/form-w9/update",
  formW9Comment: "api/form-w9/comment",

  toDoList: "api/to-do/index",
  toDoListStore: "api/to-do/store",
  toDoListDelete: "api/to-do/delete",
  toDoMarkAsComplete: "api/to-do/mark-as-complete",

  systemNotifications: "api/system-notifications",
  systemNotificationMarkAsRead: "api/system-notifications/mark-as-complete",

  getMasterBillingTypes: "api/get-master-billing-types",
  storeMasterBilling: "api/monthly-billing/master-hospital/store",
  getHospitalBillingTypes: "api/monthly-billing/master-hospital",
  getMonthlyBillingData: "api/monthly-billing/get",
  monthlyBillingUpdate: "api/monthly-billing/update",
  monthlyBillingExportExcel: "api/monthly-billing/export-excel",
  monthlyBillingAddComment: "api/monthly-billing/add-comment",
  monthlyBillingDeleteComment: "api/monthly-billing/remove-comment",
  monthlyBillingAddUsers: "api/monthly-billing/add-users",
  getAssociatedEmployees: "api/get-assoicated-employees-with-monthly-billing",
  createNextMonthBilling: "api/monthly-billing/create",

  hospitalQiReportNotificationUrl: "api/hospital-qi-report-notifications",
  hospitalQiReportNotificationSendEmailUrl: "api/hospital-qi-report-notification/send-email",
  hospitalQiReportNotificationDeleteUrl: "api/hospital-qi-report-notification/delete",

  citizenshipStatus: [
    {
      label: "A citizen of the United States",
      value: "CB_1",
    },
    {
      label: "A noncitizen national of the United States",
      value: "CB_2",
    },
    {
      label: "A lawful permanent resident",
      value: "CB_3",
    },
    {
      label: "A noncitizen authorized to work until",
      value: "CB_4",
    },
  ],

  federalTaxClassification: [
    {
      label: "Individual/sole proprietor",
      value: "topmostSubform[0].Page1[0].Boxes3a-b_ReadOrder[0].c1_1[0]",
    },
    {
      label: "C Corporation",
      value: "topmostSubform[0].Page1[0].Boxes3a-b_ReadOrder[0].c1_1[1]",
    },
    {
      label: "S Corporation",
      value: "topmostSubform[0].Page1[0].Boxes3a-b_ReadOrder[0].c1_1[2]",
    },
    {
      label: "Partnership",
      value: "topmostSubform[0].Page1[0].Boxes3a-b_ReadOrder[0].c1_1[3]",
    },
    {
      label: "Trust/estate",
      value: "topmostSubform[0].Page1[0].Boxes3a-b_ReadOrder[0].c1_1[4]",
    },
    {
      label: "LLC",
      value: "topmostSubform[0].Page1[0].Boxes3a-b_ReadOrder[0].c1_1[5]",
    },
    {
      label: "Other",
      value: "topmostSubform[0].Page1[0].Boxes3a-b_ReadOrder[0].c1_1[6]",
    },
  ],

  attachment_limit: [
    { value: 0, type: "Single" },
    { value: 1, type: "Multiple" },
  ],
  filetype_select: ["image/jpeg", "image/jpg", "image/png", "application/pdf"],
  employee_types: [
    { value: 1, text: "Clinical", color: "green", key: "for_clinical" },
    { value: 2, text: "Non Clinical", color: "pink", key: "for_non_clinical" },
    {
      value: 3,
      text: "Advanced Practice",
      color: "blue",
      key: "for_advanced_practice",
    },
    { value: 4, text: "Nursing", color: "orange", key: "for_nursing" },
    { value: 5, text: "Perfusion", color: "purple", key: "for_allied_health" },
    { value: 6, text: "Surgeon", color: "yellow", key: "for_surgeon" },
  ],
  employment_types: [
    { value: 1, text: "Employee", color: "green" },
    { value: 2, text: "Contractor", color: "pink" },
  ],
  policyRuleEmploymentTypes: [
    { value: 0, text: "All", color: "green" },
    { value: 1, text: "Employee", color: "green" },
    { value: 2, text: "Contractor", color: "pink" },
  ],
  blood_groups: ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"],
  bloodTypes: [
    "Unknown",
    "A positive",
    "A negative",
    "B positive",
    "B negative",
    "AB positive",
    "AB negative",
    "O positive",
    "O negative",
  ],
  employeeStatus: [
    { value: "Active", text: "ACTIVE" },
    { value: "FMLA", text: "FAMILY MEDICAL LEAVE ACT" },
    { value: "LeaveOfAbsence", text: "LEAVE OF ABSENCE" },
    { value: "WorkersCompensation ", text: "WORKER'S COMPENSTATION" },
  ],
  yesNo: [
    { value: "Yes", text: "Yes" },
    { value: "No", text: "No" },
  ],
  yesNoNotApplicable: [
    { value: "Yes", text: "Yes" },
    { value: "No", text: "No" },
    { value: "Not Applicable", text: "Not Applicable" },
  ],
  ownerStatusOption: [
    { value: "None", text: "No" },
    { value: "OwnerOrPartner", text: "Owner/Partner" },
    { value: "CEO", text: "Corporate Executive Officer" },
  ],
  employeePaidOption: [
    { value: "SalaisPaidSalaryry", text: "Salary" },
    { value: "isPaidHourly", text: "Hourly" },
    { value: "isPaidCommission", text: "Commission" },
  ],
  gender: [
    { value: "Male", text: "Male" },
    { value: "Female", text: "Female" },
    { value: "Other", text: "Other" },
    { value: "Prefer not to say", text: "Prefer not to say" },
  ],
  employeeAgreementCategoryOption: [
    {
      value: "LicensedDeferredDepositLoanRegulated",
      text: "Licensed Deferred Deposit Loan Regulated",
    },
    {
      value: "LicensedResidentialMortgageBroker",
      text: "Licensed Residential Mortgage Broker",
    },
    { value: "LicensedSecurityDealer", text: "Licensed Security Dealer" },
    { value: "Other", text: "Other" },
  ],
  classificationOption: [
    { value: "FullTime", text: "FullTime" },
    { value: "PartTime", text: "PartTime" },
    { value: "Temporary", text: "Temporary" },
    { value: "Seasonal", text: "Seasonal" },
  ],
  states: [
    { text: "AK", value: "AK", name: "Alaska" },
    { text: "AL", value: "AL", name: "Alabama" },
    { text: "AR", value: "AR", name: "Arkansas" },
    { text: "AZ", value: "AZ", name: "Arizona" },
    { text: "CA", value: "CA", name: "California" },
    { text: "CO", value: "CO", name: "Colorado" },
    { text: "CT", value: "CT", name: "Connecticut" },
    { text: "DC", value: "DC", name: "District of Columbia" },
    { text: "DE", value: "DE", name: "Delaware" },
    { text: "FL", value: "FL", name: "Florida" },
    { text: "GA", value: "GA", name: "Georgia" },
    { text: "HI", value: "HI", name: "Hawaii" },
    { text: "IA", value: "IA", name: "Iowa" },
    { text: "ID", value: "ID", name: "Idaho" },
    { text: "IL", value: "IL", name: "Illinois" },
    { text: "IN", value: "IN", name: "Indiana" },
    { text: "KS", value: "KS", name: "Kansas" },
    { text: "KY", value: "KY", name: "Kentucky" },
    { text: "LA", value: "LA", name: "Louisiana" },
    { text: "MA", value: "MA", name: "Massachusetts" },
    { text: "MD", value: "MD", name: "Maryland" },
    { text: "ME", value: "ME", name: "Maine" },
    { text: "MI", value: "MI", name: "Michigan" },
    { text: "MN", value: "MN", name: "Minnesota" },
    { text: "MO", value: "MO", name: "Missouri" },
    { text: "MS", value: "MS", name: "Mississippi" },
    { text: "MT", value: "MT", name: "Montana" },
    { text: "NC", value: "NC", name: "North Carolina" },
    { text: "ND", value: "ND", name: "North Dakota" },
    { text: "NE", value: "NE", name: "Nebraska" },
    { text: "NH", value: "NH", name: "New Hampshire" },
    { text: "NJ", value: "NJ", name: "New Jersey" },
    { text: "NM", value: "NM", name: "New Mexico" },
    { text: "NV", value: "NV", name: "Nevada" },
    { text: "NY", value: "NY", name: "New York" },
    { text: "OH", value: "OH", name: "Ohio" },
    { text: "OK", value: "OK", name: "Oklahoma" },
    { text: "OR", value: "OR", name: "Oregon" },
    { text: "PA", value: "PA", name: "Pennsylvania" },
    { text: "RI", value: "RI", name: "Rhode Island" },
    { text: "SC", value: "SC", name: "South Carolina" },
    { text: "SD", value: "SD", name: "South Dakota" },
    { text: "TN", value: "TN", name: "Tennessee" },
    { text: "TX", value: "TX", name: "Texas" },
    { text: "UT", value: "UT", name: "Utah" },
    { text: "VA", value: "VA", name: "Virginia" },
    { text: "VT", value: "VT", name: "Vermont" },
    { text: "WA", value: "WA", name: "Washington" },
    { text: "WI", value: "WI", name: "Wisconsin" },
    { text: "WV", value: "WV", name: "West Virginia" },
    { text: "WY", value: "WY", name: "Wyoming" },
    // { text: "AA", value: "AA", name: "Armed Forces Americas" },
    // { text: "AE", value: "AE", name: "Armed Forces Europe" },
    // { text: "AP", value: "AP", name: "Armed Forces Pacific" },
  ],

  eventDays: [
    { text: "Every", value: 99 },
    { text: "Sunday", value: 0 },
    { text: "Monday", value: 1 },
    { text: "Tuesday", value: 2 },
    { text: "Wednesday", value: 3 },
    { text: "Thursday", value: 4 },
    { text: "Friday", value: 5 },
    { text: "Saturday", value: 6 },
  ],

  calendarEventStatus: [
    { color: "red", value: 0, text: "Deleted" },
    { color: "orange", value: 1, text: "Available" },
    { color: "blue", value: 2, text: "Assigned" },
    { color: "green", value: 3, text: "completed" },
    { color: "#808080", value: 99, text: "-" },
  ],

  paidTimeOffTypes: [
    { text: "Vacation/Paid leave", value: 1 },
    { text: "Sick Leave", value: 2 },
    { text: "Conference", value: 3 },
    { text: "Unpaid Time Off", value: 4 },
    { text: "No Call", value: 5 },
  ],

  hospitalCaseServiceTypes: [
    { text: "Single", value: 1 },
    { text: "Monthly", value: 2 },
  ],

  orderDeliveryTypes: [
    { text: "Standard", value: 1, color: "green" },
    { text: "Express", value: 2, color: "orange" },
    { text: "Overnight", value: 3, color: "red" },
  ],

  performanceEvaluationJobs: [
    { text: "Autotranfusionist", value: 1 },
    { text: "Perfusion", value: 2 },
  ],

  employeeJobPaymentUnit: [
    { text: "Hourly", value: "Hour" },
    { text: "Annual Salary", value: "Year" },
    { text: "Annual Salary + Overtime ", value: "Year+Overtime" },
  ],

  performanceEvaluationTypes: [
    { text: "Annual", value: 1 },
    { text: "Special", value: 2 },
  ],

  inventoryLogTypes: [
    { text: "Order", value: 1 },
    { text: "Case Report", value: 2 },
    { text: "B2B-T", value: 3 },
    { text: "B2B-A", value: 4 },
    { text: "B2B-L", value: 5 },
  ],

  binToBinProductTypes: [
    { text: "Transfer", value: 0 },
    { text: "Adjustment", value: 1 },
    { text: "Lost/Damaged", value: 2 },
  ],

  dispatchedOrderStatus: [
    { text: "Pending", value: 0, color: "warning" },
    { text: "Completed", value: 1, color: "green" },
  ],

  frequencyOfPreventiveMeasurements: [
    { value: 1, text: "Singular" },
    { value: 4, text: "Quarterly" },
    { value: 2, text: "Semi-Annual" },
    { value: 3, text: "Annual" },
    { value: 5, text: "Not Reported" },
  ],

  incidentDeviceTypes: [
    "Oxygenator / Tubing Pack",
    "Cannula",
    "Cardioplegia Disposables",
    "Cardioplegia (Drug)",
    "Cell Saver Disposables",
    "Heart Lung Machine",
    "Cell Saver Device",
    "Heater Cooler",
    "Intraaortic Balloon Pump (IABP)",
    "ECMO",
    "Platelet Gel Therapy",
    "Thromboelastogram (TEG)",
    "Point of Care Devices",
    "Other",
  ],

  incidentInjuries: [
    "Death",
    "Serious",
    "Moderate",
    "Minor",
    "Near Miss",
    "None",
  ],

  caseStatuses: [
    { text: "ELECTIVE", value: 1 },
    { text: "EMERGENT", value: 2 },
    { text: "URGENT", value: 3 },
    { text: "SALVAGE", value: 4 },
  ],

  caseReportStatuses: [
    { text: "Pending", value: 1 },
    { text: "Submitted", value: 2 },
    { text: "Completed", value: 3 },
  ],

  caseReportStatusesForAdmin: [
    { text: "Pending", value: 1 },
    { text: "Submitted", value: 2 },
    { text: "Completed", value: 3 },
    { text: "Monthly", value: 5 },
    { text: "Invoiced", value: 4 },
  ],

  expenseStatuses: [
    { text: "Pending", value: 1 },
    { text: "Approved", value: 2 },
    { text: "Rejected", value: 3 },
    { text: "Paid", value: 4 },
  ],

  expenseTripTypes: [
    { text: "One Way Trip", value: 1 },
    { text: "Round Trip", value: 2 },
  ],

  expenseStatusesForAdmin: [
    { text: "Pending", value: 1 },
    { text: "Approved", value: 2 },
    { text: "Billed", value: 5 },
    { text: "Rejected", value: 3 },
    { text: "Paid", value: 4 },
  ],

  timeTrackingStatuses: [
    { text: "Pending", value: 1 },
    { text: "Approved", value: 2 },
    { text: "Rejected", value: 3 },
    { text: "Paid", value: 4 },
  ],

  orderStatuses: [
    { text: "Pending", value: 0 },
    { text: "Processing", value: 1 },
    { text: "Dispatched", value: 2 },
    { text: "Completed", value: 3 },
    { text: "Rejected", value: 4 },
  ],

  competenceAssessmentStatuses: [
    { text: "Created", value: 0 },
    { text: "Sent", value: 1 },
    { text: "Completed", value: 2 },
    { text: "Deleted", value: 3 },
  ],
  competenceAssessmentFormStatusesAdmin: [
    { text: "Created", value: 0 },
    { text: "Sent", value: 1 },
    { text: "Under Review", value: 2 },
    { text: "Completed", value: 3 },
    { text: "Deleted", value: 4 },

  ],
  competenceAssessmentFormStatuses: [
    { text: "Pending", value: 1 },
    { text: "Submitted", value: 2 },
    { text: "Completed", value: 3 },
  ],
  serviceCategories: [
    { text: "Cardiac", value: 1 },
    { text: "General", value: 2 },
    { text: "Orthopedic", value: 3 },
    { text: "Vascular", value: 4 },
    { text: "Other", value: 5 },
    { text: "TA-NRP", value: 6 },
    { text: "A-NRP", value: 7 },
    { text: "DBD Recovery", value: 8 },
    { text: "DCD Recovery", value: 9 },
  ],

  typeOfAnticoagulations: [
    {
      text: "Citrate-Phosphate-Dextrose (CPD)",
      value: "Citrate-Phosphate-Dextrose (CPD)",
    },
    { text: "Heparin", value: "Heparin" },
  ],

  assetInventoryOwnedBy: [
    { text: "Company", value: 1, color: "green" },
    { text: "Hospital", value: 2, color: "red" },
  ],

  assetInventoryPreventiveDateOverdue: [
    { text: "No", value: 1, color: "green" },
    { text: "Yes", value: 2, color: "red" },
    { text: "N/A", value: 3, color: "cyan" },
  ],

  loginLogAttemptStatuses: [
    { text: "Allow", value: 1, color: "green" },
    { text: "Block", value: 0, color: "red" },
  ],

  employeeNotificationSettings: [
    {
      title: "Case Reporting",
      description: "Get email updates on case reporting workflow.",
      type: 1,
    },
    {
      title: "Expenses",
      description: "Get email updates on expense workflow.",
      type: 2,
    },
    {
      title: "Time Tracking",
      description: "Get email updates on time tracking workflow.",
      type: 3,
    },
    {
      title: "Product Ordering",
      description: "Get email updates on product ordering workflow.",
      type: 4,
    },
    {
      title: "Credentialing",
      description: "Get email updates on credentialing updates.",
      type: 5,
    },
  ],

  hospitalNotificationSettings: [
    {
      title: "Case Reports",
      description: "",
      type: 1,
      pdf_attachment_checkbox_text: "Do you want to include case report PDF?",
      enable_checkbox_text: "Do you want to enable this notification?",
      template_variables: [
        "{{ mr_number }}",
        "{{ account_number }}",
        "{{ report_number }}",
        "{{ total_amount }}",
        "{{ case_report_date }}",
        "{{ services }}",
        "{{ hospital_name }}",
        "{{ cati_invoice_amount }}",
        "{{ cati_po_number }}",
        "{{ surgeon_name }}",
      ],
    },
    {
      title: "Billing",
      description: "",
      type: 2,
      pdf_attachment_checkbox_text: "Do you want to include billing PDF?",
      enable_checkbox_text: "Do you want to enable this notification?",
      template_variables: [
        "{{ mr_number }}",
        "{{ account_number }}",
        "{{ report_number }}",
        "{{ total_amount }}",
        "{{ case_report_date }}",
        "{{ services }}",
        "{{ hospital_name }}",
        "{{ cati_invoice_amount }}",
        "{{ cati_po_number }}",
        "{{ surgeon_name }}",
      ],
    },
  ],

  preProcedureChecklist: [
    "Patient Chart Reviewed",
    "Visual Inspection of all Components",
    "Vacuum Regulator Set at Appropriate Limit",
    "Serial Numbers Recorded",
    "Anticoagulant Connected and Regulated",
    "Wash Solution (0.9% NaCl)",
    "Patient Label on Transfer Bag",
  ],

  cpbPreByPassChecklist: [
    {
      name: "PATIENT",
      checklist: ["Chart reviewed", "Procedure verified"],
    },
    {
      name: "STERILITY",
      checklist: [
        "Components checked for package integrity",
        "Components checked for expiration date",
        "Heat exchanger(s) leak-tested",
      ],
    },
    {
      name: "PUMP",
      checklist: [
        "Speed controls operational",
        "Roller heads smooth and quiet",
        "Occlusion(s) set",
        "Flow meter in correct direction and calibrated",
        "Holders secure",
      ],
    },
    {
      name: "ELECTRICAL",
      checklist: [
        "Power cord(s) securely connected",
        "GAS SUPPLY",
        "Gas line securely connected",
        "Flow meter/blender functional",
        "Hoses leak-free",
        "Gas exhaust unobstructed",
      ],
    },
    {
      name: "LINES/PUMP TUBING",
      checklist: [
        "Connections secure",
        "Tubing direction traced and correct",
        "No kinks noted",
        "One-way valve(s) in correct direction",
        "Debubbled/leak free",
        "Patency of arterial line/cannula verified",
      ],
    },
    {
      name: "CARDIOPLEGIA",
      checklist: [
        "Solution checked",
        "System debubbled/leak-free",
        "SAFETY MECHANISMS",
        "Alarms operational and engaged",
        "Arterial filter/bubble trap debubbled",
        "Cardiotomy reservoir vented",
      ],
    },
    {
      name: "MONITORING",
      checklist: [
        "Temperature probes in place and calibrated",
        "Pump pressure monitors calibrated",
        "In-line and/or on-line sensors calibrated",
        "Oxygen analyzer calibrated",
      ],
    },
    {
      name: "TEMPERATURE CONTROL",
      checklist: ["Water source connected and functional"],
    },
    {
      name: "SUPPLIES",
      checklist: [
        "Tubing clamps available",
        "Drugs available and properly labeled",
        "Solutions available",
        "Blood available",
        "Sampling syringes/laboratory tubes available",
      ],
    },
    {
      name: "ANTICOAGULATION",
      checklist: [
        "Heparin time and dose verified",
        "Anticoagulation tested and reported",
      ],
    },
    {
      name: "BACKUP",
      checklist: [
        "Hand cranks available",
        "Emergency lighting available",
        "Duplicate circuit components available",
      ],
    },
  ],

  hipecPreProcedureChecklist: [
    "Patient Chart Reviewed",
    "Visual Inspection of all Components",
    "Chemotherapy Ordered by Physician",
    "Personal Protective Equipment Used",
    "Lot Numbers Recorded",
  ],

  hipecPostProcedureChecklist: [
    "Circuit Disposed of in Proper Receptacle",
    "Protective Equipment Disposed of",
    "Sharps Disposed of Properly",
    "Cabinet and Machine Cleaned",
    "Supplies and Equipment Restocked",
    "Form Placed in Patient Chart",
  ],

  ecmoSetupChecklist: [
    "Circuit inspected, intact, tubing direction traced & correct",
    "Pigtails added to oxygenator/circuit, all stopcocks tight with non-vented caps",
    "All circuit connections/power cords inspected & secured",
    "Gas lines attached to oxygenator, inspected & secured",
    "Battery checked by unplugging pump",
    "H2O lines connected and off to oxygenator, DO NOT PERFORM LEAK TEST UNTIL READY TO PRIME",
    "Full tank of O<sub>2</sub>",
    "Manufacturer safety check performed",
  ],

  ecmoPrePrimeChecklist: [
    "Heat exchanger leak tested before priming",
    "pArt, pInt, & pVen alert/alarm zeroed & set (if applicable)",
    "Verify gas source alarm",
  ],

  ecmoPostPrimeChecklist: [
    "Circuit de-bubbled and leak free",
    "Set flow limits, sensitivity, & flow mode (if applicable)",
    "Zero flow as appropriate for pump",
    "Keep CardioHelp pump on and activate global override (if applicable)",
  ],

  nrpPreProcedureChecklist: [
    "NRP Console Power On & Plug Secure",
    "Circuit Primed & Free of Air",
    "Serial Numbers Documented",
    "Expiration Dates Checked",
    "Clamps x4 Available",
    "Donor Authorization",
    "Insertion Kits, Wires, Disposables Available",
    "Instrument Tray Available",
    "Cannulas Chosen and Handed Up",
    "Sterile Field Setup and Checked",
    "Ultrasound Available",
    "Heater Cooler Leak Test Performed",
    "Units PRBCs Available",
    "Extra Disposables Available",
    "VAVD setup and confirmed functional"
  ],

  nrpInitiationChecklist: [
    "Tubing Direction Checked and Air Free",
    "Connections Tightened, Stopcocks Closed",
    "Oxygen/Gas Source Connected and On",
    "Supplies Available",
    "Oxygen Tank available",
    "Hand Crank Available",
    "Heparin Bolus In",
    "Cannulas In & Connected to Circuit",
    "NRP Flowsheet/Documentation in Order",
    "Clamped Out & Ready to Initiate",
    "RPM > 1500",
    "Safety Alarms & Interventions On",
    "A-NRP, Cross clamp placed across aorta superior to NRP arterial cannula",
    "Confirmed Head Vessel Occlusion",
    "Head Vessels Vented",
  ],

  caseServiceRequestTypes: [
    "CATH LAB", // "CATH LAB STANDBY",
    "CPB",
    // "CPB STANDBY",
    "AUTOTRANSFUSION",
    // "ATX STANDBY",
    "ECMO",
    "HYPERTHERMIC INTRAPERITONEAL EXTRACORPOREAL CHEMOTHERAPY (HIPEC)",
    "ISOLATED LIMB INFUSION (ILI)",
    "PLATELET GEL THERAPY",
    "PERFUSION", // "PERFUSION STANDBY",
    "TAVR", // "TAVR STANDBY",
    "VAD INSERTION",
    "CANCELED CASE",
  ],

  caseServiceAnticoagulantTypes: [
    "Anticoagulant Citrate Phosphate Dextrose (CPDA)",
    "Heparinized Saline",
    // "Heparinized Citrate",
    // "Other",
    "Not Applicable",
  ],

  cellSaverDeviceSerialNumbers: [
    "1 - B022149",
    "2 - B03407B20",
    "3 - 2073116B16",
  ],

  cellSaverDeviceManufacturers: [
    "Fresenius Kabi C.A.T.S",
    "Haemonetics 5",
    "Haemonetics 5+",
    "Haemonetics Elite",
    "LivaNova Electa",
    "LivaNova XTRA",
    "Medtronic Autolog",
  ],

  performanceEvaluationStarDesc: [
    "Performs well below expected performance",
    "Below expected performance",
    "Expected performance",
    "Above expected performance",
    "Exceeds expected performance",
  ],

  proximalTechniques: [
    "Single Cross-Clamp",
    "Occlusion Clamp",
    "Anastomotic Device (l.e HeartString)",
    "Not Applicable",
  ],

  cardioplegiaTypes: [
    "4:1 Blood to Crystalloid",
    "1:4 Blood to Crystalloid (l.e Del Nido)",
    "Microplegia (l.e Quest MPS)",
  ],

  calendarEventNotesString:
    "Surgeon: \nProcedure: \nLength of Procedure: \nPatient Identifier: \nOR Room Number (If known):",

  ruleTrigger: ["change", "blur"],
  cellSaverSourcesParameters: ["HCT", "HGB", "PLT", "Albumin", "K+"],
  cellSaverCabTechniques: ["On-CPB", "Off-CPB"],
  cellSaverAortics: [
    "No",
    "Replacement",
    "Repair/Reconstruction",
    "Root Reconstruction Valve Conduit",
    "Reconstruction w/ Valve Sparing",
    "Resuspension Aortic Valve",
    "Resection Sub-Aortic Stenosis",
    "TAVR",
  ],
  cellSaverMitrals: [
    "No",
    "Annuloplasty Only",
    "Replacement",
    "Reconstruction w/ Annuloplasty",
    "Reconstruction w/out Annuloplasty",
  ],
  cellSaverTricuspids: [
    "No",
    "Annuloplasty Only",
    "Replacement",
    "Reconstruction w/ Annuloplasty",
    "Reconstruction w/out Annuloplasty",
    "Valvectomy",
  ],
  cellSaverPulmonics: ["No", "Replacement", "Reconstruction"],
  cellSaverCardiacProcedures: [
    "Left Ventricular Aneurysm Repair",
    "Left Atrial Appendage Ligation",
    "Batista",
    "Transmyocard Laser Revasc",
    "Permanent Pacemaker",
    "Ventricular Septal Defect Repair",
    "SVR",
    "Cardiac Trauma",
    "AICD",
    "Atrial Septal Defect Repair",
    "Congenital Defect Repair",
    "Cardiac Transplant",
    "VAD",
    "ECMO",
    "Re-exploration",
    "Thrombectomy",
    "Septal Myectomy",
    "Laser Lead Extraction",
    "Other",
  ].sort(),
  cellSaverNonCardiacProcedures: [
    "Aortic Aneurysm",
    "Aortic Dissection",
    "Carotid Endarterectomy",
    "Liver Transplant",
    "Other Vascular",
    "Other Thoracic",
  ].sort(),
  heartLungDevices: ["Heart Lung Device", "Label"],
  heaterCoolerDevices: ["Heater Cooler Device"],
  cellSaverDevices: ["Cell Saver Device"],
  caseReportPersonnelTypes: [
    "Primary Perfusionist",
    "Secondary Perfusionist",
    "Autotransfusionist",
    "Surgeon",
    "Cardiologist",
    "Anesthesiologist",
    "Anesthetist",
    "Nurse",
    "Surgical First Assist",
    "NRP Tech",
    "Preservationist",
  ],
  hrIncidentTypes: [
    "Harassment or Discrimination",
    "Workplace Injury",
    "Unsafe Working Conditions",
    "Bullying or Intimidation",
    "Conflict of Interest",
    "Data Breach or Security Incident",
    "Workplace Theft or Vandalism",
    "Attendance Violations",
    "Violation of Policies",
    "Customer Complaints",
    "Performance Issues",
    "Accommodation Requests",
    "Health and Safety Concerns",
    "Whistleblower Reports",
    "Equipment Malfunction",
    "Conflict Resolution",
    "Suspicious Activities",
    "Workplace Accidents",
    "Misconduct",
    "Training Needs",
  ],

  // TIME TRACKING
  timeTrackingOnCall: [1, 2, 3, 4, 5, 7, 9, 10],

  // DISPOSABLE ONLY CASE
  disposableOnlyCase: 24,

  // CASE REPORT STEPS
  disposableProductsStep: [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 17, 19, 20, 21, 22, 23, 24, 25, 26, 27
  ],
  acuteNormovolemicHemodilutionStep: [20],
  qualityIndicatorsStep: [7, 8, 14, 19, 23],
  procedureInfoStep: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 17],
  nrpPocedureInfoStep: [19, 23],
  cellSaverOperativeDataStep: [1, 2, 3, 4, 7, 8, 14, 21],
  qualityIndicatorsForHIPECStep: [10],
  plateletGelTherapyStep: [2, 12, 22],
  qualityIndicatorsForECMOStep: [9],
  postProcedureMaintenanceStep: [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 13, 14, 17, 19, 21, 23,
  ],
  preservationServiceStep: [25],
  organRecoveryQiIndicatorStep: [27],
  organRecoveryProcedureInfoStep: [27],

  // LIVE CHARTING
  caseServicesIdForATS: [1, 2, 3, 4, 6, 14, 21],
  caseServicesIdForCPB: [6, 7, 8, 13, 14],
  caseServicesIdForHIPEC: [10, 11],
  caseServicesIdForECMO: [9],
  caseServicesIdForNRP: [19, 23],
  caseReportlpm: [1.6, 1.8, 2.0, 2.2, 2.4],
  medicationDrugs: [
    { label: "Albumin (25%)", value: "Albumin (25%)", uom: "mL" },
    { label: "Albumin (5%)", value: "Albumin (5%)", uom: "mL" },
    { label: "Amicar", value: "Amicar", uom: "grams" },
    { label: "Amiodarone", value: "Amiodarone", uom: "mg" },
    {
      label: "Antithrombin (Recombinant)",
      value: "Antithrombin (Recombinant)",
      uom: "IU",
    },
    { label: "Bivalirudin", value: "Bivalirudin", uom: "mg" },
    { label: "Calcium Chloride", value: "Calcium Chloride", uom: "grams" },
    { label: "Calcium Gluconate", value: "Calcium Gluconate", uom: "grams" },
    { label: "Cefuroxime", value: "Cefuroxime", uom: "grams" },
    { label: "Clinimix w/ Electrolytes", value: "Clinimix w/ Electrolytes", uom: "mL" },
    { label: "Diphenhydramine hcl", value: "Diphenhydramine hcl", uom: "mg" },
    { label: "Heparin", value: "Heparin", uom: "units" },
    { label: "Insulin", value: "Insulin", uom: "units" },
    { label: "Lidocaine", value: "Lidocaine", uom: "mg" },
    { label: "Magnesium Sulfate", value: "Magnesium Sulfate", uom: "grams" },
    { label: "Mannitol (20%)", value: "Mannitol (20%)", uom: "grams" },
    { label: "Mannitol (25%)", value: "Mannitol (25%)", uom: "grams" },
    { label: "Methylene Blue", value: "Methylene Blue", uom: "mg" },
    { label: "Milrinone", value: "Milrinone", uom: "mg" },
    { label: "Neosynepherine", value: "Neosynepherine", uom: "mcg" },
    { label: "Normal Saline (0.9%)", value: "Normal Saline (0.9%)", uom: "cc" },
    { label: "Norepinephrine", value: "Norepinephrine", uom: "mcg" },
    { label: "Plasmalyte", value: "Plasmalyte", uom: "cc" },
    { label: "Potassium Chloride", value: "Potassium Chloride", uom: "mEq" },
    { label: "Prednisone", value: "Prednisone", uom: "mg" },
    { label: "Protamine", value: "Protamine", uom: "mg" },
    { label: "Rocuronium", value: "Rocuronium", uom: "mg" },
    { label: "Sodium Bicarbonate", value: "Sodium Bicarbonate", uom: "mEq" },
    { label: "Sodium Taurocholate", value: "Sodium Taurocholate", uom: "mg" },
    { label: "Solu-Medrol", value: "Solu-Medrol", uom: "g" },
    { label: "Tranexamic Acid", value: "Tranexamic Acid", uom: "mg" },
    { label: "Vasopressin", value: "Vasopressin", uom: "mcg" },
    { label: "Veletri", value: "Veletri", uom: "mg" },
    { label: "Versed", value: "Versed", uom: "mg" },
  ],
  medicationDoseUnits: ["mL", "mmHg"],
  bloodProductTypes: ["PRBC", "FFP"],

  pgtPreProcedureChecklist: [
    "Patient Chart Reviewed",
    "Visual Inspection of all Components",
    "Lot Numbers Recorded",
    "Anticoagulant",
    "Patient Label on Blood Products",
  ],

  pgtPostProcedureChecklist: [
    "Tourniquet Removed",
    "Sharps Disposed Properly",
    "Cabinet & Machine Cleaned",
    "Supplies & Equipment Restocked",
    "Form Placed on Patient Chart",
  ],

  // MASKA
  timeMaska: {
    mask: "##:Q#",
    tokens: {
      Q: {
        pattern: /[0-5]/,
      },
    },
  },
  timeWithSecMaska: {
    mask: "##:Q#:Q#",
    tokens: {
      Q: {
        pattern: /[0-5]/,
      },
    },
  },
  contactNumberMask: {
    mask: "###-###-####",
  },
  contactNumberPlaceholder: "xxx-xxx-xxxx",
  leadContactNumberMask: {
    mask: "(###)###-####",
  },
  leadContactNumberPlaceholder: "(xxx)xxx-xxxx",
  datepickerFormat: "MM/DD/YYYY",
  datePickerPlaceholder: "mm/dd/yyyy",
  signPadCustomCss: {
    border: "black 1px solid",
    borderRadius: "5px",
    maxWidth: "400px",
    height: "200px",
    margin: "0 auto",
  },

  // CRM
  leadTypes: [
    { text: "Email", value: 1 },
    { text: "Call", value: 2 },
    { text: "Meeting", value: 3 },
    { text: "Comment", value: 4 },
    { text: "Status Change", value: 5 },
  ],

  // TIMEZONES
  timezones: [
    { text: "EST", label: "EST", value: "America/New_York" },
    { text: "CST", label: "CST", value: "America/Chicago" },
    { text: "PST", label: "PST", value: "America/Los_Angeles" },
    { text: "MST", label: "MST", value: "America/Denver" },
  ],

  credentialApprovalStatuses: [
    { text: "Pending Review", value: 0 },
    { text: "Approved", value: 1 },
    { text: "Rejected", value: 2 },
  ],

  // RECENT UPDATE TYPE
  recentUpdateTypes: [
    { text: "Admin", value: 1 },
    { text: "Employee", value: 0 },
  ],

  employeeStatuses: [
    { text: "Onboarding", value: 3 },
    { text: "Active", value: 1 },
    { text: "Inactive", value: 0 },
    { text: "Terminated", value: 4 },
    { text: "Rehire", value: 5 }
  ],

  candidateStatuses: [
    { text: "Created", value: 1 },
    { text: "Pending Approval", value: 7 },
    { text: "Offered", value: 2 },
    { text: "Signed", value: 3 },
    { text: "Completed", value: 4 },
    { text: "Expired", value: 5 },
    { text: "Rejected", value: 6 },
  ],

  onboardingDocTypes: [
    { value: "Acknowledge", text: "Acknowledge", value: 0 },
    { value: "Signature", text: "Signature", value: 1 },
  ],

  timeOffStatuses: [
    { text: "Applied", value: 1 },
    { text: "Scheduled", value: 2 },
    { text: "Taken", value: 3 },
  ],

  nrpEventTypes: [
    "Withdrawal of Life Sustaining Therapy Time",
    "Circulatory Cessation Time",
    "Declaration of Death Time",
    "End of Observation Period",
    "Incision Time",
    "Head Vessels Ligated Time",
    "Head Vessels Vented",
    "NRP Aortic Cannulation Time",
    "NRP Venous Cannulation Time",
    "Aortic and IVC Clamp Time",
    "Cold Aortic Flush Post NRP Time",
    "Heart Flush Time",
    "Lung Flush Time",
    "Functional Warm Ischemic Time"
  ],

  feedbackCategories: [
    { value: 1, label: "Enhancement Request" },
    { value: 2, label: "Bug Report" },
    { value: 3, label: "Other" },
  ],

  feedbackSeverities: [
    { value: 1, label: "High" },
    { value: 2, label: "Medium" },
    { value: 3, label: "Low" },
  ],

  fileTypesForFtp: [
    { value: 1, label: "PDF" },
    { value: 2, label: "Excel" },
  ],

  organs: [
    { value: "Heart", label: "Heart" },
    { value: "Lungs", label: "Lungs" },
    { value: "Liver", label: "Liver" },
    { value: "Kidneys", label: "Kidneys" },
    { value: "Pancreas", label: "Pancreas" },
    { value: "None", label: "None" },
  ],

  blended_methods: [
    { value: "Super Rapid Recovery", label: "Super Rapid Recovery" },
    { value: "Transmedics", label: "Transmedics" },
    { value: "OrganOx", label: "OrganOx" },
    { value: "XVIVO", label: "XVIVO" },
    { value: "Other", label: "Other" },
  ],

  paidTimeOffStatuses: [
    { text: "Pending", value: 1 },
    { text: "Approved", value: 2 },
    { text: "Rejected", value: 3 },
    { text: "Pending for Cancellation", value: 4 },
    { text: "Cancelled", value: 5 },
  ],

  organsTargetedForRecoveryOptions: [
    { value: "Thoracic Only", label: "Thoracic Only" },
    { value: "Abdominal Only", label: "Abdominal Only" },
    { value: "Thoracic and Abdominal", label: "Thoracic and Abdominal" },
  ],

  cannulationStrategyOptions: [
    { value: "TA-NRP", label: "TA-NRP" },
    { value: "A-NRP", label: "A-NRP" },
    { value: "A-NRP Hybrid", label: "A-NRP Hybrid" },
  ],

  sources: [
    { value: "Company Website", label: "Company Website" },
    { value: "Indeed", label: "Indeed" },
    { value: "LinkedIn", label: "LinkedIn" },
    { value: "Conference", label: "Conference" },
    { value: "Referred by Other", label: "Referred by Other" }
  ],

  reasons: [
    { value: "Culture Fit", label: "Culture Fit" },
    { value: "Compensation", label: "Compensation" },
    { value: "Location", label: "Location" },
    { value: "Other", label: "Other" }
  ],

  perservation_solution_types: [
    { value: "UW Solution", label: "UW Solution" },
    { value: "Perfadex Plus", label: "Perfadex Plus" },
    { value: "Del Nido", label: "Del Nido" },
  ],

  organ_perfused_types: [
    { value: "Heart", label: "Heart" },
    { value: "Lungs", label: "Lungs" },
  ],

  hospital_qi_report_notification_statuses: [
    { value: 0, text: "Pending" },
    { value: 1, text: "Mail Sent" },
  ],

  onboardingStatuses: [
    { key: "credentialing", tab: "Credentialing" },
    { key: "ssn/dl", tab: "SSN/DL" },
  ],

  qi_report_frequency_types: [
    { value: 1, label: "Monthly" },
    { value: 2, label: "Quarterly" },
    { value: 3, label: "Semi-Annual" },
    { value: 4, label: "Annual" },
  ],

  qi_report_notification_types: [
    { value: 1, label: "Cell Saver", api: 'api/report/cell-saver-quality-report' },
    { value: 2, label: "Open Heart", api: 'api/report/open-heart-quality-report' },
    { value: 3, label: "NRP", api: 'api/report/nrp-qi-report' },
  ],

  competency: [
    { value: "device", label: "Device", id: 1 },
    { value: "services", label: "Services", id: 2 },
    { value: "other", label: "Locum Tenen", id: 3 },
  ],
  device: [
    {
      label: 'Heart Lung Machine',
      options: [
        { value: "liveNova_s5_competency_checklist", label: "LivaNova S5" },
        { value: "terumo_system_1_competency_checklist", label: "Terumo System 1" },
        {value:"spectrum_quantum_heart-lung_machine_competency_checklist" , label:"Spectrum Medical"},
        // { value: "spectrum_quantum_competency_checklist", label: "Spectrum Medical"},
      ]
    },
    {
      label: 'ECMO Devices',
      options: [
        // { value: "kps_cardiohelp_competency_checklist", label: "Cardiohelp"},
        // { value: "kps_stockert_scpc_system_competency", label: "LivaNova SCPC"},
        // { value: "kps_rotaflow_competency_checklist", label: "Rotaflow" },
        {value:"spectrum_quantum_ECMO_competency_checklist" , label:"Spectrum ECMO"}
      ]
    },
    {
      label: 'Heater Cooler',
      options: [
        { value: "liveNova_3t_competency_checklist", label: "LivaNova 3T" },
        { value: "csz_hemotherm_400mr_cometency_checklist", label: "CSZ Hemotherm 400MR" },
        { value: "mr_frosty_competency_checklist", label: "Spectrum Mr. Frosty" },
        { value: "cardioQuip_competency_checklist", label: "Cardioquip" },
      ]
    },
    {
      label: 'Autotransfusion',
      options: [
        { value: "liveNova_xtra_competency_checklist", label: "LivaNova Xtra" },
        { value: "haemonetics_elite_competency_checklist", label: "Haemonetics Elite" },
        { value: "haemonetics_competency_checklist", label: "Haemonetics 5+" },
        { value: "medtronic_autolog_competency_checklist", label: "Medtronic Autolog" },
        { value: "medtronic_autolog_iq_competency_checklist", label: "Medtronic Autolog IQ" },
        { value: "terumo_cats_competency_checklist", label: "Terumo CATS" },
      ]
    },
    {
      label: 'NRP Devices',
      options: [
        { value: "kps_stockert_scpc_system_competency", label: "LivaNova SCPC" },
        {value:"spectrum_quantum_NRP_machine" , label:"Spectrum NRP"},
      ]
    },
    // {
    //   label: 'POC Devices',
    //   options: [
    //     { value: "kps_poc_istate_user_competency", label: "I-Stat" },
    //   ]
    // }
  ],

  services: [
    { value: "kps_nrp_ccp_competency", label: "NRP CCP" },
    { value: "kps_nrp_tech_competency", label: "NRP Tech" },
    // { value: "cpb_clinical_competency_checklist", label: "CPB" },
    // { value: "liveNova_s5_competency_checklist", label: "ATS"},
    { value: "ecmo_clinical_competency_assesment_form", label: "ECMO" },
    { value: "hipec_clinical_competency_assesment", label: "HIPEC" },
    // { value: "kps_cardiohelp_competency_checklist", label: "Cardiohelp"},
    // { value: "spectrum_quantum_competency_checklist", label: "Spectrum"}
  ],
  other: [
    { value: "employee_assessment", label: "Emp" },
  ],

  tableAssessmentOptions: [
    "Not Competent",
    "Competent with Supervision",
    "Fully Competent",
    "Trainer Level"
  ],

  tableHeads: [
    "Task",
    "Self Assessment",
    "Evaluator Assessment",
    "Date Competency Met",
  ],
  competencyExamOptions: [
    { value: "kps_nrp_ccp_competency", label: "NRP CCP" },
    { value: "kps_nrp_tech_competency", label: "NRP Tech" },
    { value: "kps_poc_istate_user_competency", label: "i-STAT" },
  ],
  dynamic_compentency_forms: [
    { value: "cardioQuip_competency_checklist", label: "CardioQuip_Competency_Checklist" },
    { value: "cell_sever_clinical_competency_assesment_form", label: "Cell Sever Clinical Competency Assesment Form" },
    { value: "cpb_clinical_competency_checklist", label: "CPB-Clinical_Competency_Checklist" },
    { value: "csz_hemotherm_400mr_cometency_checklist", label: "CSZ_Hemotherm_400MR_Cometency_Checklist" },
    { value: "ecmo_clinical_competency_assesment_form", label: "ECMO Clinical Competency Assesment Form" },
    { value: "haemonetics_competency_checklist", label: "Haemonetics_5%2B_Competency_Checklist" },
    { value: "haemonetics_elite_competency_checklist", label: "Haemonetics_Elite_Competency_Checklist" },
    { value: "hipec_clinical_competency_assesment", label: "HIPEC_Clinical_Competency_Assesment" },
    { value: "kps_cardiohelp_competency_checklist", label: "KPS Cardiohelp Competency Checklist" },
    { value: "kps_nrp_ccp_competency", label: "KPS NRP CCP Competency" },
    { value: "kps_nrp_tech_competency", label: "KPS NRP Tech Competency" },
    { value: "kps_poc_istate_user_competency", label: "KPS POC i-STAT User Competency" },
    { value: "kps_rotaflow_competency_checklist", label: "KPS Rotaflow Competency Checklist" },
    { value: "kps_stockert_scpc_system_competency", label: "KPS SCPC User Competency" },
    { value: "liveNova_3t_competency_checklist", label: "LiveNova_3T_Competency_Checklist" },
    { value: "liveNova_s5_competency_checklist", label: "LiveNova_S5_Competency_Checklist" },
    { value: "liveNova_xtra_competency_checklist", label: "LiveNova_Xtra_Competency_Checklist" },
    { value: "medtronic_autolog_competency_checklist", label: "Medtronic_Autolog_Competency_Checklist" },
    { value: "medtronic_autolog_iq_competency_checklist", label: "Medtronic_Autolog_IQ_Competency_Checklist" },
    { value: "mr_frosty_competency_checklist", label: "Mr_Frosty_Competency_Checklist" },
    { value: "spectrum_quantum_competency_checklist", label: "Spectrum_Quantum_Competency_Checklist" },
    { value: "terumo_cats_competency_checklist", label: "Terumo_Cats_Competency_Checklist" },
    { value: "terumo_system_1_competency_checklist", label: "Terumo_System_1_Competency_Checklist" },
    { value: "employee_assessment_form", label: "Emp" },
  ],

  hospital_live_charting_enable_options: [
    { value: 0, label: "Disable" },
    { value: 1, label: "KeyPort" },
    { value: 2, label: "Third-Party" },
  ],

  policyRuleDocumentStatuses: [
    { text: "Active", value: 1 },
    { text: "Archived", value: 0 },
  ],
};
