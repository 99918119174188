<template>
  <a-form
    name="hospital_form"
    ref="hospital_form"
    :model="formState"
    :rules="rules"
    layout="vertical"
    @finish="onSubmit"
  >
    <a-row :gutter="[20, 0]">
      <a-col :xs="24" :lg="12">
        <a-form-item
          ref="name"
          label="Name"
          name="name"
          :validateStatus="validate('name', 'status')"
          :help="validate('name', 'msg')"
        >
          <a-input
            v-model:value="formState.name"
            placeholder="Enter name here"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :lg="12">
        <a-form-item
          ref="email"
          label="Email"
          name="email"
          :validateStatus="validate('email', 'status')"
          :help="validate('email', 'msg')"
        >
          <a-input
            v-model:value="formState.email"
            placeholder="Enter email here"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :lg="12">
        <a-form-item
          ref="address_line_1"
          label="Address Line 1"
          name="address_line_1"
        >
          <a-input
            v-model:value="formState.address_line_1"
            placeholder="Enter address line 1 here"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :lg="12">
        <a-form-item
          ref="address_line_2"
          label="Address Line 2"
          name="address_line_2"
        >
          <a-input
            v-model:value="formState.address_line_2"
            placeholder="Enter address line 2 here"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :lg="12">
        <a-form-item ref="city" label="City" name="city">
          <a-input
            v-model:value="formState.city"
            placeholder="Enter city here"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :lg="12">
        <a-form-item ref="state" label="State" name="state">
          <a-select
            v-model:value="formState.state"
            placeholder="Select state"
            show-search
            optionFilterProp="label"
          >
            <a-select-option
              v-for="state in $constants.states"
              :key="state.name"
              :label="state.name"
              :value="state.name"
            >
              {{ state.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :xs="24" :lg="12">
        <a-form-item ref="zipcode" label="Zip Code" name="zipcode">
          <a-input
            v-model:value="formState.zipcode"
            placeholder="Enter zipcode here"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :lg="12">
        <a-form-item ref="companies" label="Company" name="companies">
          <a-select
            v-model:value="formState.companies"
            placeholder="select companies"
            optionFilterProp="label"
          >
            <a-select-option
              v-for="company in companies"
              :key="company.id"
              :label="company.name"
              :value="company.id"
            >
              {{ company.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :xs="24" :lg="12">
        <a-form-item
          ref="is_same_as_facility_address"
          name="is_same_as_facility_address"
        >
          <a-checkbox v-model:checked="formState.is_same_as_facility_address">
            Shipping address same as facility address?
          </a-checkbox>
        </a-form-item>
      </a-col>
      <a-col :xs="24" :lg="12">
        <a-form-item
          v-if="!formState.is_same_as_facility_address"
          ref="shipping_name"
          label="Shipping Name"
          name="shipping_name"
          :validateStatus="validate('shipping_name', 'status')"
          :help="validate('shipping_name', 'msg')"
        >
          <a-input
            v-model:value="formState.shipping_name"
            placeholder="Enter shipping name here"
          />
        </a-form-item>
      </a-col>
      <a-col :xs="24" :lg="12">
        <a-form-item
          v-if="!formState.is_same_as_facility_address"
          ref="shipping_address_line_1"
          label="Shipping Address Line 1 "
          name="shipping_address_line_1"
        >
          <a-input
            v-model:value="formState.shipping_address_line_1"
            placeholder="Enter shipping address line_1 here"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :lg="12">
        <a-form-item
          v-if="!formState.is_same_as_facility_address"
          ref="shipping_address_line_2"
          label="Shipping Address Line 2 "
          name="shipping_address_line_2"
        >
          <a-input
            v-model:value="formState.shipping_address_line_2"
            placeholder="Enter shipping address line_2 here"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :lg="12">
        <a-form-item ref="website" label="Website" name="website">
          <a-input
            v-model:value="formState.website"
            placeholder="Enter website here"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :lg="12">
        <a-form-item ref="contact_no" label="Contact Number" name="contact_no">
          <a-input
            v-model:value="formState.contact_no"
            v-maska="$constants.contactNumberMask"
            :placeholder="$constants.contactNumberPlaceholder"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :lg="12">
        <a-form-item ref="fax_no" label="Fax Number" name="fax_no">
          <a-input
            v-model:value="formState.fax_no"
            v-maska="$constants.contactNumberMask"
            :placeholder="$constants.contactNumberPlaceholder"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :lg="12">
        <!-- <a-form-item ref="delivery_code" name="delivery_code">
          <template #label>
            Account Number
            <a-tooltip>
              <template #title>
                This input field is intended for entering a specific account
                number that is associated with a manufacturer and is linked to a
                particular facility.
              </template>
              <a-button type="link" size="small">
                <i class="ti ti-info-circle ti-lg"></i>
              </a-button>
            </a-tooltip>
          </template>
          <a-input
            v-model:value="formState.delivery_code"
            placeholder="Enter account number here"
          />
        </a-form-item> -->
      </a-col>

      <a-col :xs="24" :lg="12">
        <a-form-item
          ref="has_clinical_site"
          label="Clinical Site Information"
          name="has_clinical_site"
        >
          <a-checkbox v-model:checked="formState.has_clinical_site">
            Does this hospital have clinical site information?
          </a-checkbox>
        </a-form-item>
      </a-col>

      <a-col :xs="24" :lg="12">
        <a-form-item
          v-if="formState.has_clinical_site"
          ref="google_docs_link"
          label="Google Docs Link"
          name="google_docs_link"
        >
          <a-input
            v-model:value="formState.google_docs_link"
            placeholder="Enter google docs link here"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :lg="12">
        <a-form-item
          ref="is_travel_comp_available"
          label="Travel Allowance"
          name="is_travel_comp_available"
        >
          <a-checkbox v-model:checked="formState.is_travel_comp_available">
            Does travel compensation apply for this hospital?
          </a-checkbox>
        </a-form-item>
      </a-col>

      <a-col :xs="24" :lg="12">
        <a-form-item
          ref="quickbooks_class_id"
          label="QuickBooks Class"
          name="quickbooks_class_id"
        >
          <a-select
            v-model:value="formState.quickbooks_class_id"
            placeholder="Select quickbooks class"
            optionFilterProp="label"
            allowClear
            showSearch
          >
            <a-select-option
              v-for="option in computedClasses"
              :key="option.Id"
              :label="option.Name"
              :value="option.Id"
            >
              {{ option.Name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :xs="24" :lg="12">
        <a-form-item
          ref="quickbooks_income_account_id"
          label="QuickBooks Income Accounts"
          name="quickbooks_income_account_id"
        >
          <a-select
            v-model:value="formState.quickbooks_income_account_id"
            placeholder="Select quickbooks income accounts"
            optionFilterProp="label"
            allowClear
            showSearch
          >
            <a-select-option
              v-for="option in incomeAccounts"
              :key="option.Id"
              :label="option.FullyQualifiedName"
              :value="option.Id"
            >
              {{ option.FullyQualifiedName }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :xs="24" :lg="12">
        <a-form-item
          ref="quickbooks_customer_id"
          label="QuickBooks Customer"
          name="quickbooks_customer_id"
        >
          <a-select
            v-model:value="formState.quickbooks_customer_id"
            placeholder="Select quickbooks customer"
            optionFilterProp="label"
            allowClear
            showSearch
          >
            <a-select-option
              v-for="option in customers"
              :key="option.Id"
              :label="option.DisplayName"
              :value="option.Id"
            >
              {{ option.DisplayName }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :xs="24" :lg="12">
        <a-form-item
          ref="is_live_charting_enable"
          label="Live Charting"
          name="is_live_charting_enable"
        >
          <a-select
            v-model:value="formState.is_live_charting_enable"
            placeholder="Select any one"
            optionFilterProp="label"
            allowClear
            showSearch
          >
            <a-select-option
              v-for="option in $constants.hospital_live_charting_enable_options"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            >
              {{ option.label }}
            </a-select-option>
          </a-select>
          <!-- <a-checkbox v-model:checked="formState.is_live_charting_enable">
            Does this facility utilize the live charting function?
          </a-checkbox> -->
        </a-form-item>
      </a-col>

      <a-col :xs="24" :lg="12">
        <a-form-item ref="po_no" label="PO Number" name="po_no">
          <a-input
            v-model:value="formState.po_no"
            placeholder="Enter PO number here"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :lg="12">
        <a-form-item ref="timezone" label="Timezone" name="timezone">
          <a-select
            v-model:value="formState.timezone"
            placeholder="select timezone"
            optionFilterProp="label"
            :options="$constants.timezones"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :lg="12">
        <a-form-item
          ref="attachment_password"
          label="Attachment Password"
          name="attachment_password"
        >
          <a-input
            v-model:value="formState.attachment_password"
            placeholder="Enter attachment password here"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :lg="12">
        <a-form-item
          ref="is_can_receive_organs"
          label="Organ Procurement"
          name="is_can_receive_organs"
        >
          <a-checkbox v-model:checked="formState.is_can_receive_organs">
            Does this facility require NRP?
          </a-checkbox>
        </a-form-item>
      </a-col>

      <a-col :xs="24" :lg="12">
        <a-form-item label="Invoice Settings">
          <a-checkbox v-model:checked="formState.is_monthly_invoice_enable">
            Does this facility do monthly invoices?
          </a-checkbox>
          <a-checkbox
            v-model:checked="formState.is_additional_time_charges_enable"
            style="margin-left: 0"
          >
            Does this facility allow additional time charges?
          </a-checkbox>
        </a-form-item>
      </a-col>

      <a-col :xs="24" :lg="12">
        <a-form-item
          ref="has_allow_product_mergin_less_then_10_per"
          label="Product Settings"
          name="has_allow_product_mergin_less_then_10_per"
        >
          <a-checkbox
            v-model:checked="
              formState.has_allow_product_mergin_less_then_10_per
            "
          >
            Allow product margin less than 10%?
          </a-checkbox>
        </a-form-item>
      </a-col>

      <a-col :xs="24" :lg="12">
        <a-form-item
          ref="quickbooks_sales_term_id"
          label="QuickBooks Sales Term"
          name="quickbooks_sales_term_id"
        >
          <a-select
            v-model:value="formState.quickbooks_sales_term_id"
            placeholder="Select quickbooks sales term"
            optionFilterProp="label"
            allowClear
            showSearch
          >
            <a-select-option
              v-for="option in quickbookTerms"
              :key="option.Id"
              :label="option.Name"
              :value="option.Id"
            >
              {{ option.Name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col
        v-if="formState.is_additional_time_charges_enable"
        :xs="24"
        :lg="12"
      >
        <a-form-item
          ref="additional_time_charge_quickbooks_class_id"
          label="Additional Time Charges QuickBooks Class"
          name="additional_time_charge_quickbooks_class_id"
        >
          <a-select
            v-model:value="formState.additional_time_charge_quickbooks_class_id"
            placeholder="Select quickbooks class"
            optionFilterProp="label"
            allowClear
            showSearch
          >
            <a-select-option
              v-for="option in computedClasses"
              :key="option.Id"
              :label="option.Name"
              :value="option.Id"
            >
              {{ option.Name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :xs="24">
        <a-form-item ref="notes" label="Notes" name="notes">
          <a-textarea
            v-model:value="formState.notes"
            placeholder="Enter note here"
            :autoSize="{ minRows: 2 }"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :lg="12">
        <a-form-item ref="logo" label="Logo" name="logo">
          <a-upload
            :file-list="fileList"
            :before-upload="(file) => beforeUpload(file)"
            @change="({ file }) => onFileAdd(file, 'image/jpeg,image/png')"
            accept="image/jpeg,image/png"
          >
            <div>
              <a-button>
                <UploadOutlined />
                Upload
              </a-button>
            </div>
          </a-upload>
          <a-modal
            :visible="previewVisible"
            :footer="null"
            @cancel="handleCancel"
          >
            <img alt="company image" style="width: 100%" :src="previewImage" />
          </a-modal>
        </a-form-item>
      </a-col>
    </a-row>

    <side-drawer-buttons :name="submitButtonText" />
  </a-form>
</template>

<script>
import { companyService } from "@/services";
import { UploadOutlined } from "@ant-design/icons-vue";
import { mapActions, mapGetters } from "vuex";
import formMixins from "../../mixins/formMixins";

export default {
  components: {
    UploadOutlined,
  },

  data() {
    return {
      fileList: [],
      oldFileList: [],
      previewVisible: false,
      previewImage: "",
      formState: {},
      rules: {
        name: [
          {
            required: true,
            message: "This field is required!",
            trigger: this.$constants.ruleTrigger,
            transform(value) {
              return value.trim();
            },
          },
        ],
        email: [
          {
            type: "email",
            message: "This field is required!",
            trigger: this.$constants.ruleTrigger,
            transform(value) {
              return value.trim();
            },
          },
        ],
        address_line_1: [
          {
            required: true,
            message: "This field is required!",
            trigger: this.$constants.ruleTrigger,
            transform(value) {
              return value.trim();
            },
          },
        ],
        city: [
          {
            required: true,
            message: "This field is required!",
            trigger: this.$constants.ruleTrigger,
            transform(value) {
              return value.trim();
            },
          },
        ],
        state: [
          {
            required: true,
            message: "This field is required!",
            trigger: this.$constants.ruleTrigger,
          },
        ],
        zipcode: [
          {
            required: true,
            message: "This field is required!",
            trigger: this.$constants.ruleTrigger,
            transform(value) {
              return value.trim();
            },
          },
          {
            validator: this.$validation.numeric,
            trigger: this.$constants.ruleTrigger,
          },
        ],
        companies: [
          {
            required: true,
            type: "integer",
            message: "This field is required!",
            trigger: this.$constants.ruleTrigger,
          },
          {
            validator: this.$validation.numeric,
            trigger: this.$constants.ruleTrigger,
          },
        ],
        shipping_name: [
          {
            required: true,
            message: "This field is required!",
            trigger: this.$constants.ruleTrigger,
            transform(value) {
              return value.trim();
            },
          },
        ],
        shipping_address_line_1: [
          {
            required: true,
            message: "This field is required!",
            trigger: this.$constants.ruleTrigger,
            transform(value) {
              return value.trim();
            },
          },
        ],
        website: [
          {
            type: "url",
            message: "This field is required!",
            trigger: this.$constants.ruleTrigger,
            transform(value) {
              return value.trim();
            },
          },
        ],
        google_docs_link: [
          {
            required: true,
            type: "url",
            message: "This field is required!",
            trigger: this.$constants.ruleTrigger,
            transform(value) {
              return value.trim();
            },
          },
        ],
        timezone: [
          {
            required: true,
            message: "This field is required!",
            trigger: this.$constants.ruleTrigger,
          },
        ],
        additional_time_charge_quickbooks_class_id: [
          {
            required: true,
            message: "This field is required!",
            trigger: this.$constants.ruleTrigger,
          },
        ],
        is_live_charting_enable: [
          {
            required: true,
            message: "This field is required!",
            type: "integer",
          },
        ],
      },
    };
  },

  mixins: [formMixins],

  computed: {
    ...mapGetters("drawer", ["record", "extra"]),

    companies() {
      return this.extra.companies;
    },

    classes() {
      return this.extra.classes;
    },

    carolinaClasses() {
      return this.extra.carolina_classes;
    },

    computedClasses() {
      return this.classes;
    },

    incomeAccounts() {
      return this.extra.accounts.filter((v) => v.AccountType == "Income");
    },

    customers() {
      return this.extra.customers;
    },

    quickbookTerms() {
      return this.extra.quickbookTerms;
    },

    submitButtonText() {
      if (this.record.id) {
        return "Update";
      } else {
        return "Create";
      }
    },
  },

  watch: {
    "formState.is_same_as_facility_address": function (newVal) {
      if (newVal) {
        this.formState.shipping_name = null;
        this.formState.shipping_address_line_1 = null;
        this.formState.shipping_address_line_2 = null;
      } else {
        this.formState.shipping_name = this.record.shipping_name || "";
        this.formState.shipping_address_line_1 =
          this.record.shipping_address_line_1 || "";
        this.formState.shipping_address_line_2 =
          this.record.shipping_address_line_2 || "";
      }
    },
  },
  mounted() {
    this.getFormState();
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    getFormState() {
      this.formState = {
        name: this.record.name ?? "",
        email: this.record.email ?? "",
        address_line_1: this.record.address_line_1 ?? "",
        address_line_2: this.record.address_line_2 ?? "",
        city: this.record.city ?? "",
        state: this.record.state ?? null,
        zipcode: this.record.zipcode ?? "",
        companies: this.record.companies
          ? this.selected_company(this.record.companies)[0]
          : undefined,
        website: this.record.website ?? "",
        contact_no: this.record.contact_no ?? "",
        fax_no: this.record.fax_no ?? "",
        has_clinical_site: this.record.has_clinical_site == 1 ? true : false,
        google_docs_link: this.record.google_docs_link ?? "",
        delivery_code: this.record.delivery_code ?? "",
        is_travel_comp_available:
          this.record.is_travel_comp_available == 1 ? true : false,
        is_same_as_facility_address:
          this.record.is_same_as_facility_address == 1 ? true : false,
        shipping_name: this.record.shipping_name ?? "",
        shipping_address_line_1: this.record.shipping_address_line_1 ?? "",
        shipping_address_line_2: this.record.shipping_address_line_2 ?? "",
        quickbooks_class_id: this.record.quickbooks_class_id ?? undefined,
        quickbooks_income_account_id:
          this.record.quickbooks_income_account_id ?? undefined,
        quickbooks_customer_id: this.record.quickbooks_customer_id ?? undefined,
        quickbooks_sales_term_id:
          this.record.quickbooks_sales_term_id ?? undefined,
        additional_time_charge_quickbooks_class_id:
          this.record.additional_time_charge_quickbooks_class_id ?? undefined,
        is_live_charting_enable: this.record.is_live_charting_enable,
        po_no: this.record.po_no ?? "",
        attachment_password: this.record.attachment_password ?? "",
        notes: this.record.notes ?? "",
        timezone: this.record.timezone ?? undefined,
        has_allow_product_mergin_less_then_10_per:
          this.record.has_allow_product_mergin_less_then_10_per == 1
            ? true
            : false,
        is_can_receive_organs:
          this.record.is_can_receive_organs == 1 ? true : false,
        is_monthly_invoice_enable:
          this.record.is_monthly_invoice_enable == 1 ? true : false,
        is_additional_time_charges_enable:
          this.record.is_additional_time_charges_enable == 1 ? true : false,
      };

      this.fileList = this.record.logo
        ? [
            {
              uid: this.record.id,
              status: "Done",
              url: this.record.logo_full_path,
              thumbUrl: this.record.logo_full_path,
              name: this.record.logo_file_name,
            },
          ]
        : [];
    },
    beforeUpload(file) {
      const items = this.fileList;
      items.forEach(() => items.pop());
      items.push(file);
      return false;
    },
    onFileAdd(file, types) {
      const items = this.fileList;
      if (!items) return;
      const acceptedTypes = types.split(",");

      if (!acceptedTypes.includes(file.type)) {
        this.$message.error(`File type of ${file.type} is not supported.`);
        items.pop();
        return;
      }

      if (file.size / 1024 / 1024 > 5) {
        this.$message.error(`File size of ${file.name} is too large. Max: 5MB`);
        items.pop();
        return false;
      }
    },

    onSubmit() {
      let data = { ...this.formState };
      data.image = this.fileList;

      const formData = new FormData();
      const appendQuickbooksItem = (formData, key, id, items, idKey) => {
        const selectedItem = items.find((item) => item.Id === id);
        if (!selectedItem) return;
        formData.append(key, JSON.stringify(selectedItem));
        formData.append(idKey, id);
      };

      if (this.record.id) {
        formData.append("id", this.record.id);
      }
      [this.formState.companies].forEach((v) => {
        formData.append(`companies[]`, v);
      });
      if (this.fileList.some((v) => v.status === "Done")) {
        formData.append("old_image", this.fileList[0].uid);
      } else {
        this.fileList.forEach((v) => {
          formData.append("image", v);
        });
      }
      appendQuickbooksItem(
        formData,
        "quickbooks_class_info",
        this.formState.quickbooks_class_id,
        this.classes,
        "quickbooks_class_id"
      );
      appendQuickbooksItem(
        formData,
        "quickbooks_income_account_info",
        this.formState.quickbooks_income_account_id,
        this.incomeAccounts,
        "quickbooks_income_account_id"
      );
      appendQuickbooksItem(
        formData,
        "quickbooks_customer_info",
        this.formState.quickbooks_customer_id,
        this.customers,
        "quickbooks_customer_id"
      );
      appendQuickbooksItem(
        formData,
        "quickbooks_sales_term_info",
        this.formState.quickbooks_sales_term_id,
        this.customers,
        "quickbooks_sales_term_id"
      );
      if (this.formState.additional_time_charge_quickbooks_class_id) {
        appendQuickbooksItem(
          formData,
          "additional_time_charge_quickbooks_class_info",
          this.formState.additional_time_charge_quickbooks_class_id,
          this.classes,
          "additional_time_charge_quickbooks_class_id"
        );
      }
      const plainFields = [
        "name",
        "email",
        "address_line_1",
        "address_line_2",
        "shipping_name",
        "shipping_address_line_1",
        "shipping_address_line_2",
        "city",
        "state",
        "zipcode",
        "website",
        "contact_no",
        "fax_no",
        "google_docs_link",
        "delivery_code",
        "quickbooks_class_id",
        "quickbooks_income_account_id",
        "is_live_charting_enable",
        "po_no",
        "attachment_password",
        "notes",
        "timezone",
      ].filter((field) => field in data);

      const truthyFields = [
        "has_clinical_site",
        "is_travel_comp_available",
        "is_same_as_facility_address",
        "is_can_receive_organs",
        "has_allow_product_mergin_less_then_10_per",
        "is_monthly_invoice_enable",
        "is_additional_time_charges_enable",
      ];

      plainFields.forEach((field) => {
        const inf = data[field];
        if (!inf) formData.append(field, "");
        else formData.append(field, inf);
      });

      truthyFields.forEach((field) => {
        const inf = data[field];
        if (!inf) formData.append(field, "0");
        else formData.append(field, "1");
      });

      this.loadingStart();
      companyService
        .store(this.$constants.hospitalStoreUrl, formData, true)
        .then((res) => {
          this.loadingStop();
          if (res.success) {
            this.$message.success(res.message);
            this.close();
            this.$emit("callback");
          }
        })
        .catch((err) => {
          this.loadingStop();
          if ("errors" in err) {
            this.errors = err.errors;
          } else {
            this.$message.error(err);
          }
        });
    },

    selected_company(object) {
      let companies = [];
      Object.values(object).forEach((v, i) => {
        companies.push(v.id);
      });
      return companies;
    },

    handleCancel() {
      this.previewVisible = false;
    },

    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },

    handleChange(info) {
      var isJPG = false;
      var isLt2M = false;
      if (info.file.status == "done") {
        isJPG =
          info.file.type === "image/jpeg" || info.file.type === "image/png";
        isLt2M = info.file.size / 1024 / 1024 < 2;
        if (!isJPG) {
          this.$message.error("You can only upload JPG or PNG file!");
        } else if (!isLt2M) {
          this.$message.error("Image must smaller than 2MB!");
        }
        if (isJPG && isLt2M) {
          this.fileList.forEach(async (file) => {
            if (!file.url && !file.preview) {
              file.url = await this.getBase64(file.originFileObj);
            }
          });
          this.fileList = info.fileList;
        } else {
          this.fileList = [];
        }
      }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
  },
};
</script>
