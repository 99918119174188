<template>
  <div
    class="custom-toggle"
    :class="{
      'toggle-true': modelValue === true,
      'toggle-false': modelValue === false,
      'toggle-indeterminate': modelValue === null,
    }"
    @click="handleClickEvent"
  >
    <div
      class="toggle-button"
      :class="{
        left: modelValue === false,
        right: modelValue === true,
        middle: modelValue === null,
      }"
    />
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [Boolean, null],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],

  methods: {
    handleClickEvent() {
      if (this.disabled) return;
      let value;
      if (this.modelValue === true) {
        value = false;
      } else if (this.modelValue === false) {
        value = null;
      } else {
        value = true;
      }
      this.$emit("update:modelValue", value);
    },
  },
};
</script>

<style lang="less">
.custom-toggle {
  position: relative;
  align-items: center;
  background-color: var(--ck-color-base-border);
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  height: 22px;
  padding: 0 5px;
  width: 44px;
  min-width: 44px;
  max-width: 44px;
  margin: 0 0.2em;
  .left {
    transform: translateX(0);
  }
  .right {
    transform: translateX(20px);
  }
  .middle {
    transform: translateX(10px);
  }

  .toggle-button {
    background-color: var(--ck-color-base-background);
    border-radius: 50%;
    height: 15px;
    left: 5px;
    position: absolute;
    transition: transform 0.3s;
    width: 15px;
  }
  &.toggle-false {
    background-color: var(--ck-color-base-error);
    &:after {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='16' height='16' fill='rgba(255,255,255,1)'%3E%3Cpath d='M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z'%3E%3C/path%3E%3C/svg%3E");
      position: absolute;
      top: 3px;
      right: 5px;
      color: var(--ck-color-base-background);
    }
  }
  &.toggle-true {
    background-color: var(--ck-color-base-action);
    &:after {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='16' height='16' fill='rgba(255,255,255,1)'%3E%3Cpath d='M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z'%3E%3C/path%3E%3C/svg%3E");
      position: absolute;
      top: 3px;
      left: 5px;
      fill: var(--ck-color-base-background);
      background-size: 10px;
    }
  }
}
</style>
