<template>
  <a-descriptions
    :column="2"
    :labelStyle="{ width: '150px' }"
    bordered
    size="small"
    class="mb-sm-1"
    style="margin: 10px 10px 0 10px"
  >
    <a-descriptions-item label="Employee">
      {{ data?.employee?.fl_name }}
    </a-descriptions-item>
    <a-descriptions-item label="Evaluator Name">
      {{ data?.assessor_name }}
    </a-descriptions-item>
    <a-descriptions-item label="Job Title">
      {{ data?.employee?.position?.name }}
    </a-descriptions-item>
    <a-descriptions-item label="Evaluator Title">
      {{ data?.assessor?.position?.name }}
    </a-descriptions-item>
    <a-descriptions-item label="Date" v-if="data?.start_date">
      {{ $customDate.mdy(data?.start_date) }} to
      {{ $customDate.mdy(data?.end_date) }}
    </a-descriptions-item>
    <a-descriptions-item label="Hospital" v-if="data?.hospital_id">
      {{ data?.hospital?.name }}
    </a-descriptions-item>
  </a-descriptions>
</template>
<script>
export default {
  props: {
    data: {
      type: [Object, {}],
      default: {},
    },
  },
};
</script>
