<template>
  <a-card size="small">
    <a-skeleton active :loading="loading">
      <a-form ref="manufacturersList" :model="manufacturerFields">
        <a-table
          size="small"
          :columns="columns"
          :data-source="manufacturerFields.rows"
          :rowKey="(_, index) => index"
          bordered
          :pagination="false"
        >
          <template #manufacturer_id="{ record, index }">
            <a-form-item
              ref="manufacturer_id"
              :name="['rows', index, 'manufacturer_id']"
              :rules="{
                required: true,
                message: 'field is required!',
                trigger: 'change',
                type: 'number',
              }"
              :wrapperCol="{ style: { width: '100%' } }"
            >
              <a-select
                v-model:value="record.manufacturer_id"
                placeholder="select manufacturer"
                optionFilterProp="label"
                :showSearch="true"
                width="100%"
                @change="handleManufacturerSelect(index)"
              >
                <a-select-option
                  v-for="manufacturer in unSelectedManufacturers(record)"
                  :key="manufacturer.id"
                  :label="`${manufacturer.name}`"
                  :value="manufacturer.id"
                >
                  {{ manufacturer.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </template>
          <template #account_number="{ record, index }">
            <a-form-item
              ref="account_number"
              :name="['rows', index, 'account_number']"
              :rules="{
                required: true,
                message: 'field is required!',
              }"
            >
              <a-input
                v-model:value="record.account_number"
                placeholder="Enter account number"
              >
              </a-input>
            </a-form-item>
          </template>

          <template #actions="{ index }">
            <a-button
              type="link"
              class="danger-color"
              @click="handleFieldRemove(index)"
              style="margin-bottom: 24px"
            >
              <i class="ti ti-trash ti-lg"></i>
            </a-button>
          </template>

          <template #footer>
            <a-button type="dashed" block @click="addField()">
              <i class="fas fa-plus mr-sm-1"></i>
              Add manufacturer
            </a-button>
          </template>
        </a-table>

        <a-button
          type="primary"
          html-type="submit"
          class="mt-sm-1"
          @click="handleFinish"
        >
          Submit
        </a-button>
      </a-form>
    </a-skeleton>
  </a-card>
</template>

<script>
import { commonService } from "../../../services";

export default {
  name: "hospital-manufacturer",
  props: {
    hospital: {
      required: true,
      default: null,
    },
  },

  data() {
    return {
      loading: true,
      manufacturers: [],
      manufacturerMaps: [],
      manufacturerFields: {
        rows: [],
      },
      manufacturerField: {
        manufacturer_id: null,
        account_number: null,
      },
      manufacturerIds: [],

      columns: [
        {
          title: "Manufacturer Name",
          dataIndex: "manufacturer_id",
          slots: {
            customRender: "manufacturer_id",
          },
        },
        {
          title: "Account Number",
          dataIndex: "account_number",
          width: "30%",
          slots: {
            customRender: "account_number",
          },
        },
        {
          title: "Actions",
          dataIndex: "actions",
          slots: {
            customRender: "actions",
          },
        },
      ],
    };
  },

  async mounted() {
    await this.getManufacturers();
    await this.getHospitalManufacturers();
  },

  methods: {
    getHospitalManufacturers() {
      return commonService
        .get(this.$constants.hospitalGetManufacturers, {
          hospital_id: this.hospital.id,
        })
        .then((res) => {
          this.manufacturerMaps = res.data;
          if (this.manufacturerMaps) {
            this.manufacturerFields.rows = this.manufacturerMaps.map((v) => {
              return {
                manufacturer_id: v.manufacturer_id,
                account_number: v.account_number,
              };
            });
            this.updateManufactureList();
          }
        })
        .finally(() => (this.loading = false));
    },

    getManufacturers() {
      return commonService
        .get(this.$constants.getManufacturersUrl)
        .then((res) => {
          this.manufacturers = res.data;
        });
    },

    addField() {
      this.manufacturerFields.rows.push(
        Object.assign({}, this.manufacturerField)
      );
    },

    handleManufacturerSelect(i) {
      this.updateManufactureList();
    },

    updateManufactureList() {
      let manufacturerIds = [];
      this.manufacturerFields.rows.forEach((v) => {
        if (v.manufacturer_id != null) {
          manufacturerIds.push(v.manufacturer_id);
        }
      });
      this.manufacturers.map((v) => {
        if (manufacturerIds.includes(v.id)) {
          v.selected = true;
        } else {
          v.selected = false;
        }
      });
    },

    handleFieldRemove(i) {
      this.manufacturerFields.rows.splice(i, 1);
      this.updateManufactureList();
    },

    handleFinish() {
      this.$refs.manufacturersList.validate().then(() => {
        let val = {
          manufacturers: this.manufacturerFields.rows,
          hospital_id: this.hospital.id,
        };
        this.loading = true;
        commonService
          .store(this.$constants.hospitalManufacturersUrl, val)
          .then((res) => {
            this.loading = false;
            this.$message.success(res.message);
          })
          .catch((err) => {
            this.loading = false;
            this.$message.error(err);
          });
      });
    },

    unSelectedManufacturers(value) {
      return this.manufacturers.filter((v) => {
        if (v.id == value.manufacturer_id || v.selected != true) {
          return v;
        }
      });
    },
  },
};
</script>
