<template>
  <a-layout-content>
    <page-header>
      <template #title>Competency Assessment</template>

      <template #buttons>
        <!--   -->
      </template>
    </page-header>

    <a-card
      :tabList="tabList"
      :activeTabKey="activeTabKey"
      @tabChange="(key) => (activeTabKey = key)"
      size="small"
    >
      <template #customRender="item">
        {{ item.text }} ({{ item.counts }})
      </template>

      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
        :rowClassName="(record) => (record.is_flagged ? 'flagged-row' : '')"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #action="{ record }">
          <a-space :size="1">
            <template v-if="record.status === 1">
              <router-link
                :to="{
                  name: 'show-competency-assessment',
                  params: { id: record.id },
                }"
              >
                <a-tooltip title="View">
                  <a-button type="link" size="small">
                    <i class="ti ti-eye ti-lg"></i>
                  </a-button>
                </a-tooltip>
              </router-link>
            </template>

            <template v-if="record.status !== 1">
              <!-- <a-tooltip title="Download">
                <a-button type="link" size="small" @click="downloadPdf(record)">
                  <i class="ti ti-download ti-lg"></i>
                </a-button>
              </a-tooltip> -->
              <a-tooltip title="Print">
                <a-button type="link" size="small" @click="renderPdf(record)">
                  <i class="ti ti-printer ti-lg"></i>
                </a-button>
              </a-tooltip>
            </template>
          </a-space>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import datatableMixins from "../../mixins/datatableMixins";
import { commonService } from "../../services";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.CompentencyFormUrl,
      deleteRecordUrl: this.$constants.CompentencyFormDelete,
      columns: [
        // {
        //   title: "Hospital",
        //   dataIndex: "hospital.name",
        //   key: "hospital_name",
        //   slots: {
        //     filterDropdown: "filterDropdown",
        //     filterIcon: "filterIcon",
        //   },
        //   defaultFilteredValue: this.$route.query?.hospital_name
        //     ? [this.$route.query?.hospital_name]
        //     : undefined,
        // },
        {
          title: "Date Range",
          dataIndex: "assessor_email",
          key: "assessor_email",
          customRender: ({ record }) => {
            const startDate = this.$customDate.mdy(record.start_date);
            const endDate = this.$customDate.mdy(record.end_date);

            if (!record.start_date && !record.end_date) {
              return "N/A";
            }
            return `${startDate} to ${endDate}`;
          },
        },
        {
          title: "Assessor Name",
          dataIndex: "assessor_name",
          key: "assessor_name",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.assessor_name
            ? [this.$route.query?.assessor_name]
            : undefined,
        },
        {
          title: "Assessor Email",
          dataIndex: "assessor_email",
          key: "assessor_email",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.assessor_email
            ? [this.$route.query?.assessor_email]
            : undefined,
        },
        {
          title: "Created By",
          dataIndex: "creator_name",
          key: "creator_name",
          customRender: ({ record }) => record?.creator?.fl_name ?? "",
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          fixed: "right",
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "1",
      tabList: this.$constants.competenceAssessmentFormStatuses.map((item) => ({
        key: item.value.toString(),
        counts: 0,
        text: item.text,
        status: item.value,
        slots: { tab: "customRender" },
      })),
    };
  },

  methods: {
    downloadPdf(record) {
      this.datatableLoading = true;

      commonService
        .downloadFile(
          this.$constants.CompentencyFormPreviewPdf,
          { id: record.id },
          `${record.employee.fl_name} assessment document.pdf`
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.datatableLoading = false;
        });
    },

    renderPdf(record) {
      this.datatableLoading = true;

      commonService
        .renderFile(
          this.$constants.CompentencyFormPreviewPdf,
          { id: record.id },
          "application/pdf;base64"
        )
        .then((res) => {
          // this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.datatableLoading = false;
        });
    },
  },
};
</script>
