<template>
  <a-row class="brand" justify="center" type="flex">
    <a-col>
      <img
        alt="keyport"
        class="img-fluid"
        src="@/assets/keyport-header-logo.svg"
      />
    </a-col>
  </a-row>
  <a-menu
    @openChange="onOpenChange"
    v-model:selectedKeys="current"
    :openKeys="openKeys"
    mode="inline"
    theme="dark"
  >
    <sidebarMenu v-for="v in rootMenu" :key="v.route" :menu="v" />
  </a-menu>
</template>

<script>
import router from "@/router";
import {
  AppstoreOutlined,
  BankOutlined,
  BulbOutlined,
  DesktopOutlined,
  FileProtectOutlined,
  LogoutOutlined,
  TeamOutlined,
  UnlockOutlined,
} from "@ant-design/icons-vue";
import { mapGetters } from "vuex";
import { comman } from "../helper/comman";
import sidebarMenu from "./sidebarMenu.vue";

export default {
  components: {
    DesktopOutlined,
    BulbOutlined,
    LogoutOutlined,
    AppstoreOutlined,
    BankOutlined,
    FileProtectOutlined,
    UnlockOutlined,
    TeamOutlined,
    sidebarMenu,
  },
  data() {
    return {
      openKeys: [],
      current: [],
    };
  },
  mounted() {
    this.updateSidebar();
  },
  computed: {
    ...mapGetters("auth", [
      "employee",
      "profileStatus",
      "isAdmin",
      "isManager",
      "isSalesPerson",
      "hasFinanceAccess",
    ]),
    ...mapGetters("navbarCount", [
      "get_pending_expense_count",
      "get_pending_time_tracking_count",
      "get_pending_order_count",
      "get_pending_case_reporting_count",
      "get_total_pending_form_i9",
      "get_total_pending_form_w9",
      "get_pending_case_reporting_count",
    ]),
    rootMenu() {
      return [
        {
          route: "home",
          title: "Dashboard",
          condition: this.profileStatus == 1 || comman.hasRole(1),
          icon: "ti ti-dashboard",
        },
        // {
        //   route: "live-charting",
        //   title: "Live Charting",
        //   condition: true,
        //   icon: "ti ti-device-heart-monitor",
        // },
        {
          route: "schedules",
          title: "Schedule",
          condition: this.profileStatus == 1 || comman.hasRole(1),
          icon: "ti ti-calendar-time",
        },
        {
          route: "case-report",
          title: "Case Reporting",
          condition: this.profileStatus == 1 || comman.hasRole(1),
          extra: [
            "create-case-report",
            "show-case-report",
            "case-report-live-charting",
          ],
          icon: "ti ti-file-report",
        },
        {
          route: "expenses",
          title: "Expenses",
          condition: this.profileStatus == 1 || comman.hasRole(1),
          extra: ["create-expense", "show-expense", "update-expense"],
          icon: "ti ti-wallet",
        },
        {
          route: "employee-time-tracks",
          title: "Time Tracking",
          condition: this.profileStatus == 1 || comman.hasRole(1),
          extra: [
            "create-employee-time-track",
            "edit-employee-time-track",
            "show-employee-time-track",
            "update-employee-time-track",
          ],
          icon: "ti ti-clock",
        },
        // {
        //   route: "paid-time-off",
        //   title: "Paid Time Off",
        //   condition:
        //     (this.profileStatus == 1 || comman.hasRole(1)) &&
        //     this.employee.employment_type == 1,
        //   extra: [
        //     "create-paid-time-off",
        //     "show-paid-time-off",
        //     "edit-paid-time-off",
        //   ],
        //   icon: "ti ti-clock",
        // },
        // {
        //   route: "time-off",
        //   title: "Time Off",
        //   condition: comman.hasRole(1),
        //   icon: "ti ti-beach",
        // },
        {
          route: "facilities",
          title: "Facilities",
          condition: this.profileStatus == 1 && comman.hasRole([2, 3, 4]),
          extra: ["show-facility"],
          icon: "ti ti-building-hospital",
        },
        {
          route: "incidents",
          title: "Incidents",
          condition: this.profileStatus == 1 || comman.hasRole(1),
          extra: ["incidentCreate", "incidentEdit", "incidentShow"],
          icon: "ti ti-alert-triangle",
        },
        {
          route: "performance-evaluation",
          title: "Performance Evaluations",
          icon: "ti ti-star",
          condition: this.profileStatus == 1 || comman.hasRole(1),
          extra: ["performance-evaluation", "show-performance-evaluation"],
        },
        {
          route: "competency-assessment",
          title: "Competency Assessment",
          icon: "ti ti-star",
          condition: this.profileStatus == 1 || comman.hasRole(1),
          extra: ["competency-assessment", "show-competency-assessment"],
        },
        {
          route: "competency-exam",
          title: "Competency Exam",
          icon: "ti ti-star",
          condition: this.profileStatus == 1 || comman.hasRole(1),
          extra: ["competency-exam", "show-competency-exam"],
        },
        {
          route: "order",
          title: "Orders",
          condition: this.profileStatus == 1 || comman.hasRole(1),
          extra: ["create-order", "view-order", "checkout-order"],
          icon: "ti ti-basket",
        },
        {
          route: "dispatched-orders",
          title: "Dispatched Orders",
          condition: this.profileStatus == 1 || comman.hasRole(1),
          icon: "ti ti-truck-delivery",
        },
        {
          route: "biomed-service-record",
          title: "Biomed Service Record",
          condition: this.profileStatus == 1 || comman.hasRole(1),
          extra: [
            "create-biomed-service-record",
            "edit-biomed-service-record",
            "show-biomed-service-record",
          ],
          icon: "ti ti-tool",
        },
        {
          route: "cell-saver-quality-control",
          title: "Cell Saver QC",
          condition: this.profileStatus == 1 || comman.hasRole(1),
          extra: [
            "create-cell-saver-quality-control",
            "edit-cell-saver-quality-control",
            "show-cell-saver-quality-control",
          ],
          icon: "ti ti-droplet",
        },
        {
          route: "equipment-maintenance",
          title: "Heater Cooler QA",
          condition: this.profileStatus == 1 || comman.hasRole(1),
          extra: [
            "create-equipment-maintenance",
            "edit-equipment-maintenance",
            "show-equipment-maintenance",
          ],
          icon: "ti ti-snowflake",
        },
        {
          route: "assetRoutes",
          title: "Assets",
          icon: "ti ti-device-heart-monitor",
          condition: comman.hasRole(1),
          subMenu: [
            {
              route: "asset.manufacturer",
              title: "Manufacturers",
              condition: comman.hasRole(1),
              extra: [],
              icon: "ti ti-building-factory-2",
            },
            {
              route: "asset.category",
              title: "Categories",
              condition: comman.hasRole(1),
              extra: [],
              icon: "ti ti-stack",
            },
            {
              route: "asset.product",
              title: "Products",
              condition: comman.hasRole(1),
              extra: [],
              icon: "ti ti-heart-rate-monitor",
            },
            {
              route: "asset.inventory",
              title: "Inventory",
              condition: comman.hasRole(1),
              extra: ["asset.bulk-create-inventory"],
              icon: "ti ti-building-warehouse",
            },
          ],
        },
        {
          route: "productMenuRoutes",
          title: "Product",
          icon: "ti ti-package",
          condition: comman.hasRole(1),
          subMenu: [
            {
              route: "manufacturer",
              title: "Manufacturers",
              condition: comman.hasRole(1),
              icon: "ti ti-building-factory-2",
            },
            {
              route: "product-category",
              title: "Categories",
              condition: comman.hasRole(1),
              icon: "ti ti-category",
            },
            {
              route: "uom",
              title: "UOMs",
              condition: comman.hasRole(1),
              icon: "ti ti-stack",
            },
            {
              route: "product",
              title: "Products",
              condition: comman.hasRole(1),
              icon: "ti ti-vaccine-bottle",
            },
          ],
        },
        {
          route: "policiesInfoRoutes",
          title: "Policies & Info",
          icon: "ti ti-license",
          condition: this.profileStatus == 1 || comman.hasRole(1),
          subMenu: [
            {
              route: "clinical-site-information",
              title: "Clinical Site Info",
              condition: this.profileStatus == 1 || comman.hasRole(1),
              icon: "ti ti-building-hospital",
            },
            {
              route: "employeeHandbook",
              title: "Employee Handbook",
              condition: this.profileStatus == 1 || comman.hasRole(1),
              icon: "ti ti-book",
            },
            {
              route: "policy-and-procedure-manual",
              title: "Policy & Procedure",
              condition: this.profileStatus == 1 || comman.hasRole(1),
              icon: "ti ti-license",
            },
          ],
        },
        {
          route: "hrIncidentRoute",
          title: "Human Resources",
          icon: "ti ti-users",
          condition: this.profileStatus == 1 || comman.hasRole(1),
          subMenu: [
            {
              route: "hr.dashboard",
              title: "Dashboard",
              condition: comman.hasRole(1),
              icon: "ti ti-dashboard",
            },
            {
              route: "hr-incident.index",
              title: "HR Incidents",
              condition: this.profileStatus == 1 || comman.hasRole(1),
              extra: [
                "hr-incident.create",
                "hr-incident.edit",
                "hr-incident.show",
              ],
              icon: "ti ti-alert-triangle",
            },
          ],
        },
        {
          route: "userMenuRoutes",
          title: "Users",
          icon: "ti ti-users",
          condition:
            comman.hasRole(1) || this.$comman.canAccess("users-employee"),
          subMenu: [
            {
              route: "employee",
              title: "Employee",
              condition:
                comman.hasRole(1) || this.$comman.canAccess("users-employee"),
              extra: ["create-employee", "update-employee", "show-employee"],
              icon: "ti ti-user",
            },
            {
              route: "role",
              title: "Role",
              condition: comman.hasRole(1),
              icon: "ti ti-subtask",
            },
            {
              route: "employee-positions",
              title: "Employee Positions",
              condition: comman.hasRole(1),
              extra: ["employee-position.offer-letter-template"],
              icon: "ti ti-armchair",
            },
            {
              route: "onboarding-documents",
              title: "Onboarding Docs",
              condition: comman.hasRole(1),
              extra: [
                "onboarding-document.store",
                "onboarding-document.edit",
                "onboarding-document.show",
              ],
              icon: "ti ti-license",
            },
            {
              route: "policy-rule-documents",
              title: "Policy Rule Docs",
              condition: comman.hasRole(1),
              extra: [
                "policy-rule-documents.store",
                "policy-rule-documents.edit",
                "policy-rule-documents.show",
              ],
              icon: "ti ti-script",
            },
            {
              route: "candidates",
              title: "Candidates",
              condition: comman.hasRole(1),
              extra: [
                "candidate.create-offer-letter",
                "candidate.sign-offer-letter",
              ],
              icon: "ti ti-user-plus",
            },
            {
              route: "form-i9",
              title: "Form I-9",
              condition: comman.hasRole(1),
              counts: this.get_total_pending_form_i9,
              icon: "ti ti-forms",
              extra: ["form-i9-approve"],
            },
            {
              route: "form-w9",
              title: "Form W-9",
              counts: this.get_total_pending_form_w9,
              condition: comman.hasRole(1),
              icon: "ti ti-forms",
            },
          ],
        },
        {
          route: "adminReportingRoute",
          title: "Admin",
          icon: "ti ti-chart-infographic",
          condition:
            comman.hasRole(1) ||
            (this.profileStatus === 1 &&
              (this.$comman.canAccess("admin-order") ||
                this.$comman.canAccess("admin-dispatched-orders") ||
                this.$comman.canAccess("admin-credentialing-approvals"))),
          subMenu: [
            {
              route: "admin-announcements",
              title: "Announcements",
              condition: comman.hasRole(1),
              icon: "ti ti-speakerphone",
            },
            {
              route: "admin-expenses",
              title: "Expenses",
              condition: comman.hasRole(1),
              extra: ["admin-update-expense"],
              // counts: this.get_pending_expense_count,
              icon: "ti ti-wallet",
            },
            {
              route: "admin-approvals",
              title: "Onboarding Approvals",
              condition:
                this.profileStatus === 1 ||
                comman.hasRole(1) ||
                this.$comman.canAccess("admin-credentialing-approvals"),
              icon: "ti ti-file-certificate",
            },
            // {
            //   route: "admin-competency-assessments",
            //   title: "Competency Assessments",
            //   icon: "ti ti-award",
            //   condition: comman.hasRole(1),
            // },
            {
              route: "admin-competency-assessments-new",
              title: "Competency Assessment",
              icon: "ti ti-award",
              condition: comman.hasRole(1),
              extra: [
                "admin-competency-assessments-new-store",
                "admin-competency-assessment-new-show",
              ],
            },
            {
              route: "admin-competency-exam",
              title: "Competency Exam",
              icon: "ti ti-award",
              condition: comman.hasRole(1),
              extra: [
                "admin-competency-exam-store",
                "admin-competency-exam-show",
              ],
            },
            {
              route: "admin-employee-time-tracks",
              title: "Time Tracking",
              condition: comman.hasRole(1),
              extra: ["admin-update-employee-time-track"],
              // counts: this.get_pending_time_tracking_count,
              icon: "ti ti-clock",
            },
            // {
            //   route: "admin.paid-time-off",
            //   title: "Paid Time Off",
            //   condition: comman.hasRole(1),
            //   extra: [],
            //   icon: "ti ti-clock",
            // },
            {
              route: "admin-order",
              title: "Orders",
              condition:
                comman.hasRole(1) ||
                (this.profileStatus === 1 &&
                  this.$comman.canAccess("admin-order")),
              extra: ["admin-view-order", "admin-update-order"],
              // counts: this.get_pending_order_count,
              icon: "ti ti-basket",
            },
            {
              route: "admin-dispatched-orders",
              title: "Dispatched Orders",
              condition:
                comman.hasRole(1) ||
                (this.profileStatus === 1 &&
                  this.$comman.canAccess("admin-dispatched-orders")),
              icon: "ti ti-truck-delivery",
            },
            {
              route: "admin.missing-item-orders",
              title: "Missing Item Orders",
              condition:
                comman.hasRole(1) ||
                (this.profileStatus === 1 &&
                  this.$comman.canAccess("admin.missing-item-orders")),
              icon: "ti ti-truck-delivery",
            },
            {
              route: "admin-inventory-logs",
              title: "Inventory Logs",
              condition: comman.hasRole(1),
              icon: "ti ti-package",
            },
            {
              route: "admin-bill",
              title: "Bills",
              condition: comman.hasRole(1),
              icon: "ti ti-receipt-2",
            },
            {
              route: "admin-performance-evaluation",
              title: "Performance Evaluations",
              condition: comman.hasRole(1),
              extra: [
                "admin-performance-evaluation",
                "admin-create-performance-evaluation",
                "admin-edit-performance-evaluation",
                "admin-show-performance-evaluation",
              ],
              icon: "ti ti-star",
            },
            {
              route: "admin.case-report",
              title: "Case Reporting",
              condition: comman.hasRole(1),
              extra: [
                "admin.show-case-report",
                "admin.create-case-report",
                "admin.case-report-live-charting",
              ],
              // counts: this.get_pending_case_reporting_count,
              icon: "ti ti-file-report",
            },
            {
              route: "admin-incidents",
              title: "Incidents",
              condition: comman.hasRole(1),
              extra: ["admin-incident-show"],
              icon: "ti ti-alert-triangle",
            },
            {
              route: "admin.feedback",
              title: "Feedback",
              condition: comman.hasRole(1),
              extra: [],
              icon: "ti ti-brand-hipchat",
            },
            {
              route: "admin-settings",
              title: "Third-Party API",
              condition: comman.hasRole(1),
              extra: ["admin-settings"],
              icon: "ti ti-book-2",
            },
            {
              route: "admin-email-smtp",
              title: "Email SMTP",
              condition: comman.hasRole(1),
              extra: [],
              icon: "ti ti-mail",
            },
          ],
        },
        {
          route: "managerRoutes",
          title: "Manager",
          icon: "ti ti-chart-infographic",
          condition: this.profileStatus == 1 && comman.hasRole(3),
          subMenu: [
            {
              route: "manager.case-report",
              title: "Case Reporting",
              condition: this.profileStatus == 1 && comman.hasRole(3),
              extra: [
                "manager.show-case-report",
                "manager.create-case-report",
                "manager.case-report-live-charting",
              ],
              icon: "ti ti-file-report",
            },
            {
              route: "manager.timesheet",
              title: "Time Tracking",
              condition: this.profileStatus == 1 && comman.hasRole(3),
              extra: ["show-employee-time-track"],
              icon: "ti ti-clock",
            },
            // {
            //   route: "manager.paid-time-off",
            //   title: "Paid Time Off",
            //   condition: this.profileStatus == 1 && comman.hasRole(3),
            //   extra: [],
            //   icon: "ti ti-clock",
            // },
            {
              route: "manager.order",
              title: "Orders",
              condition: this.profileStatus == 1 && comman.hasRole(3),
              extra: ["manager.show-order"],
              icon: "ti ti-basket",
            },
            {
              route: "manager.dispatched-orders",
              title: "Dispatched Orders",
              condition: this.profileStatus == 1 && comman.hasRole(3),
              icon: "ti ti-truck-delivery",
            },
            {
              route: "manager.performance-evaluation",
              title: "Performance Evaluations",
              condition: this.profileStatus == 1 && comman.hasRole(3),
              extra: [
                "manager.performance-evaluation",
                "manager.create-performance-evaluation",
                "manager.edit-performance-evaluation",
                "manager.show-performance-evaluation",
              ],
              icon: "ti ti-star",
            },
            {
              route: "manager.competency-assessment",
              title: "Competency Assessment",
              condition: this.profileStatus == 1 && comman.hasRole(3),
              extra: [
                "manager.competency-assessment",
                "manager.competency-assessment-store",
                "manager.show-competency-assessment",
              ],
              icon: "ti ti-star",
            },
            {
              route: "manager.competency-exam",
              title: "Competency Exam",
              condition: this.profileStatus == 1 && comman.hasRole(3),
              extra: [
                "manager.competency-exam",
                "manager.show-competency-exam",
              ],
              icon: "ti ti-star",
            },
            {
              route: "manager.facilities",
              title: "Facilities",
              condition: this.profileStatus == 1 && comman.hasRole(3),
              extra: ["manager.show-facility"],
              icon: "ti ti-building-hospital",
            },
            {
              route: "manager.report.cell-saver-report",
              title: "Cell Saver QI Report",
              condition: this.profileStatus == 1 && comman.hasRole(3),
              icon: "ti ti-report",
            },
            {
              route: "manager.report.open-heart-report",
              title: "Open Heart QI Report",
              condition: this.profileStatus == 1 && comman.hasRole(3),
              icon: "ti ti-report",
            },
            {
              route: "manager.report.nrp-qi-report",
              title: "NRP QI Report",
              condition: this.profileStatus == 1 && comman.hasRole(3),
              icon: "ti ti-report",
            },
            {
              route: "manager.employee",
              title: "Employees",
              condition: this.profileStatus == 1 && comman.hasRole(3),
              icon: "ti ti-users",
              extra: ["manager.show-employee"],
            },
          ],
        },
        {
          route: "adminReports",
          title: "Reports",
          icon: "ti ti-file-analytics",
          condition:
            comman.hasRole(1) || (this.profileStatus == 1 && comman.hasRole(3)),
          subMenu: [
            {
              route: "report.payroll",
              title: "Payroll",
              condition: comman.hasRole(1),
              icon: "ti ti-coin",
            },
            {
              route: "report.time-tracking",
              title: "Time Tracking",
              condition: comman.hasRole(1),
              icon: "ti ti-clock",
            },
            {
              route: "report.case-report-billing",
              title: "Case Report Billing",
              condition: comman.hasRole(1),
              icon: "ti ti-report-money",
            },
            {
              route: "report.case-report",
              title: "Case Report",
              condition:
                comman.hasRole(1) ||
                (this.profileStatus == 1 && comman.hasRole(3)),
              icon: "ti ti-report",
            },
            {
              route: "report.cell-saver-report",
              title: "Cell Saver QI Report",
              condition: comman.hasRole(1),
              icon: "ti ti-report",
            },
            {
              route: "report.open-heart-report",
              title: "Open Heart QI Report",
              condition: comman.hasRole(1),
              icon: "ti ti-report",
            },
            {
              route: "report.nrp-qi-report",
              title: "NRP QI Report",
              condition: comman.hasRole(1),
              icon: "ti ti-report",
            },
            {
              route: "report.product-report",
              title: "Product Report",
              condition: comman.hasRole(1),
              icon: "ti ti-report",
            },
            {
              route: "report.completeness-of-personnel-file",
              title: "File Compliance",
              condition: comman.hasRole(1),
              icon: "ti ti-file-info",
            },
            {
              route: "report.case-report-pdfs",
              title: "Case Report PDFs",
              condition:
                comman.hasRole(1) ||
                (this.profileStatus == 1 && comman.hasRole(3)),
              icon: "ti ti-report",
            },
            {
              route: "report.auto-qi-report",
              title: "Auto QI Reports",
              condition:
                comman.hasRole(1) ||
                (this.profileStatus == 1 && comman.hasRole(3)),
              extra: [],
              icon: "ti ti-send",
            },
          ],
        },
        {
          route: "adminLogs",
          title: "Logs",
          icon: "ti ti-stack-3",
          condition: comman.hasRole(1),
          subMenu: [
            {
              route: "logs.login",
              title: "Login",
              condition: comman.hasRole(1),
              icon: "ti ti-login",
            },
            {
              route: "logs.asset-inventory",
              title: "Asset Inventory",
              condition: comman.hasRole(1),
              icon: "ti ti-building-warehouse",
            },
            {
              route: "logs.case-report",
              title: "Case Report",
              condition: comman.hasRole(1),
              icon: "ti ti-file-report",
            },
            {
              route: "logs.activity-log",
              title: "Activity Logs",
              condition: comman.hasRole(1),
              icon: "ti ti-file-report",
            },
            {
              route: "logs.audit-logs",
              title: "Charting Logs",
              condition: comman.hasRole(1),
              icon: "ti ti-file-database",
            },
          ],
        },
        {
          route: "masterMenuRoutes",
          title: "Settings",
          icon: "ti ti-settings",
          condition: comman.hasRole(1),
          subMenu: [
            {
              route: "company",
              title: "Companies",
              condition: comman.hasRole(1),
              icon: "ti ti-building",
            },
            {
              route: "hospital",
              title: "Hospitals",
              condition: comman.hasRole(1),
              extra: ["hospital-details"],
              icon: "ti ti-building-hospital",
            },
            {
              route: "case-services",
              title: "Case Services",
              condition: comman.hasRole(1),
              icon: "ti ti-stack",
            },
            {
              route: "certificationType",
              title: "Certificate Types",
              condition: comman.hasRole(1),
              icon: "ti ti-certificate",
            },
            // {
            //   route: "emailTemplate",
            //   title: "Email Templates",
            //   condition: comman.hasRole(1),
            //   extra: ["editEmailTemplate"],
            //   icon: "ti ti-mail",
            // },
            {
              route: "expenseType",
              title: "Expense Types",
              condition: comman.hasRole(1),
              icon: "ti ti-wallet",
            },
            {
              route: "shiftType",
              title: "Shift Types",
              condition: comman.hasRole(1),
              icon: "ti ti-clock",
            },
            {
              route: "masterHandbooks",
              title: "Employee Handbook",
              condition: comman.hasRole(1),
              extra: ["create-master-handbook", "edit-master-handbook"],
              icon: "ti ti-book",
            },
            {
              route: "employee-benefits",
              title: "Employee Benefits",
              condition: comman.hasRole(1),
              icon: "ti ti-align-left",
            },
            {
              route: "policy-and-procedure-manual-admin",
              title: "Policy & Procedure",
              condition: comman.hasRole(1),
              icon: "ti ti-license",
            },
            {
              route: "admin-login-sliders",
              title: "Login Sliders",
              condition: comman.hasRole(1),
              icon: "ti ti-slideshow",
            },
            {
              route: "admin.recent-update",
              title: "Recent Updates",
              condition: comman.hasRole(1),
              icon: "ti ti-speakerphone",
            },
            {
              route: "hospitalTemplates",
              title: "Hospital Templates",
              condition: comman.hasRole(1),
              icon: "ti ti-folder",
            },
          ],
        },
        {
          route: "kbRoutes",
          title: "Knowledge Base",
          icon: "ti ti-bulb",
          condition: this.profileStatus == 1 || comman.hasRole(1),
          subMenu: [
            {
              route: "kb.calculators",
              title: "Calculators",
              extra: [],
              condition: true,
              icon: "ti ti-calculator",
            },
            {
              route: "kb.videos",
              title: "Videos",
              extra: [],
              condition: true,
              icon: "ti ti-video",
            },
            {
              route: "kb.device-ifus",
              title: "Device IFUs",
              extra: [],
              condition: true,
              icon: "ti ti-file-text",
            },
            {
              route: "kb.downloads",
              title: "Downloads",
              extra: [],
              condition: true,
              icon: "ti ti-download",
            },
          ],
        },
        {
          route: "finance-dashboard",
          title: "Finance",
          icon: "ti ti-currency-dollar",
          condition: this.hasFinanceAccess,
          subMenu: [
            {
              route: "finance-dashboard",
              title: "Dashboard",
              condition: this.hasFinanceAccess,
              icon: "ti ti-dashboard",
            },
            {
              route: "finance-profit-loss",
              title: "Profit & Loss",
              condition: this.hasFinanceAccess,
              icon: "ti ti-chart-line",
            },
            {
              route: "finance-balance-sheet",
              title: "Balance Sheet",
              condition: this.hasFinanceAccess,
              icon: "ti ti-table-export",
            },
            {
              route: "unpaid-bills",
              title: "Accounts Payable",
              condition: this.hasFinanceAccess,
              icon: "ti ti-table-export",
            },
            {
              route: "monthly-customer-invoicing",
              title: "Billing Checklist",
              condition: this.hasFinanceAccess,
              icon: "ti ti-table-options",
            },
            {
              route: "customer-reports",
              title: "Customer Reports",
              condition: this.hasFinanceAccess,
              icon: "ti ti-table-options",
            },
          ],
        },
        {
          route: "crm",
          title: "CRM",
          icon: "ti ti-bulb",
          condition: comman.hasRole(1) || this.isSalesPerson,
          subMenu: [
            {
              route: "crm.leads",
              title: "Leads",
              extra: ["crm.show.lead"],
              condition: true,
              icon: "ti ti-circles",
            },
            {
              route: "crm.settings",
              title: "Settings",
              icon: "ti ti-bulb",
              condition: comman.hasRole(1),
              subMenu: [
                {
                  route: "crm.setting.sol",
                  title: "Stages of Lead",
                  extra: [],
                  condition: true,
                  icon: "ti ti-brand-stackoverflow",
                },
                {
                  route: "crm.setting.sales-team",
                  title: "Sales Team",
                  extra: [],
                  condition: true,
                  icon: "ti ti-users",
                },
              ],
            },
          ],
        },
        {
          route: "profile",
          title: "Profile",
          condition: true,
          icon: "ti ti-user",
        },

        // {
        //   key: "appointmentsMenuRoutes",
        //   title: "Schedules",
        //   icon: "ti ti-calendar-time",
        //   subMenu: [
        //     {
        //       route: "calendar-event",
        //       title: "Calendar View",
        //       condition: true,
        //       icon: "ti ti-calendar-event",
        //     },
        //     {
        //       route: "events",
        //       title: "List View",
        //       condition: true,
        //       extra: ["create-calendar-event"],
        //       icon: "ti ti-list-details",
        //     },
        //   ],
        // },

        // {
        //   route: "paid-time-off",
        //   title: "PTO",
        //   condition: true,
        //   extra: [
        //     "create-paid-time-off",
        //     "show-paid-time-off",
        //     "edit-paid-time-off",
        //   ],
        // },

        // {
        //   route: "service",
        //   title: "Order Services",
        //   condition: true,
        //   extra: ["create-service", "edit-service"],
        //   icon: "ti ti-folder",
        // },

        // {
        //   route: "paid-time-off",
        //   title: "PTO",
        //   condition: true,
        //   extra: [
        //     "create-paid-time-off",
        //     "show-paid-time-off",
        //     "edit-paid-time-off",
        //   ],
        // },
      ];
    },
  },
  watch: {
    $route: {
      handler() {
        this.updateSidebar();
      },
    },
  },
  methods: {
    updateSidebar() {
      let openKeyCombinations = this.calculateOpenKeyCombinations();
      const current_route = router.currentRoute.value.name;

      this.openKeys = [];
      openKeyCombinations.forEach((element) => {
        if (element.routes.indexOf(current_route) !== -1) {
          this.openKeys = element.openKey;
        }
      });
      this.currentRouteSelection();
    },

    currentRouteSelection(
      menu = this.rootMenu,
      current_route = router.currentRoute.value.name
    ) {
      menu.forEach((e) => {
        if (
          e.route == current_route ||
          ("extra" in e && e.extra.indexOf(current_route) !== -1)
        ) {
          this.current = [e.route];
        }

        if (e.subMenu) {
          this.currentRouteSelection(e.subMenu);
        }
      });
    },

    calculateOpenKeyCombinations() {
      let data = [];
      this.rootMenu.forEach((menu) => {
        if (menu.subMenu) {
          menu.subMenu.forEach((menu1) => {
            if (menu1.subMenu) {
              let temp =
                menu1.subMenu?.map((e) => [e.route, ...(e.extra ?? [])]) ?? [];
              data.push({
                openKey: [menu.route, menu1.route],
                routes: temp.flat(),
              });
            }
          });

          let temp =
            menu.subMenu?.map((e) => [e.route, ...(e.extra ?? [])]) ?? [];
          data.push({
            openKey: [menu.route],
            routes: temp.flat(),
          });
        }
      });

      return data;
    },

    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(
        (key) => this.openKeys.indexOf(key) === -1
      );
      if (
        this.rootMenu.map((item) => item.route).indexOf(latestOpenKey) === -1
      ) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
  },
};
</script>

<style scoped>
.brand {
  padding: 15px;
}

.ant-menu {
  height: calc(100vh - 100px);
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 50px;
}

span.ant-menu-item-icon {
  display: flex;
}

.ti {
  font-size: 24px;
  line-height: 1;
}
</style>
