<template>
  <a-select
    v-model:value="selectedForm"
    placeholder="Select Form type"
    optionFilterProp="label"
    @change="updatePDF"
  >
    <a-select-option
      v-for="(form, index) in $constants.dynamic_compentency_forms"
      :key="index"
      :label="form.label"
      :value="form.value"
    >
      {{ form.label }}
    </a-select-option>
  </a-select>

  <a-card v-if="pdfPreviewURL">
    <iframe
      :src="pdfPreviewURL + '#toolbar=0&navpanes=0&scrollbar=0'"
      type="application/pdf"
      width="100%"
      style="height: calc(100vh - 100px)"
      @click.right.stop.prevent
    />
  </a-card>
</template>
<script>
import { commonService } from "../services";

export default {
  data() {
    return {
      selectedForm: null,
      pdfPreviewURL: null,
    };
  },
  methods: {
    updatePDF() {
      this.pdfPreviewURL = null;

      commonService
        .downloadFile(
          this.$constants.CompetencyChecklistPDf,
          { key: this.selectedForm },
          "sample.pdf",
          true
        )
        .then((res) => {
          this.pdfPreviewURL = window.URL.createObjectURL(
            new Blob([res], {
              type: "application/pdf",
            })
          );
        })
        .catch((err) => {
          this.submitBtnLoading = false;
          this.$message.error(err);
        });
    },
  },
};
</script>
