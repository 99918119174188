<template>
  <a-layout-content>
    <a-page-header
      class="page-header"
      title="Show Competency Exam"
      style="margin-bottom: 24px; background-color: #fff"
      @back="$router.go(-1)"
    >
      <template #extra>
        <!--  -->
      </template>
    </a-page-header>
    <a-card size="small" :loading="loading">
      <employeeInfo :data="data" />

      <dynamicCompetecyExamVue
        :formModel="dynamic_form"
        :fillableBy="fillable_by"
        :recordId="record_id"
        :data="data"
      />
    </a-card>
  </a-layout-content>
</template>

<script>
import { computed } from "@vue/reactivity";
import { mapGetters } from "vuex";
import { commonService } from "../../services";
import employeeInfo from "../../components/dynamicCompetencyForm/employeeInfo.vue";
import dynamicCompetecyExamVue from "../dynamicCompetecyExam.vue";

export default {
  components: { dynamicCompetecyExamVue, employeeInfo },
  data() {
    return {
      loading: true,
      id: this.$route.params.id,
      data: null,
      dynamic_form: [],
      record_id: 0,
    };
  },
  provide() {
    return {
      employeeName: computed(() => this.data.employee.full_name),
    };
  },
  computed: {
    ...mapGetters("auth", ["employee"]),

    fillable_by() {
      return this.data?.employee_id == this.employee.id
        ? "employee"
        : "evaluator";
    },
  },

  methods: {
    getData() {
      commonService
        .get(this.$constants.CompentencyExamShow, {
          id: this.id,
        })
        .then((res) => {
          this.loading = false;
          this.data = res.data;
          this.dynamic_form = res.data.data;
          this.record_id = res.data.id;
        })
        .catch((err) => {
          this.$message.error("Access to the requested resource is forbidden");
          this.$router.push({ name: "competency-exam" });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
