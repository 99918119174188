<template>
  <a-layout-content>
    <page-header>
      <template #prepend>
        <img
          alt="keyport"
          class="img-fluid"
          src="@/assets/keyport-header-logo.svg"
        />
      </template>
      <template #title> Competency Assessment </template>
    </page-header>

    <a-card :loading="loading" size="small" class="mb-sm-2">
      <a-result
        v-if="formState.isCompleted"
        status="success"
        title="Assessment has been completed. Thank you!"
      />

      <a-descriptions
        :bordered="true"
        :title="false"
        size="small"
        :column="{ md: 2, sm: 1, xs: 1 }"
      >
        <a-descriptions-item
          v-for="item in employeeDescriptions"
          :key="item.label"
          :label="item.label"
        >
          {{ item.value }}
        </a-descriptions-item>
      </a-descriptions>

      <dynamicCompetencyForm
        v-if="!formState.isCompleted"
        :formModel="dynamic_form"
        :fillableBy="fillable_by"
        :recordId="record_id"
        :formState="formState"
      />
    </a-card>
  </a-layout-content>
</template>

<script>
import signatureBox from "@/components/signatureBox.vue";
import { commonService } from "@/services";
import dynamicCompetencyForm from "./dynamicCompetencyForm.vue";
import { mapGetters } from "vuex";
import { computed } from "@vue/reactivity";
import employeeInfo from "../components/dynamicCompetencyForm/employeeInfo.vue";

export default {
  components: { signatureBox, dynamicCompetencyForm, employeeInfo },
  data() {
    return {
      loading: true,
      userInfo: {},
      dynamic_form: [],
      record_id: 0,
      formState: {
        email: null,
        token: null,
        isCompleted: false,
      },
    };
  },
  mounted() {
    this.getData();
  },
  provide() {
    return {
      employeeName: computed(() => this.userInfo.employee.full_name),
    };
  },
  methods: {
    getData() {
      return commonService
        .get(this.$constants.managerCompentencyFormShow, {
          token: this.$route.params.token,
          email: this.$route.query.email,
        })
        .then((res) => {
          this.userInfo = res.data;
          this.dynamic_form = res.data.data;
          this.record_id = res.data.id;
          if (res.data.status === 3) {
            this.formState.isCompleted = true;
            return;
          }
          this.formState.email = this.$route.query.email;
          this.formState.token = this.$route.params.token;
        })
        .catch((err) => {
          this.$router.push({ name: "page-not-found" });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    ...mapGetters("auth", ["employee"]),

    fillable_by() {
      return this.userInfo?.employee_id == this.employee.id
        ? "employee"
        : "evaluator";
    },

    employeeDescriptions() {
      const record = this.userInfo;
      const employee = record.employee;
      const hospital = record.hospital;

      return [
        {
          label: "Employee Name",
          value: employee?.full_name,
        },
        {
          label: "Hospital Name",
          value: hospital?.name ?? "N/A",
        },
        {
          label: "Start Date",
          value: this.$customDate.mdy(record?.start_date),
        },
        {
          label: "End Date",
          value: this.$customDate.mdy(record?.end_date),
        },
      ];
    },
  },
};
</script>
