<template>
  <a-layout-content>
    <a-card
      v-if="loading"
      :loading="loading"
      class="page-header"
      size="small"
    />

    <a-page-header
      v-else
      @back="$router.go(-1)"
      class="page-header"
      style="margin-bottom: 24px; background-color: #fff"
    >
      <template #title>
        <template v-if="employee.profile_pic">
          <a-avatar :src="employee.profile_pic_url">
            {{ employee?.last_name?.charAt(0) }}
            {{ employee?.first_name?.charAt(0) }}
          </a-avatar>
        </template>
        {{ employee.full_name }}
      </template>

      <template #tags>
        <a-tag
          :color="$comman.get_employee_type(employee.employee_type, 'color')"
        >
          {{ $comman.get_employee_type(employee.employee_type, "text") }}
        </a-tag>

        <a-tag v-if="employee.is_manager" color="purple"> Manager</a-tag>

        <a-tooltip
          :title="
            isProfileCredentialsComplete
              ? 'All required credentials submitted.'
              : 'Some of required credentials have expired or are missing.'
          "
        >
          <a-badge
            :status="isProfileCredentialsComplete ? 'success' : 'error'"
            dot
          />
        </a-tooltip>
      </template>

      <template #extra>
        <a-space>
          <router-link
            v-if="nextPrev && nextPrev.prev"
            :to="{
              name: 'show-employee',
              params: { id: nextPrev.prev.id },
              replace: true,
            }"
          >
            <a-button type="link">
              <a-space>
                <i class="ti ti-chevron-left ti-lg"></i>
                {{ nextPrev.prev.full_name }}
              </a-space>
            </a-button>
          </router-link>

          {{ nextPrev.index }} of {{ nextPrev.count }}

          <router-link
            v-if="nextPrev && nextPrev.next"
            :to="{
              name: 'show-employee',
              params: { id: nextPrev.next.id },
              replace: true,
            }"
          >
            <a-button type="link">
              <a-space>
                {{ nextPrev.next.full_name }}
                <i class="ti ti-chevron-right ti-lg"></i>
              </a-space>
            </a-button>
          </router-link>

          <a-popconfirm
            v-if="
              isAdmin &&
              [1, 3].includes(employee.status) &&
              loginEmployee.id !== employee.id &&
              hasFinanceAccess
            "
            @confirm="loginToThisAccount"
            cancel-text="No"
            ok-text="Yes"
            ok-type="danger"
            title="Are you sure to login to this account? You will be logged out from your current account."
          >
            <a-button danger ghost>
              <a-space> <i class="ti ti-login ti-lg"></i> Log-in </a-space>
            </a-button>
          </a-popconfirm>

          <template v-if="[1, 3].includes(employee.status)">
            <a-button
              type="primary"
              ghost
              @click="checkProfileStatus"
              :loading="checkingProfileStatus"
            >
              <a-space>
                <i class="ti ti-refresh ti-lg"></i> Refresh Profile Status
              </a-space>
            </a-button>
          </template>

          <template v-if="isAdmin">
            <router-link
              v-if="employee.status == 1"
              :to="{
                name: 'edit-employee',
                params: { id: employee.id },
              }"
            >
              <a-button type="primary">
                <a-space><i class="ti ti-pencil ti-lg"></i> Edit</a-space>
              </a-button>
            </router-link>

            <template v-else>
              <a-tag v-if="employee.status == 0" color="red"> Inactive</a-tag>
              <a-tag v-if="employee.status == 3" color="blue">
                Onboarding
              </a-tag>
              <a-tag v-if="employee.status == 4" color="red">
                Terminated
              </a-tag>
              <a-tag v-if="employee.status == 5" color="blue"> Rehire </a-tag>
            </template>
          </template>
        </a-space>
      </template>

      <template #footer>
        <a-tabs
          @tabChange="(key) => (activeTabKey = key)"
          v-model:activeKey="activeTabKey"
          style="margin-bottom: 0"
        >
          <a-tab-pane v-for="tab in tabList" :key="tab.key" :tab="tab.tab" />
        </a-tabs>
      </template>

      <a-row :gutter="[16, 16]" class="content" type="flex">
        <!-- <a-col> <i class="ti ti-user ti-lg"></i> Perfusionist </a-col> -->

        <a-col>
          <a-space>
            <i class="ti ti-building ti-lg"></i>
            {{ employee?.company?.name ?? "N/A" }}
          </a-space>
        </a-col>

        <a-col>
          <a-space>
            <i class="ti ti-mail ti-lg"></i>
            <a-typography-link :href="`mailto:${employee.email}`">
              {{ employee.email }}
            </a-typography-link>
          </a-space>
        </a-col>

        <a-col v-if="employee.contact_no">
          <a-space>
            <i class="ti ti-device-mobile ti-lg"></i>
            <a-typography-link :href="`tel:${employee.contact_no}`">
              {{ employee.contact_no }}
            </a-typography-link>
          </a-space>
        </a-col>
      </a-row>
    </a-page-header>

    <component
      v-if="is"
      @updateEmployeeData="updateEmployeeData"
      :is="is"
      v-bind="{ employee }"
    >
      <router-view />
    </component>
  </a-layout-content>
</template>

<script>
import { mapGetters } from "vuex";
import { route } from "../../helper/route";
import { commonService } from "../../services";

export default {
  data() {
    return {
      loading: true,
      checkingProfileStatus: false,
      gustoImportLoading: false,
      employee: null,
      nextPrev: null,
      employee_id: this.$route.params.id,
      activeTabKey: "overview",
    };
  },

  mounted() {
    this.getData();
    this.activeTabKey = this.$route.query?.tab
      ? this.$route.query.tab
      : this.isAdmin
      ? "overview"
      : "certificates";
  },

  updated() {
    if (this.employee_id != this.$route.params.id) {
      this.loading = true;
      this.employee = null;
      this.employee_id = this.$route.params.id;
      this.getData();
    }
  },

  computed: {
    ...mapGetters("auth", {
      isAdmin: "isAdmin",
      loginEmployee: "employee",
      hasFinanceAccess: "hasFinanceAccess",
    }),

    is() {
      if (this.activeTabKey !== "") {
        return route(`employee/showTabs/${this.activeTabKey}`);
      } else {
        return "";
      }
    },

    isProfileCredentialsComplete() {
      if (!this.employee) {
        return false;
      } else {
        return !!this.employee.profile_status;
      }
    },

    tabList() {
      return [
        ...(this.isAdmin
          ? [
              {
                key: "overview",
                tab: "Overview",
              },
            ]
          : []),
        {
          key: "certificates",
          tab: "Credentialing",
        },
        ...(this.isAdmin
          ? [
              {
                key: "documents",
                tab: "Documents",
              },
              {
                key: "hospitals",
                tab: "Hospitals",
              },
            ]
          : []),
        ...(this.employee?.employment_type == 2
          ? [
              {
                key: "contractor_analytics",
                tab: "Analytics",
              },
            ]
          : []),
        ...(this.isAdmin
          ? [
              {
                key: "case_reporting",
                tab: "Case Reporting",
              },
              {
                key: "expense",
                tab: "Expense",
              },
              {
                key: "timesheet",
                tab: "Timesheet",
              },
              {
                key: "competency_assessment",
                tab: "Competency Assessment",
              },
              {
                key: "performance_evalution",
                tab: "Performance Evaluation",
              },
              {
                key: "management",
                tab: "Management",
              },
              {
                key: "permissions",
                tab: "Permissions",
              },
              {
                key: "settings",
                tab: "Settings",
              },
            ]
          : []),
      ];
    },
  },

  watch: {
    activeTabKey: {
      handler: function (val) {
        this.$router.replace({ query: { tab: val } });
      },
    },
  },

  methods: {
    getData() {
      this.loading = true;
      commonService
        .get(this.$constants.employeeShowUrl, { id: this.employee_id })
        .then((res) => {
          this.loading = false;
          this.employee = res.data.employee;
          this.nextPrev = res.data.nextPrev;
        })
        .catch((err) => {
          this.$message.error(err || "Something went wrong.");
          this.$router.push({ name: "employee" });
        });
    },

    loginToThisAccount() {
      const email = this.employee.email;
      this.loading = true;
      commonService
        .store(this.$constants.loginIntoOtherEmployeeAccountUrl, { email })
        .then(async (res) => {
          this.$message.success("Switching accounts...");
          localStorage.setItem("token", res.token.access_token);
          await this.$store.dispatch("init");
          // this.$router.push({ name: "dashboard" });
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    gustoEmployeeImport() {
      this.gustoImportLoading = true;
      commonService
        .store(this.$constants.syncEmployeeFromGustoUrl, {
          gusto_employee_id: this.employee.gusto_employee_id,
        })
        .then((res) => {
          this.$message.success(res.message);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.gustoImportLoading = false;
          this.getData();
        });
    },

    updateEmployeeData(data) {
      this.employee = data;
    },

    checkProfileStatus() {
      this.checkingProfileStatus = true;
      commonService
        .store(
          `${this.$constants.checkEmployeeProfileStatusUrl}/${this.employee.id}`
        )
        .then(async (res) => {
          this.updateEmployeeData(res.data);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.checkingProfileStatus = false;
        });
    },
  },
};
</script>

<style>
.ant-badge-status-dot {
  width: 9px !important;
  height: 9px !important;
}
</style>
