<template>
  <a-layout-content>
    <a-page-header
      @back="$router.go(-1)"
      class="page-header"
      style="margin-bottom: 24px; background-color: #fff"
    >
      <template #title>Add Competency Assessment</template>

      <template #buttons>
        <!--  -->
      </template>
    </a-page-header>

    <a-form ref="form" :model="formState" :rules="rules" layout="vertical">
      <a-row :gutter="[10, 10]">
        <a-col :span="12">
          <a-space direction="vertical" style="width: 100%">
            <a-card size="small">
              <a-spin :spinning="loading">
                <a-row :gutter="[20, 0]">
                  <a-col :sm="24">
                    <a-form-item label="Employee" name="employee_id">
                      <a-select
                        v-model:value="formState.employee_id"
                        placeholder="Select Employee"
                        show-search
                        optionFilterProp="label"
                        @change="handleEmployeeChangeEvent"
                      >
                        <a-select-option
                          v-for="employee in filteredEmployees"
                          :key="employee.id"
                          :label="employee.full_name"
                          :value="employee.id"
                        >
                          {{ employee.full_name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>

                  <a-col :sm="12">
                    <a-form-item label="Hospital" name="hospital_id">
                      <a-select
                        v-model:value="formState.hospital_id"
                        placeholder="Select Hospital"
                        show-search
                        allow-clear
                        optionFilterProp="label"
                        :options="employeeHospitals"
                        :disabled="!formState.employee_id || hospitalLoading"
                      >
                      </a-select>
                    </a-form-item>
                  </a-col>

                  <a-col :sm="12">
                    <a-form-item label="Date Range" name="date_range">
                      <a-range-picker
                        v-model:value="formState.date_range"
                        :format="$constants.datepickerFormat"
                        :disabled="!formState.employee_id"
                        :placeholder="[
                          $constants.datePickerPlaceholder,
                          $constants.datePickerPlaceholder,
                        ]"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :sm="12">
                    <a-form-item label="Select Type" name="form_type">
                      <a-select
                        v-model:value="formState.form_type"
                        placeholder="Select Form Type"
                        optionFilterProp="label"
                        @change="handleFormTypeChange"
                      >
                        <a-select-option
                          v-for="form in $constants.competency"
                          :key="form.value"
                          :label="form.label"
                          :value="form.value"
                        >
                          {{ form.label }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>

                  <a-col
                    :sm="12"
                    v-if="
                      groupedOptions.length && formState.form_type !== 'other'
                    "
                  >
                    <a-form-item
                      label="Select Competency"
                      name="select_competency"
                    >
                      <a-select
                        v-model:value="formState.select_competency"
                        placeholder="Select Competency"
                        :options="groupedOptions"
                        @change="handleFormChange"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :sm="12" v-if="formState.form_type !== 'other'">
                    <a-form-item label="Select Assessor" name="assessor_id">
                      <a-select
                        v-model:value="formState.assessor_id"
                        placeholder="Select Assessor"
                        show-search
                        optionFilterProp="label"
                        :options="employeeManagers"
                        :disabled="managerLoading"
                      >
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :sm="12">
                    <a-form-item label="Assessor Name" name="assessor_name">
                      <a-input
                        v-model:value="formState.assessor_name"
                        placeholder="Enter Assessor Name"
                        :disabled="
                          !formState.employee_id ||
                          formState.form_type !== 'other'
                        "
                      />
                    </a-form-item>
                  </a-col>

                  <a-col :sm="12">
                    <a-form-item label="Assessor Email" name="assessor_email">
                      <a-input
                        v-model:value="formState.assessor_email"
                        type="email"
                        :disabled="
                          !formState.employee_id ||
                          formState.form_type !== 'other'
                        "
                        placeholder="Enter Assessor Email"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-spin>
            </a-card>
            <a-card size="small">
              <a-space>
                <a-button
                  :loading="formLoading"
                  type="primary"
                  @click="() => onCreate(0)"
                  >Create</a-button
                >
                <a-button
                  :loading="formLoading"
                  type="primary"
                  @click="() => onCreate(1)"
                  >Create and Send</a-button
                >

                <a-button
                  html-type="button"
                  :loading="previewBtnLoader"
                  @click="previewPDF"
                >
                  Preview PDF
                </a-button>
              </a-space>
            </a-card>
          </a-space>
        </a-col>

        <a-col :span="12">
          <!-- <a-card
              v-if="dynamic_form && formState.select_competency"
              size="small"
              style="height: 600px; overflow: auto"
            >
              <dynamicCompetencyForm
                :formModel="dynamic_form"
                :selectedForm="formState.select_competency"
              />
            </a-card> -->
          <a-card v-if="previewBtnLoader" :loading="true" />
          <template v-else>
            <a-card v-if="!pdfPreviewURL">
              <a-empty>
                <template #description>
                  <span> Preview will be shown here </span>
                </template>
              </a-empty>
            </a-card>

            <iframe
              v-else
              :src="pdfPreviewURL + '#toolbar=0&navpanes=0&scrollbar=0'"
              type="application/pdf"
              width="100%"
              height="500"
              @click.right.stop.prevent
            />
          </template>
        </a-col>
      </a-row>
    </a-form>
  </a-layout-content>
</template>

<script>
import { PlusOutlined, UploadOutlined } from "@ant-design/icons-vue";
import moment from "moment";
import { commonService } from "../../../services";
import dynamicCompetencyForm from "../../dynamicCompetencyForm.vue";
import { constants } from "../../../helper/constants";
import { mapGetters } from "vuex";
export default {
  components: {
    PlusOutlined,
    UploadOutlined,
    dynamicCompetencyForm,
  },
  data() {
    return {
      loading: true,
      formLoading: false,
      hospitalLoading: false,
      managerLoading: false,
      selectedForm: null,
      pdfPreviewURL: null,
      previewBtnLoader: false,
      dynamic_form: null,
      selectedCompetency: null,
      isMailSend: 0,
      groupedOptions: [],
      formState: {
        date_range: [],
        employee_id: undefined,
        hospital_id: undefined,
        assessor_id: undefined,
        assessor_name: null,
        assessor_email: null,
        form_type: undefined,
        select_competency: undefined,
      },
      rules: {
        employee_id: [
          {
            required: true,
            type: "number",
            message: "This field is required",
          },
        ],
        // hospital_id: [
        //   {
        //     required: true,
        //     type: "number",
        //     message: "This field is required",
        //   },
        // ],
        form_type: [
          {
            required: true,
            message: "This field is required",
          },
        ],
        select_competency: [
          {
            required: true,
            message: "This field is required",
          },
        ],
        assessor_id: [
          {
            required: true,
            message: "This field is required",
          },
        ],
        assessor_name: [
          {
            required: true,
            message: "This field is required",
          },
        ],
        assessor_email: [
          {
            required: true,
            message: "This field is required",
          },
          {
            type: "email",
            message: "Please enter valid email address",
          },
        ],
      },
      employees: [],
      employeeHospitals: [],
      employeeManagers: [],
    };
  },
  computed: {
    ...mapGetters("auth", ["employee"]),

    filteredEmployees() {
      return this.employees.filter((emp) => emp.id !== this.employee.id);
    },
  },

  watch: {
    "formState.assessor_id"(newValue) {
      const selectedAssessor = this.employeeManagers.find(
        (manager) => manager.value === newValue
      );
      if (selectedAssessor) {
        this.formState.assessor_name = selectedAssessor.label;
        this.formState.assessor_email = selectedAssessor.email;
      }
    },
  },
  methods: {
    updateDynamicFormWithEmployee() {
      const selectedEmployee = this.employees.find(
        (employee) => employee.id === this.formState.employee_id
      );

      if (selectedEmployee && this.dynamic_form) {
        // Clinician Name field
        const clinicianField = this.dynamic_form.sections[0]?.fields?.find(
          (field) =>
            field.label === "Clinician Name" || field.label === "Employee Name"
        );
        if (clinicianField) {
          clinicianField.value = selectedEmployee.full_name;
        }

        // Clinician Role field
        const clinicianRole = this.dynamic_form.sections[0]?.fields?.find(
          (field) => field.label === "Clinician Role"
        );
        if (clinicianRole) {
          const matchedType = constants.employee_types.find(
            (type) => type.value === selectedEmployee.employee_type
          );
          if (matchedType) {
            clinicianRole.value = matchedType.text;
          }
        }
      }
    },
    handleFormTypeChange(selectedKey) {
      if (this.formState.form_type !== "other") {
        this.formState.select_competency = null;
        this.groupedOptions = [];
        const groupData = this.$constants[selectedKey];
        if (groupData.options) {
          this.groupedOptions = groupData.map((group) => ({
            label: group.label,
            options: group.options.map((option) => ({
              value: option.value,
              label: option.label,
            })),
          }));
        } else {
          this.groupedOptions = groupData;
        }
        const selectedAssessor = this.employeeManagers.find(
          (manager) => manager.value === this.formState.assessor_id
        );
        if (selectedAssessor) {
          this.formState.assessor_name = selectedAssessor.label;
          this.formState.assessor_email = selectedAssessor.email;
        }
      } else {
        import(
          "../../../helper/dynamicCompentencyForms/employee_assessment_form"
        ).then((e) => {
          this.dynamic_form = JSON.parse(JSON.stringify(e.default));
          this.formState.select_competency = "employee_assessment_form";
        });

        this.formState.assessor_name = null;
        this.formState.assessor_email = null;
      }
    },
    handleFormChange() {
      this.dynamic_form = null;
      import(
        "../../../helper/dynamicCompentencyForms/" +
          this.formState.select_competency
      ).then((e) => {
        this.dynamic_form = JSON.parse(JSON.stringify(e.default));

        const dateField = this.dynamic_form.sections[0]?.fields?.find(
          (field) =>
            field.label === "Date of Competency" || field.label === "Date"
        );
        if (dateField) {
          dateField.value = moment().format("MM/DD/YYYY");
        }
        this.updateDynamicFormWithEmployee();
      });
    },

    previewPDF() {
      this.pdfPreviewURL = null;
      this.previewBtnLoader = true;
      commonService
        .downloadFile(
          this.$constants.adminCompentencyFormPreviewPdf,
          { json: this.dynamic_form },
          "sample.pdf",
          true
        )
        .then((res) => {
          this.pdfPreviewURL = window.URL.createObjectURL(
            new Blob([res], {
              type: "application/pdf",
            })
          );
        })
        .catch((err) => {
          this.submitBtnLoading = false;
          this.$message.error(err);
        })
        .finally(() => {
          this.previewBtnLoader = false;
        });
    },

    fetchEmployees() {
      return commonService
        .get(this.$constants.getEmployeesUrl, {
          // select: ["id", "first_name", "last_name"],
        })
        .then((res) => {
          this.employees = res.data;
        })
        .catch((err) => {
          this.$message.error(err || "Something went wrong.");
        })
        .finally(() => {
          //
        });
    },

    fetchEmployeeHospitals(id) {
      this.hospitalLoading = true;
      return commonService
        .get(this.$constants.employeeHospitalShowUrl, {
          id,
          with_hospital: true,
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          this.$message.error(err || "Something went wrong.");
        })
        .finally(() => (this.hospitalLoading = false));
    },
    fetchEmployeeManagers() {
      this.managerLoading = true;
      return commonService
        .get(this.$constants.GetManagers, {
          with_admin: true,
        })
        .then((res) => {
          this.employeeManagers = res.data
            .filter((emp) => emp.id !== this.formState.employee_id)
            .map((v) => ({
              label: v.full_name,
              value: v.id,
              email: v.email,
            }));
        })
        .catch((err) => {
          this.$message.error(err || "Something went wrong.");
        })
        .finally(() => (this.managerLoading = false));
    },

    onCreate(is_mail_send) {
      this.$refs.form.validate().then(() => {
        this.formLoading = true;

        let start_date = null;
        let end_date = null;

        if (
          this.formState.date_range &&
          this.formState.date_range.length === 2
        ) {
          start_date = this.formState.date_range[0]
            ? moment(this.formState.date_range[0]).format("YYYY-MM-DD")
            : null;
          end_date = this.formState.date_range[1]
            ? moment(this.formState.date_range[1]).format("YYYY-MM-DD")
            : null;
        }

        const selectedFormType = this.$constants.competency.find(
          (competency) => competency.value === this.formState.form_type
        );
        const data = {
          ...this.formState,
          start_date,
          end_date,
          key: this.formState.select_competency,
          data: this.dynamic_form,
          form_type: selectedFormType.id,
          is_mail_send: is_mail_send,
        };

        delete data.date_range;

        return commonService
          .store(this.$constants.adminCompentencyFormStore, data)
          .then((res) => {
            this.$message.success(res.message);
            this.$router.push({ name: "admin-competency-assessments-new" });
          })
          .catch((err) => {
            this.$message.error(err);
          })
          .finally(() => (this.formLoading = false));
      });
    },

    async handleEmployeeChangeEvent() {
      this.formState.hospital_id = null;
      this.formState.assessor_id = null;
      this.updateDynamicFormWithEmployee();
      const employeeHospitals = await this.fetchEmployeeHospitals(
        this.formState.employee_id
      );
      this.employeeHospitals = employeeHospitals.map((v) => ({
        label: v.hospital.name,
        value: v.hospital_id,
      }));

      return true;
    },
  },
  async mounted() {
    await this.fetchEmployees();
    await this.fetchEmployeeManagers();
    if (this.formState.employee_id) {
      await this.handleEmployeeChangeEvent();
    }
    this.loading = false;
  },
};
</script>
