<template>
  <div class="competence-assessment-mail-logs">
    <a-descriptions
      :column="1"
      :labelStyle="{ width: '150px' }"
      bordered
      size="small"
      class="mb-sm-1"
    >
      <a-descriptions-item label="Employee">
        {{ record.employee.fl_name }}
      </a-descriptions-item>
      <a-descriptions-item label="Hospital">
        {{ record.hospital?.name ?? "N/A" }}
      </a-descriptions-item>
      <a-descriptions-item label="Name">
        {{ record.assessor_name }}
      </a-descriptions-item>
      <a-descriptions-item label="Email">
        {{ record.assessor_email }}
      </a-descriptions-item>
    </a-descriptions>

    <mail-log :record="record" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import mailLog from "../../../components/mailLog.vue";

export default {
  emits: ["callback"],

  components: { mailLog },

  data() {
    return {
      //
    };
  },
  computed: {
    ...mapGetters("drawer", ["record"]),
  },

  mounted() {
    //
  },

  methods: {
    //
  },
};
</script>
