<template>
  <template v-if="!editMode">
    <a-form :model="model" layout="vertical" @finish="onSubmit" ref="noteForm">
      <a-alert
        v-if="!extra.is_click_note"
        :message="message"
        type="info"
        style="margin-bottom: 10px"
      />
      <a-form-item
        name="note"
        :rules="{
          required: true,
          message: 'This field is required.',
        }"
      >
        <a-textarea
          v-model:value="model.note"
          placeholder="Enter note here..."
          auto-size
        />
      </a-form-item>

      <a-button htmlType="submit" type="primary" :loading="loading">
        Add Note
      </a-button>
    </a-form>
  </template>

  <template v-if="record.notes.length > 0">
    <a-divider orientation="left">Note History</a-divider>

    <a-list
      class="note-histories"
      item-layout="horizontal"
      :data-source="record.notes"
      :rowKey="(record) => record.id"
    >
      <template #renderItem="{ item }">
        <a-list-item>
          <a-comment>
            <template #author>
              <div style="display: flex; align-items: center; gap: 8px">
                <span>{{ item.creator.full_name }}</span>
              </div>
            </template>

            <template #content>
              <div v-if="editedNoteId === item.id">
                <a-textarea
                  v-model:value="editedNote"
                  placeholder="Edit your note"
                  style="width: 600px"
                  auto-size
                /><br />
                <a-space style="margin-top: 10px">
                  <a-button
                    :loading="noteLoading"
                    type="primary"
                    size="small"
                    @click="saveNote(item)"
                  >
                    Save
                  </a-button>
                  <a-button size="small" @click="cancelEdit"> Cancel </a-button>
                </a-space>
              </div>
              <p v-else>{{ item.note }}</p>
            </template>

            <template #datetime>
              <a-space class="comment-section">
                <a-tooltip
                  :title="moment(item.created_at).format('YYYY-MM-DD HH:mm:ss')"
                >
                  <span>{{ moment(item.created_at).fromNow() }}</span>
                </a-tooltip>
                <template v-if="item.is_flagged == 1 || item.is_flagged == 0">
                  <a-tag :color="item.is_flagged == 0 ? 'red' : 'green'">
                    {{ item.is_flagged == 1 ? "Flagged" : "Unflagged" }}
                  </a-tag>
                </template>
                <template v-if="item.is_resolved == 1">
                  <a-tag color="green"> Resolved </a-tag>
                </template>
              </a-space>
            </template>
          </a-comment>
          <template #actions>
            <template v-if="item.creator.id == employee.id && !editMode">
              <a-tooltip title="Edit">
                <a-button type="link" size="small" @click="editNote(item)">
                  <i class="ti ti-pencil ti-lg"></i>
                </a-button>
              </a-tooltip>
            </template>
            <template v-if="item.is_resolved === 0">
              <a-popconfirm
                placement="left"
                title="Are you sure you want to mark this note as resolved?"
                ok-text="Yes"
                :okButtonProps="{ loading: popConfirmLoading }"
                @confirm="markAsResolved(item)"
              >
                <a-tooltip placement="bottom" title="Mark as Resolved">
                  <a-button type="link" size="small">
                    <i class="ti ti-check ti-lg" style="color: green"></i>
                  </a-button>
                </a-tooltip>
              </a-popconfirm>
            </template>
          </template>
        </a-list-item>
      </template>
    </a-list>
  </template>
</template>

<script>
import { PlusOutlined } from "@ant-design/icons-vue";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import formMixins from "../../../../mixins/formMixins";
import { commonService } from "../../../../services";

export default {
  components: {
    PlusOutlined,
  },

  emits: ["callback"],

  data() {
    return {
      moment,
      model: {
        note: null,
      },
      editedNoteId: null,
      editedNote: "",
      editMode: false,
      noteLoading: false,
      popConfirmLoading: false,
    };
  },

  mixins: [formMixins],

  computed: {
    ...mapGetters("drawer", ["record", "loading", "extra"]),
    ...mapGetters("auth", ["isAdmin", "employee"]),

    message() {
      if (this.record.is_flagged == 0) {
        return "You are going to flag this case report.";
      } else {
        return "You are going to Unflag this case report.";
      }
    },
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    async onSubmit() {
      this.loadingStart();

      let url;
      const payload = {
        id: this.record.id,
        case_report_id: this.record.id,
        note: this.model.note,
      };
      if (!this.extra.is_click_note) {
        if (this.record.is_flagged === 0) {
          url = this.$constants.caseReportFlagUrl;
          payload.is_flagged = true;
        } else {
          url = this.$constants.caseReportUnflagUrl;
        }
      } else {
        url = this.$constants.caseReportAddNote;
      }
      try {
        const res = await commonService.store(url, payload);
        this.$message.success(res.message);
        this.record.notes = res.data.notes;
        this.$emit("callback", res.data.notes, res.data.is_flagged);
        this.model.note = null;
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.loadingStop();
      }
    },

    editNote(item) {
      this.editMode = true;
      this.editedNoteId = item.id;
      this.editedNote = item.note;
    },

    cancelEdit() {
      this.editMode = false;
      this.editedNoteId = null;
      this.editedNote = "";
    },

    async saveNote(item) {
      if (!this.editedNote.trim()) {
        this.$message.error("Note cannot be empty.");
        return;
      }

      const payload = {
        id: item.id,
        note: this.editedNote,
      };
      this.noteLoading = true;
      try {
        const res = await commonService.store(
          this.$constants.caseReportUpdateNoteUrl,
          payload
        );
        this.$message.success(res.message);
        item.note = this.editedNote;
        this.cancelEdit();
      } catch (err) {
        this.$message.error(err.message || "Failed to update note.");
      } finally {
        this.editMode = false;
        this.noteLoading = false;
      }
    },
    async markAsResolved(item) {
      const payload = {
        id: item.id,
        is_resolved: 1,
      };
      this.popConfirmLoading = true;
      try {
        const res = await commonService.store(
          this.$constants.caseReportMarkAsResolveNote,
          payload
        );
        this.$message.success(res.message);
        item.is_resolved = 1;
      } catch (err) {
        this.$message.error(err.message);
      } finally {
        this.popConfirmLoading = false;
      }
    },
  },
};
</script>

<style lang="less">
.note-histories {
  .ant-comment {
    .ant-comment-inner {
      padding: 0;
    }
  }
}
</style>
