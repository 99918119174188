<template>
  <div
    style="gap: 8px 4px; display: flex; flex-wrap: wrap; align-items: center"
  >
    <template v-for="(text, index) in para" :key="index">
      {{ text }}
      <a-input
        v-if="index != count"
        :value="modelValue[index]"
        placeholder="Enter here..."
        size="small"
        style="width: 125px"
        @input="(e) => handleInput(e, index)"
        :disabled="disabled"
      />
    </template>
  </div>
</template>

<script>
export default {
  props: ["modelValue", "text", "disabled"],
  emits: ["update:modelValue"],

  computed: {
    para() {
      return this.text.split("%input%");
    },

    count() {
      return this.para.length - 1;
    },
  },

  methods: {
    handleInput(e, index) {
      let value = e.target.value;
      let inputs = [];
      for (let idx = 0; idx < this.count; idx++) {
        if (index == idx) {
          inputs.push(value);
        } else {
          inputs.push(this.modelValue[idx] ?? null);
        }
      }

      this.$emit("update:modelValue", inputs);
    },
  },
};
</script>
