<template>
  <a-modal
    :visible="isModalVisible"
    title="Genereate Competency Exam"
    :footer="null"
    @cancel="handleCancel"
  >
    <a-form ref="examForm" :model="examData" layout="vertical">
      <a-form-item
        ref="select_competency_exam"
        label="Competency Exam"
        name="select_competency_exam"
        :rules="{ required: true, message: 'Please select exam!' }"
      >
        <a-select
          v-model:value="examData.select_competency_exam"
          placeholder="Select Exam..."
          optionFilterProp="label"
          @change="handleFormChange"
        >
          <a-select-option
            v-for="exam in $constants.competencyExamOptions"
            :key="exam.value"
            :label="exam.label"
            :value="exam.value"
          >
            {{ exam.label }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="Employee Name" name="employee_name">
        <a-input
          v-model:value="examData.employee_name"
          disabled
          placeholder="Employee Name"
        />
      </a-form-item>

      <a-form-item label="Evaluator Name" name="assessor_name">
        <a-input
          v-model:value="examData.assessor_name"
          disabled
          placeholder="Evaluator Name"
        />
      </a-form-item>

      <a-space>
        <a-button
          type="primary"
          html-type="button"
          @click="handleOk(0)"
          :loading="loading"
        >
          Create
        </a-button>

        <a-button
          type="primary"
          html-type="button"
          @click="handleOk(1)"
          :loading="loading"
        >
          Create and Send
        </a-button>

        <a-button
          danger
          html-type="button"
          @click="handleCancel"
          :loading="loading"
        >
          Close
        </a-button>
      </a-space>
    </a-form>
  </a-modal>
</template>

<script setup>
import { defineProps, onMounted, ref } from "vue";

const emit = defineEmits(["submit", "close"]);
const props = defineProps(["formState", "isModalVisible", "loading"]);

const dynamic_form = ref(null);
const examForm = ref(null);
const examData = ref({
  select_competency_exam: null,
  employee_name: null,
  assessor_name: null,
  employee_id: null,
  assessor_id: null,
});

const handleOk = (is_mail_send) => {
  examForm.value
    .validate()
    .then(() => {
      const data = {
        examData: {
          select_competency_exam: examData.value.select_competency_exam,
          employee_id: examData.value.employee_id,
          assessor_id: examData.value.assessor_id,
          data: dynamic_form.value,
          is_mail_send: is_mail_send,
        },
      };
      localStorage.setItem("data", JSON.stringify(data));
      emit("submit", data);
    })
    .catch((error) => {
      console.error("Validation failed:", error);
    });
};

const handleCancel = () => {
  emit("close");
};

const handleFormChange = () => {
  dynamic_form.value = null;
  import(
    "../../helper/competencyExamForms/" + examData.value.select_competency_exam
  ).then((e) => {
    dynamic_form.value = JSON.parse(JSON.stringify(e.default));
    const dateField = dynamic_form.value.sections[0]?.fields?.find(
      (field) => field.label === "Date of Competency" || field.label === "Date"
    );
    if (dateField) {
      dateField.value = moment().format("MM/DD/YYYY");
    }
  });
};

onMounted(() => {
  const data = props.formState;
  examData.value.employee_id = data?.employee_id;
  examData.value.assessor_id = data?.assessor_id;
  examData.value.employee_name = data?.employee?.full_name || "N/A";
  examData.value.assessor_name = data?.assessor
    ? `${data.assessor?.last_name}, ${data.assessor?.first_name}`
    : "N/A";
});
</script>
