<template>
  <a-layout-content>
    <page-header>
      <template #title> Case Report </template>

      <template #buttons>
        <!--  -->
      </template>
    </page-header>

    <a-card
      :tabList="tabList"
      :activeTabKey="activeTabKey"
      @tabChange="handleTabChange"
      size="small"
    >
      <template #title>
        <a-row type="flex" :gutter="[16, 0]">
          <a-col flex="auto">
            <a-select
              v-model:value="extraFilters.case_service_ids"
              :options="caseServices"
              mode="multiple"
              allowClear
              placeholder="Search using case service type"
              optionFilterProp="label"
            />
          </a-col>
          <a-col flex="250px">
            <a-range-picker
              v-model:value="extraFilters.date_range"
              :format="$constants.datepickerFormat"
              :placeholder="[
                $constants.datePickerPlaceholder,
                $constants.datePickerPlaceholder,
              ]"
            />
          </a-col>
          <a-col flex="100px">
            <a-space>
              <a-button type="primary" @click="refresh"> Search</a-button>
              <a-button type="ghost" @click="resetFilters"> Reset</a-button>
              <a-button
                class="primary-color-btn"
                type="primary"
                :loading="excelExporting"
                @click="exportExcel"
              >
                <a-space>
                  <i class="ti ti-download ti-lg"></i>
                  Download Excel
                </a-space></a-button
              >
            </a-space>
          </a-col>
        </a-row>
      </template>

      <template #customRender="item">
        {{ item.text }} ({{ item.counts }})
      </template>

      <template #tabBarExtraContent>
        <show-hide-column v-model="columns" />
      </template>

      <a-space direction="vertical" style="width: 100%">
        <template v-if="activeTabKey == 3">
          <a-popconfirm
            v-if="selectedRowKeys.length == 0"
            title="Are you sure you want to upload all completed case reports on QuickBooks?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="uploadInvoiceOnQuickbooks"
          >
            <a-button
              size="small"
              type="primary"
              ghost
              htmlType="button"
              :loading="uploadInvoiceOnQuickbooksLoading"
              :disabled="datatableLoading"
            >
              <i class="ti ti-refresh ti-1x"></i>
              Upload Case Reports on QuickBooks
            </a-button>
          </a-popconfirm>

          <a-button
            v-else
            size="small"
            type="primary"
            ghost
            htmlType="button"
            @click="uploadInvoiceOnQuickbooks"
            :loading="uploadInvoiceOnQuickbooksLoading"
            :disabled="datatableLoading"
          >
            <i class="ti ti-refresh ti-1x"></i>
            Upload Case Reports on QuickBooks
          </a-button>
        </template>

        <a-row v-if="activeTabKey == 2" class="mb-sm-1" justify="space-between">
          <div style="display: flex">
            <a-select
              v-model:value="bulkActionValue"
              placeholder="Select One"
              style="width: 120px; margin-right: 20px"
            >
              <a-select-option value="3"> Approve</a-select-option>
            </a-select>

            <a-button
              html-type="button"
              type="primary"
              :disabled="bulkActionBtnDisabled"
              @click="bulkAction"
            >
              Submit
            </a-button>
          </div>
        </a-row>

        <a-row v-if="activeTabKey == 5" class="mb-sm-1" justify="space-between">
          <a-spin :spinning="datatableLoading">
            <a-space>
              <a-select
                v-model:value="monthlyFilters.month"
                placeholder="Select Month"
                style="width: 200px"
              >
                <a-select-option
                  v-for="(v, i) in originalResponse?.extra?.months ?? []"
                  :key="i"
                  :value="i"
                >
                  {{ v }}
                </a-select-option>
              </a-select>

              <a-select
                v-model:value="monthlyFilters.hospital"
                placeholder="Select Hospital"
                style="width: 200px"
              >
                <a-select-option
                  v-for="(v, i) in originalResponse?.extra?.hospitals ?? []"
                  :key="i"
                  :value="i"
                >
                  {{ v }}
                </a-select-option>
              </a-select>

              <a-button
                html-type="button"
                type="primary"
                :disabled="disabledMonthlyButtons"
                @click="filterMonthlyCaseReports"
              >
                {{ datatableLoading ? "Filtering..." : "Filter" }}
              </a-button>

              <a-button
                html-type="button"
                type="primary"
                danger
                ghost
                :disabled="disabledMonthlyButtons"
                @click="resetMonthlyCaseReportFilters"
              >
                Reset
              </a-button>

              <a-popconfirm
                v-if="!disabledMonthlyButtons"
                title="Are you sure you want to upload selected monthly case reports on QuickBooks?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="uploadMonthlyInvoiceOnQuickbooks"
              >
                <a-button
                  type="primary"
                  ghost
                  htmlType="button"
                  :disabled="datatableLoading"
                >
                  <i class="ti ti-refresh ti-1x"></i>
                  Upload Monthly Case Reports on QB
                </a-button>
              </a-popconfirm>

              <a-button
                v-else
                type="primary"
                ghost
                htmlType="button"
                :disabled="disabledMonthlyButtons"
              >
                <i class="ti ti-refresh ti-1x"></i>
                Upload Monthly Case Reports on QB
              </a-button>
            </a-space>
          </a-spin>
        </a-row>

        <a-table
          class="ant-table-striped"
          :row-selection="rowSelection"
          :columns="columns.filter((e) => e.show ?? true)"
          :rowKey="(record) => record.id"
          :dataSource="dataSource"
          :pagination="pagination"
          :loading="datatableLoading"
          @change="handleTableChange"
          @refresh="refresh"
          size="small"
          :scroll="scroll"
          :rowClassName="
            (record, index) =>
              record.is_flagged
                ? 'flagged-row'
                : index % 2 === 1
                ? 'table-striped'
                : null
          "
        >
          <template #filterDropdown="filterDropdown">
            <xFilterInputSearchDropdown
              :filterDropdown="filterDropdown"
              @handleSearch="handleDatatableSearch"
              @handleReset="handleDatatableReset"
            />
          </template>

          <template #filterIcon="filterIcon">
            <xFilterIcon :filterIcon="filterIcon" />
          </template>

          <template #date="{ record, text }">
            <a-space :size="1">
              {{ $customDate.mdy(text) }}
              <a-tooltip v-if="record.quickbooks_invoice_id" placement="top">
                <template #title>
                  Invoice No:
                  {{ JSON.parse(record.quickbooks_invoice_info).DocNumber }}
                </template>
                <a-button size="small" type="link">
                  <i class="ti ti-info-circle ti-lg"> </i>
                </a-button>
              </a-tooltip>
            </a-space>
          </template>

          <template #action="{ record }">
            <a-space :size="1">
              <router-link
                :to="{
                  name: 'admin.show-case-report',
                  params: { uuid: record.uuid },
                }"
              >
                <a-tooltip title="View">
                  <a-button
                    type="link"
                    size="small"
                    @click="
                      $comman.caseReportLogStore({
                        id: record.id,
                        action: 'View Record',
                      })
                    "
                  >
                    <i class="ti ti-eye ti-lg"></i>
                  </a-button>
                </a-tooltip>
              </router-link>

              <router-link
                :to="{
                  name: 'admin.create-case-report',
                  query: { uuid: record.uuid },
                }"
              >
                <a-tooltip title="Edit">
                  <a-button
                    type="link"
                    size="small"
                    @click="
                      $comman.caseReportLogStore({
                        id: record.id,
                        action: 'Edit Record',
                      })
                    "
                  >
                    <i class="ti ti-pencil ti-lg"></i>
                  </a-button>
                </a-tooltip>
              </router-link>

              <template v-if="record.status == 2">
                <a-popconfirm
                  title="Are you sure you want to approve this case report?"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="
                    () => {
                      $comman.caseReportLogStore({
                        id: record.id,
                        action: 'Approve Record',
                      });
                      handleStatusChangeEvent({ id: record.id, status: '3' });
                    }
                  "
                >
                  <a-tooltip title="Approve Case Report">
                    <a-button size="small" type="link">
                      <i class="ti ti-status-change ti-lg"></i>
                    </a-button>
                  </a-tooltip>
                </a-popconfirm>
              </template>
              <a-tooltip title="Notes">
                <a-badge :count="record.notes.length" :offset="[-6, 0]">
                  <a-button
                    type="link"
                    size="small"
                    @click="addNotes(record, true)"
                  >
                    <i class="ti ti-notes ti-lg"></i>
                  </a-button>
                </a-badge>
              </a-tooltip>
              <template
                v-if="[3, 4, 5].includes(record.status) && record.hospital?.ftp"
              >
                <a-tooltip title="Upload PDF On FTP Server" placement="left">
                  <a-button
                    type="link"
                    size="small"
                    :class="`${
                      record.case_report_ftp_success_logs_count > 0
                        ? 'primary-color'
                        : 'danger-color'
                    }`"
                    class="text-bold"
                    @click="uploadPDFOnServer(record)"
                  >
                    <i class="ti ti-upload ti-lg"></i>
                  </a-button>
                </a-tooltip>
              </template>

              <a-dropdown placement="bottomLeft">
                <a-button size="small" type="link">
                  <i class="ti ti-dots-vertical ti-lg"></i>
                </a-button>
                <template #overlay>
                  <a-menu>
                    <a-menu-item>
                      <a-tooltip
                        v-if="record.is_flagged == 0"
                        title="Mark as Flagged"
                        placement="left"
                      >
                        <a-button
                          type="link"
                          size="small"
                          @click="addNotes(record, false)"
                        >
                          <i class="ti ti-flag ti-lg"></i>
                        </a-button>
                      </a-tooltip>
                      <a-tooltip
                        v-else
                        title="Mark as Unflagged"
                        placement="left"
                      >
                        <a-button
                          type="link"
                          size="small"
                          @click="addNotes(record, false)"
                        >
                          <i class="ti ti-flag-off ti-lg"></i>
                        </a-button>
                      </a-tooltip>
                    </a-menu-item>

                    <a-menu-item v-if="record.status == 2">
                      <a-button
                        size="small"
                        type="link"
                        @click="handleRevertbacktoPending(record)"
                      >
                        <i class="ti ti-arrow-back ti-lg"></i>
                      </a-button>
                    </a-menu-item>

                    <a-menu-item v-if="showLiveChartingButton(record)">
                      <router-link
                        :to="{
                          name: 'admin.case-report-live-charting',
                          params: { uuid: record.uuid },
                        }"
                      >
                        <a-tooltip title="Live Charting">
                          <a-button type="link" size="small">
                            <i class="ti ti-device-heart-monitor ti-lg"></i>
                          </a-button>
                        </a-tooltip>
                      </router-link>
                    </a-menu-item>

                    <template v-if="![3, 5].includes(record.status)">
                      <a-menu-item>
                        <a-popconfirm
                          title="Are you sure you want to delete this case report?"
                          ok-text="Yes"
                          cancel-text="No"
                          @confirm="
                            () => {
                              $comman.caseReportLogStore({
                                id: record.id,
                                action: 'Delete Record',
                              });
                              handleStatusChangeEvent({
                                id: record.id,
                                status: '0',
                              });
                            }
                          "
                        >
                          <a-tooltip title="Delete" placement="left">
                            <a-button size="small" type="link" danger>
                              <i class="ti ti-trash ti-lg"></i>
                            </a-button>
                          </a-tooltip>
                        </a-popconfirm>
                      </a-menu-item>
                    </template>

                    <template v-if="record.status != 1">
                      <a-menu-item>
                        <a-tooltip title="Download PDF" placement="left">
                          <a-button
                            type="link"
                            size="small"
                            @click="downloadPdf(record)"
                          >
                            <i class="ti ti-download ti-lg"></i>
                          </a-button>
                        </a-tooltip>
                      </a-menu-item>

                      <a-menu-item>
                        <a-tooltip title="Download Excel" placement="left">
                          <a-button
                            type="link"
                            size="small"
                            @click="downloadExcel(record)"
                          >
                            <i class="ti ti-file-spreadsheet ti-lg"></i>
                          </a-button>
                        </a-tooltip>
                      </a-menu-item>

                      <a-menu-item>
                        <a-tooltip title="Print" placement="left">
                          <a-button
                            type="link"
                            size="small"
                            @click="renderPdf(record)"
                          >
                            <i class="ti ti-printer ti-lg"></i>
                          </a-button>
                        </a-tooltip>
                      </a-menu-item>

                      <a-menu-item>
                        <a-tooltip title="Billing PDF" placement="left">
                          <a-button
                            type="link"
                            size="small"
                            @click="downloadBillingPdf(record)"
                          >
                            <i class="ti ti-file-dollar ti-lg"></i>
                          </a-button>
                        </a-tooltip>
                      </a-menu-item>
                    </template>

                    <a-menu-item v-if="record.quickbooks_invoice_id">
                      <a-tooltip title="Send Email" placement="left">
                        <a-button
                          type="link"
                          size="small"
                          @click="sendEmail(record.id)"
                        >
                          <i class="ti ti-mail-forward ti-lg"></i>
                        </a-button>
                      </a-tooltip>
                    </a-menu-item>

                    <a-menu-item v-if="record.status == 1">
                      <a-tooltip title="Send Reminder" placement="left">
                        <a-button
                          type="link"
                          size="small"
                          @click="sendReminder(record.id)"
                        >
                          <i class="ti ti-mail-fast ti-lg"></i>
                        </a-button>
                      </a-tooltip>
                    </a-menu-item>

                    <a-menu-item v-if="record.mail_logs.length > 0">
                      <a-tooltip title="Mail Logs">
                        <a-button
                          type="link"
                          size="small"
                          @click="showOfferMailLog(record)"
                        >
                          <i class="ti ti-history ti-lg"></i>
                        </a-button>
                      </a-tooltip>
                    </a-menu-item>
                    <a-menu-item v-if="record.status == 3">
                      <a-tooltip title="Conditional QBO Sync" placement="left">
                        <a-button
                          type="link"
                          size="small"
                          @click="updateConditionalQBOSync(record)"
                        >
                          <i class="ti ti-refresh-alert"></i>
                        </a-button>
                      </a-tooltip>
                    </a-menu-item>
                    <a-menu-item v-if="[3, 5].includes(record.status)">
                      <a-tooltip title="Update Service Prices">
                        <a-button
                          type="link"
                          size="small"
                          @click="updateServicePrices(record)"
                        >
                          <i class="ti ti-currency-dollar ti-lg"></i>
                        </a-button>
                      </a-tooltip>
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </a-space>
          </template>
        </a-table>
      </a-space>
    </a-card>
  </a-layout-content>
</template>

<script>
import { commonService } from "@/services";
import { PlusOutlined } from "@ant-design/icons-vue";
import moment from "moment-timezone";
import { h } from "vue";
import { mapActions } from "vuex";
import showHideColumn from "../../../components/table/showHideColumn.vue";
import datatableMixins from "../../../mixins/datatableMixins";

export default {
  components: {
    PlusOutlined,
    showHideColumn,
  },

  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.caseReportDatatableForAdminUrl,
      statusChangeUrl: this.$constants.caseReportChangeStatusUrl,
      selectedRowKeys: [],
      bulkActionValue: null,
      monthlyFilters: {
        month: null,
        hospital: null,
      },
      activeTabKey: this.$route.query?.status ?? "1",
      tabList: this.$constants.caseReportStatusesForAdmin.map((item) => ({
        key: item.value.toString(),
        counts: 0,
        text: item.text,
        status: item.value,
        slots: { tab: "customRender" },
      })),
      defaultFilterParams: {
        report_no: this.$route.query?.report_no
          ? [this.$route.query?.report_no]
          : undefined,
        hospital_name: this.$route.query?.hospital_name
          ? [this.$route.query?.hospital_name]
          : undefined,
        mr_number: this.$route.query?.mr_number
          ? [this.$route.query?.mr_number]
          : undefined,
        employee_name: this.$route.query?.employee_name
          ? [this.$route.query?.employee_name]
          : undefined,
        sortField: this.$route.query?.sortField ?? "created_at",
        sortOrder: this.$route.query?.sortOrder ?? "descend",
      },
      columns: [
        {
          title: "Date",
          dataIndex: "date",
          key: "date",
          sorter: true,
          width: 150,
          slots: {
            customRender: "date",
          },
          defaultSortOrder:
            this.$route.query?.sortField == "date"
              ? this.$route.query.sortOrder
              : undefined,
        },
        {
          title: "Report No",
          dataIndex: "report_no",
          key: "report_no",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.report_no
            ? [this.$route.query?.report_no]
            : undefined,
        },
        {
          title: "Facility",
          dataIndex: "hospital_name",
          key: "hospital_name",
          customRender: ({ record }) => record.hospital?.name ?? "-",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.hospital_name
            ? [this.$route.query?.hospital_name]
            : undefined,
        },
        {
          title: "MR No",
          dataIndex: "mr_number",
          key: "mr_number",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.mr_number
            ? [this.$route.query?.mr_number]
            : undefined,
        },
        {
          title: "Created By",
          dataIndex: "employee_name",
          key: "employee_name",
          customRender: ({ record }) => record.employee?.full_name ?? "-",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.employee_name
            ? [this.$route.query?.employee_name]
            : undefined,
        },
        {
          title: "Service + Products = Total",
          dataIndex: "total_price",
          key: "total_price",
          customRender: ({ record }) =>
            `${this.$comman.withCurrency(
              record.case_service_price + record.ot_price
            )} + ${this.$comman.withCurrency(
              record.product_price
            )} = ${this.$comman.withCurrency(record.total_price)}`,
        },
        {
          title: "Approved By",
          dataIndex: "approver_name",
          key: "approver_name",
          customRender: ({ record }) => record.approver?.full_name ?? "-",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.approver_name
            ? [this.$route.query?.approver_name]
            : undefined,
          show: false,
        },
        {
          title: "Created Date",
          dataIndex: "created_at",
          key: "created_at",
          sorter: true,
          customRender: ({ text }) => this.$customDate.ll(text),
          defaultSortOrder: this.$route.query?.sortField
            ? this.$route.query?.sortField == "created_at"
              ? this.$route.query.sortOrder
              : undefined
            : "descend",
          show: false,
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          fixed: "right",
          width: 160,
          slots: { customRender: "action" },
        },
      ],

      uploadInvoiceOnQuickbooksLoading: false,
      caseServices: [],
      excelExporting: false,
    };
  },

  computed: {
    rowSelection() {
      return ["2", "3"].includes(this.activeTabKey)
        ? {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange,
          }
        : null;
    },

    bulkActionBtnDisabled() {
      return this.bulkActionValue == null || this.selectedRowKeys.length == 0;
    },

    disabledMonthlyButtons() {
      return (
        this.monthlyFilters.month == null ||
        this.monthlyFilters.hospital == null
      );
    },
  },

  mounted() {
    this.fetchCaseServices();

    // EXTRA FILTERS
    let caseServiceIds = this.$route.query.case_service_ids;
    if (caseServiceIds) {
      if (Array.isArray(caseServiceIds)) {
        this.extraFilters.case_service_ids = caseServiceIds.map((i) =>
          parseInt(i)
        );
      } else {
        this.extraFilters.case_service_ids = [parseInt(caseServiceIds)];
      }
    }
    if (this.$route?.query?.date_range) {
      let dateRange = this.$route.query.date_range;
      this.extraFilters.date_range = [
        moment(parseInt(dateRange[0])),
        moment(parseInt(dateRange[1])),
      ];
    }
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    handleTabChange(key) {
      this.activeTabKey = key;
      this.selectedRowKeys = [];
    },

    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },

    fetchCaseServices() {
      return commonService
        .get(this.$constants.getCaseServicesUrl)
        .then((res) => {
          this.caseServices = res.data.map((item) => ({
            text: item.name,
            label: item.name,
            value: item.id,
          }));
        });
    },
    addNotes(record, is_click_note) {
      this.open({
        title: `Case Report Notes | #${record.report_no}`,
        path: "admin.newCaseReport.drawerForm.notes",
        callback: (notes, is_flag) => {
          record.notes = notes;
          record.is_flagged = is_flag;
        },
        record: record,
        extra: { is_click_note: is_click_note },
      });
    },
    handleRevertbacktoPending(record) {
      this.open({
        title: "Revert back to Pending",
        path: "admin.newCaseReport.drawerForm.revertBackToPending",
        record,
        callback: this.refresh,
      });
    },

    updateServicePrices(record) {
      this.open({
        title: "Update Case Service Prices",
        path: "admin.newCaseReport.drawerForm.updateCaseServicePrices",
        record,
        callback: this.refresh,
      });
    },
    updateConditionalQBOSync(record) {
      this.open({
        title: "Conditional QBO Sync",
        path: "admin.newCaseReport.drawerForm.updateConditionalQBOSync",
        record,
        callback: this.refresh,
      });
    },
    resetFilters() {
      this.extraFilters.case_service_ids = [];
      this.refresh();
    },

    bulkAction() {
      this.$comman.caseReportLogStore({
        id: this.selectedRowKeys,
        action: "Approve Record",
      });
      this.handleStatusChangeEvent({ id: this.selectedRowKeys, status: "3" });
    },

    downloadPdf(record) {
      this.$comman.caseReportLogStore({
        id: record.id,
        action: "PDF Download",
      });
      this.datatableLoading = true;

      commonService
        .downloadFile(
          this.$constants.caseReportExportPdfUrl,
          { id: record.id },
          `${record.report_no}_case_report.pdf`
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.datatableLoading = false;
        });
    },

    downloadExcel(record) {
      this.$comman.caseReportLogStore({
        id: record.id,
        action: "Excel Download",
      });
      this.datatableLoading = true;

      commonService
        .downloadFile(
          this.$constants.caseReportExportExcelUrl,
          { id: record.id },
          `${record.report_no}_case_report.xlsx`
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.datatableLoading = false;
        });
    },

    exportExcel() {
      this.excelExporting = true;

      commonService
        .downloadFile(
          this.$constants.caseReportDatatableForAdminUrl,
          {
            ...this.extraFilters,
            ...this.filterParams,
            result: this.pageSize,
            excel_export: true,
          },
          `Case Report.xlsx`
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.excelExporting = false;
        });
    },

    renderPdf(record) {
      this.$comman.caseReportLogStore({
        id: record.id,
        action: "Render Download",
      });
      this.datatableLoading = true;

      commonService
        .renderFile(
          this.$constants.caseReportExportPdfUrl,
          { id: record.id },
          "application/pdf;base64"
        )
        .then((res) => {
          // this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.datatableLoading = false;
        });
    },

    uploadInvoiceOnQuickbooks() {
      this.uploadInvoiceOnQuickbooksLoading = true;

      commonService
        .store(this.$constants.uploadInvoiceOnQuickbooksUrl, {
          ids: this.selectedRowKeys,
        })
        .then((res) => {
          if (res.success) {
            if (res.count > 0) {
              this.$notification.success({
                message: res.message,
                duration: 10,
              });
            }
            if (res.errors != "") {
              setTimeout(() => {
                this.$notification.error({
                  message: "Errors",
                  description: h("div", {
                    innerHTML: res.errors,
                  }),
                  duration: 10,
                });
              }, 1000);
            }
            this.refresh();
          }
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.uploadInvoiceOnQuickbooksLoading = false;
        });
    },

    sendEmail(id) {
      this.datatableLoading = true;
      commonService
        .store(this.$constants.sendInvoiceOnQuickbooksUrl, { id })
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message);
          } else {
            this.$message.error("Something went wrong.");
          }
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.datatableLoading = false;
        });
    },

    sendReminder(id) {
      this.datatableLoading = true;
      commonService
        .store(this.$constants.caseReportSendReminderMailUrl, { id })
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message);
          } else {
            this.$message.error("Something went wrong.");
          }
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.datatableLoading = false;
        });
    },

    uploadPDFOnServer(report) {
      if (report.hospital.ftp.is_enable == 0) {
        this.$message.error(
          "Please enable FTP/SFTP in order to send case report to this hospital."
        );

        return;
      }

      this.datatableLoading = true;
      commonService
        .store(this.$constants.caseReportUploadPDFOnServerUrl, {
          id: report.id,
        })
        .then((res) => {
          report.case_report_ftp_success_logs_count += 1;
          this.$message.success(res.message);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.datatableLoading = false;
        });
    },

    downloadBillingPdf(report) {
      this.datatableLoading = true;
      commonService
        .downloadFile(
          this.$constants.caseReportExportBillingPdfUrl,
          { id: report.id },
          `${report.report_no}_billing.pdf`
        )
        .then((res) => {
          this.$message.success("Billing PDF downloaded successfully.");
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.datatableLoading = false;
        });
    },

    showLiveChartingButton(record) {
      if (
        // record.quickbooks_invoice_id != null ||
        // (record.status == 3 && record.total_price == 0) ||
        record.hospital.is_live_charting_enable != 1
      ) {
        return false;
      }
      return this.$constants.caseServicesIdForCPB
        .concat(this.$constants.caseServicesIdForATS)
        .concat(this.$constants.caseServicesIdForHIPEC)
        .concat(this.$constants.caseServicesIdForECMO)
        .concat(this.$constants.caseServicesIdForNRP)
        .filter((value, index, array) => array.indexOf(value) === index)
        .reduce(
          (bool, curr) => bool || record.data.services.includes(curr),
          false
        );
    },

    filterMonthlyCaseReports() {
      this.refresh(
        {},
        {
          monthly_filters: this.monthlyFilters,
        }
      );
    },

    uploadMonthlyInvoiceOnQuickbooks() {
      this.datatableLoading = true;

      commonService
        .store(this.$constants.uploadMonthlyInvoiceOnQuickbooksUrl, {
          month: this.monthlyFilters.month,
          hospital_id: this.monthlyFilters.hospital,
        })
        .then((res) => {
          if (res.success) {
            if (res.count > 0) {
              this.$notification.success({
                message: res.message,
                duration: 10,
              });
            }
            if (res.errors != "") {
              setTimeout(() => {
                this.$notification.error({
                  message: "Errors",
                  description: h("div", {
                    innerHTML: res.errors,
                  }),
                  duration: 10,
                });
              }, 1000);
            }
            this.resetMonthlyCaseReportFilters();
            this.filterMonthlyCaseReports();
          }
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.datatableLoading = false;
        });
    },

    resetMonthlyCaseReportFilters() {
      this.monthlyFilters.month = null;
      this.monthlyFilters.hospital = null;
    },

    showOfferMailLog(record) {
      this.open({
        title: "Mail Logs",
        path: "admin.newCaseReport.mail_logs",
        callback: this.refresh,
        record,
      });
    },
  },

  watch: {
    activeTabKey() {
      this.resetMonthlyCaseReportFilters();
    },
  },
};
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
</style>
