import { route } from "@/helper/route";
import auth from "./middleware/auth";
import hasRoles from "./middleware/hasRoles";

export default [
  {
    path: "/admin/announcements",
    name: "admin-announcements",
    component: route("admin/announcements/index"),
    meta: {
      title: "Announcements",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/admin/expenses",
    name: "admin-expenses",
    component: route("admin/expense/index"),
    meta: {
      title: "Expenses",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/admin/competency-assessments",
    name: "admin-competency-assessments",
    component: route("admin/competenceAssessments/index"),
    meta: {
      title: "Competency Assessment",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/admin/competency-assessments/store",
    name: "admin-competency-assessments-store",
    component: route("admin/competenceAssessments/storeNew"),
    meta: {
      title: "Competency Assessment Store",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },

  {
    path: "/admin/competency-assessments-new",
    name: "admin-competency-assessments-new",
    component: route("admin/competencyAssessments/index"),
    meta: {
      title: "Competency Assessment",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },

  {
    path: "/admin/competency-assessments-new/store",
    name: "admin-competency-assessments-new-store",
    component: route("admin/competencyAssessments/store"),
    meta: {
      title: "Store Competency Assessment",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/admin/competency-assessment/show/:id",
    name: "admin-competency-assessment-new-show",
    component: route("admin/competencyAssessments/show"),
    meta: {
      title: "Show Competency Assessment",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },

  {
    path: "/admin/competency-exam",
    name: "admin-competency-exam",
    component: route("admin/competencyExamForms/index"),
    meta: {
      title: "Competency Exam",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/admin/competency-exam/store",
    name: "admin-competency-exam-store",
    component: route("admin/competencyExamForms/store"),
    meta: {
      title: "Store Competency Exam",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/admin/competency-exam/show/:id",
    name: "admin-competency-exam-show",
    component: route("admin/competencyExamForms/show"),
    meta: {
      title: "Show Competency Exam",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/admin/approvals",
    name: "admin-approvals",
    component: route("admin/approvals/index"),
    meta: {
      title: "Onboarding Approvals",
      layout: "app",
      middleware: [auth, hasRoles],
      extra_access: "admin-credentialing-approvals",
      roles: 1,
    },
  },
  {
    path: "/admin/expense/edit/:id",
    name: "admin-update-expense",
    component: route("admin/expense/update"),
    meta: {
      title: "Update Expense",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },

  {
    path: "/admin/incidents",
    name: "admin-incidents",
    component: route("admin/incident/index"),
    meta: {
      title: "Incidents",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/admin/incident/show/:id",
    name: "admin-incident-show",
    component: route("admin/incident/show"),
    meta: {
      title: "Show Incident",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },

  {
    path: "/admin/employee-time-tracks",
    name: "admin-employee-time-tracks",
    component: route("admin/employeeTimeTrack/index"),
    meta: {
      title: "Employee Time Tracks",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/admin/employee-time-track/edit/:id",
    name: "admin-update-employee-time-track",
    component: route("admin/employeeTimeTrack/update"),
    meta: {
      title: "Update Employee Time",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },

  {
    path: "/admin/orders",
    name: "admin-order",
    component: route("admin/order/index"),
    meta: {
      title: "Orders",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
      extra_access: "admin-order",
    },
  },
  {
    path: "/admin/order/edit/:order_no",
    name: "admin-update-order",
    component: route("admin/order/update"),
    meta: {
      title: "Update Order",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
      extra_access: "admin-order",
    },
  },
  {
    path: "/admin/order/show/:order_no",
    name: "admin-view-order",
    component: route("admin/order/view"),
    meta: {
      title: "View Order",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
      extra_access: "admin-order",
    },
  },

  {
    path: "/admin/bills",
    name: "admin-bill",
    component: route("admin/bill/index"),
    meta: {
      title: "Bills",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },

  {
    path: "/admin/performance-evaluations",
    name: "admin-performance-evaluation",
    component: route("admin/performanceAppraisals/index"),
    meta: {
      title: "Performance Evaluations",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/admin/performance-evaluation/create",
    name: "admin-create-performance-evaluation",
    component: route("admin/performanceAppraisals/store"),
    meta: {
      title: "Create Performance Evaluation",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/admin/performance-evaluation/edit/:id",
    name: "admin-edit-performance-evaluation",
    component: route("admin/performanceAppraisals/edit"),
    meta: {
      title: "Edit Performance Evaluation",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
  {
    path: "/admin/performance-evaluation/show/:id",
    name: "admin-show-performance-evaluation",
    component: route("admin/performanceAppraisals/show"),
    meta: {
      title: "Show Performance Evaluation",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },

  {
    path: "/admin/settings",
    name: "admin-settings",
    component: route("admin/setting/index"),
    meta: {
      title: "Settings",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },

  {
    path: "/admin/dispatched-orders",
    name: "admin-dispatched-orders",
    component: route("admin/dispatchedOrder/index"),
    meta: {
      title: "Dispatched Orders",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
      extra_access: "admin-dispatched-orders",
    },
  },

  {
    path: "/admin/inventory-logs",
    name: "admin-inventory-logs",
    component: route("admin/inventoryLog/index"),
    meta: {
      title: "Inventory Logs",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },

  {
    path: "/admin/login-sliders",
    name: "admin-login-sliders",
    component: route("admin/loginSlider/index"),
    meta: {
      title: "Login Slider",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },

  {
    path: "/admin/email-smtp",
    name: "admin-email-smtp",
    component: route("admin/emailSmtp/index"),
    meta: {
      title: "Email SMTP",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },

  {
    path: "/admin/missing-item-orders",
    name: "admin.missing-item-orders",
    component: route("admin/missingItemOrders"),
    meta: {
      title: "Missing Item Orders",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },

  {
    path: "/admin/recent-updates",
    name: "admin.recent-update",
    component: route("admin/recentUpdate/index"),
    meta: {
      title: "Recent Updates",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },

  {
    path: "/admin/feedback",
    name: "admin.feedback",
    component: route("admin/feedback/index"),
    meta: {
      title: "Feedback",
      layout: "app",
      middleware: [auth, hasRoles],
      roles: 1,
    },
  },
];
