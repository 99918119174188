import { route } from "@/helper/route";
import auth from "./middleware/auth";
import profile from "./middleware/profile";

export default [
  {
    path: "/competency-assessment",
    name: "competency-assessment",
    component: route("competencyAssessment/index"),
    meta: {
      title: "competency Assessment",
      layout: "app",
      middleware: [auth, profile],
    },
  },
  {
    path: "/competency-assessment/show/:id",
    name: "show-competency-assessment",
    component: route("competencyAssessment/show"),
    meta: {
      title: "Show Competency Assessment",
      layout: "app",
      middleware: [auth, profile],
    },
  },

];