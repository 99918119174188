<template>
  <template v-if="typeof heads[0] == 'string'">
    <tr>
      <template v-for="head in heads" :key="head">
        <th>{{ head }}</th>
      </template>
    </tr>
  </template>

  <template v-else>
    <template v-for="head in heads" :key="head">
      <tr>
        <template v-for="row in head" :key="row">
          <th v-bind="thProps(row)" :style="{width: row.width}">
            {{ row.text }}
          </th>
        </template>
      </tr>
    </template>
  </template>
</template>

<script>
export default {
  name: "dynamic-competency-form-table-head",

  props: {
    heads: {
      type: [Object],
      required: true,
    },
  },

  methods: {
    thProps(row) {
      let obj = {};
      if (row.col_span) {
        obj.colspan = row.col_span;
      }
      if (row.row_span) {
        obj.rowspan = row.row_span;
      }
      if(row.width){
        obj.width = row.width;
      }
      return obj;
    },
  },
};
</script>
