<template>
  <a-layout-content>
    <a-page-header
      @back="$router.go(-1)"
      class="page-header"
      style="margin-bottom: 24px; background-color: #fff"
    >
      <template #title>Add Competency Assessment</template>
    </a-page-header>
    <a-form
      :model="formState"
      :rules="rules"
      layout="vertical"
      @finish="onSubmit"
    >
      <a-space direction="vertical" style="width: 100%">
        <a-card size="small">
          <a-spin :spinning="loading">
            <a-row :gutter="[20, 0]">
              <a-col :sm="24">
                <a-form-item label="Employee" name="employee_id">
                  <a-select
                    v-model:value="formState.employee_id"
                    placeholder="Select Employee"
                    show-search
                    optionFilterProp="label"
                    :disabled="extra.fromEmployees"
                    @change="handleEmployeeChangeEvent"
                  >
                    <a-select-option
                      v-for="employee in employees"
                      :key="employee.id"
                      :label="employee.full_name"
                      :value="employee.id"
                    >
                      {{ employee.full_name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :sm="12">
                <a-form-item label="Hospital" name="hospital_id">
                  <a-select
                    v-model:value="formState.hospital_id"
                    placeholder="Select Hospital"
                    show-search
                    allow-clear
                    optionFilterProp="label"
                    :options="employeeHospitals"
                    :disabled="!formState.employee_id"
                  >
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :sm="12">
                <a-form-item label="Date Range" name="date_range">
                  <a-range-picker
                    v-model:value="formState.date_range"
                    :format="$constants.datepickerFormat"
                    :disabled="!formState.employee_id"
                    :placeholder="[
                      $constants.datePickerPlaceholder,
                      $constants.datePickerPlaceholder,
                    ]"
                  />
                </a-form-item>
              </a-col>

              <a-col :sm="12">
                <a-form-item label="Assessor Name" name="assessor_name">
                  <a-input
                    v-model:value="formState.assessor_name"
                    placeholder="Enter Assessor Name"
                    :disabled="!formState.employee_id"
                  />
                </a-form-item>
              </a-col>

              <a-col :sm="12">
                <a-form-item label="Assessor Email" name="assessor_email">
                  <a-input
                    v-model:value="formState.assessor_email"
                    type="email"
                    :disabled="!formState.employee_id"
                    placeholder="Enter Assessor Email"
                  />
                </a-form-item>
              </a-col>
              <a-col :sm="12">
                <a-form-item label="Select Type" name="select_form">
                  <a-select
                    v-model:value="selectedForm"
                    placeholder="Select Form Type"
                    optionFilterProp="label"
                    @change="handleFormTypeChange"
                  >
                    <a-select-option
                      v-for="form in $constants.Competency"
                      :key="form.value"
                      :label="form.label"
                      :value="form.value"
                    >
                      {{ form.label }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :sm="12" v-if="groupedOptions.length">
                <a-form-item label="Select Competency" name="select_competency">
                  <!-- <a-select
                      v-model:value="selectedCompetency"
                      placeholder="Select Competency"
                      optionFilterProp="label"
                      @change="handleFormChange"
                    >
                      {{ console.log('groupoption:', groupedOptions.some((group) => group.options))}}
                      <template
                        v-if="groupedOptions.some((group) => group.options)"
                      >
                        <a-select-opt-group
                          v-for="group in groupedOptions"
                          :key="group.label"
                          :label="group.label"
                        >
                          <a-select-option
                            v-for="option in group.options"
                            :key="option.value"
                            :value="option.value"
                          >
                            {{ option.label }}
                          </a-select-option>
                        </a-select-opt-group>
                      </template>
                      <template v-else>
                        <a-select-option
                          v-for="option in groupedOptions"
                          :key="option.value"
                          :value="option.value"
                        >
                          {{ option.label }}
                        </a-select-option>
                      </template>
                    </a-select> -->

                  <a-select
                    v-model:value="selectedCompetency"
                    placeholder="Select Competency"
                    :options="groupedOptions"
                    @change="handleFormChange"
                  />
                </a-form-item>
              </a-col>
            </a-row>
          </a-spin>
        </a-card>
        <a-card
          v-if="dynamic_form && selectedCompetency"
          size="small"
          style="height: 500px; overflow: auto"
        >
          <dynamicCompetencyForm
            :formModel="dynamic_form"
            :selectedForm="selectedCompetency"
          />
        </a-card>
        <a-card size="small">
          <a-button type="primary" html-type="submit">Preview Email</a-button>
        </a-card>
      </a-space>
    </a-form>
  </a-layout-content>
</template>

<script>
import { PlusOutlined, UploadOutlined } from "@ant-design/icons-vue";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { commonService } from "../../../services";
import dynamicCompetencyForm from "../../dynamicCompetencyForm.vue";
import { options } from "less";
import { constants } from "../../../helper/constants";
export default {
  components: {
    PlusOutlined,
    UploadOutlined,
    dynamicCompetencyForm,
  },
  data() {
    return {
      loading: true,
      selectedForm: null,
      dynamic_form: null,
      selectedCompetency: null,
      groupedOptions: [],
      formState: {
        date_range: [],
        employee_id: null,
        hospital_id: null,
        assessor_name: null,
        assessor_email: null,
      },
      rules: {
        date_range: [
          {
            required: true,
            message: "Please select date range",
          },
        ],
        employee_id: [
          {
            required: true,
            type: "number",
            message: "This field is required",
          },
        ],
        hospital_id: [
          {
            required: true,
            type: "number",
            message: "This field is required",
          },
        ],
        assessor_name: [
          {
            required: true,
            message: "This field is required",
          },
        ],
        assessor_email: [
          {
            required: true,
            message: "This field is required",
          },
          {
            type: "email",
            message: "Please enter valid email address",
          },
        ],
      },
      employees: [],
      employeeHospitals: [],
    };
  },
  computed: {
    ...mapGetters("drawer", ["record", "extra"]),
  },
  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),
    ...mapActions("modal", ["open"]),

    updateDynamicFormWithEmployee() {
      const selectedEmployee = this.employees.find(
        (employee) => employee.id === this.formState.employee_id
      );

      if (selectedEmployee && this.dynamic_form) {
        // Clinician Name field
        const clinicianField = this.dynamic_form.sections[0]?.fields?.find(
          (field) =>
            field.label === "Clinician Name" || field.label === "Employee Name"
        );
        if (clinicianField) {
          clinicianField.value = selectedEmployee.full_name;
        }

        // Clinician Role field
        const clinicianRole = this.dynamic_form.sections[0]?.fields?.find(
          (field) => field.label === "Clinician Role"
        );
        if (clinicianRole) {
          const matchedType = constants.employee_types.find(
            (type) => type.value === selectedEmployee.employee_type
          );
          if (matchedType) {
            clinicianRole.value = matchedType.text;
          }
        }
      }
    },
    handleFormTypeChange(selectedKey) {
      console.log("selectedkey", this.selectedForm);
      this.selectedCompetency = null;
      this.groupedOptions = [];
      const groupData = this.$constants[selectedKey];
      if (groupData.options) {
        this.groupedOptions = groupData.map((group) => ({
          label: group.label,
          options: group.options.map((option) => ({
            value: option.value,
            label: option.label,
          })),
        }));
      } else {
        this.groupedOptions = groupData;
      }
    },

    handleFormChange(selectedKey) {
      let key =
        this.$constants.dynamic_compentency_forms.find(
          (e) => e.value == selectedKey
        )?.value ?? null;
      this.dynamic_form = null;

      import("../../../helper/dynamicCompentencyForms/" + key).then((e) => {
        this.dynamic_form = JSON.parse(JSON.stringify(e.default));

        const dateField = this.dynamic_form.sections[0]?.fields?.find(
          (field) =>
            field.label === "Date of Competency" || field.label === "Date"
        );
        if (dateField) {
          dateField.value = moment().format("MM/DD/YYYY");
        }
        this.updateDynamicFormWithEmployee();
      });
    },

    fetchEmployees() {
      return commonService
        .get(this.$constants.getEmployeesUrl, {
          // select: ["id", "first_name", "last_name"],
        })
        .then((res) => {
          this.employees = res.data;
        })
        .catch((err) => {
          this.$message.error(err || "Something went wrong.");
        })
        .finally(() => {
          //
        });
    },

    fetchEmployeeHospitals(id) {
      return commonService
        .get(this.$constants.employeeHospitalShowUrl, {
          id,
          with_hospital: true,
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          this.$message.error(err || "Something went wrong.");
        });
    },

    onSubmit() {
      this.loadingStart();

      const [start_date, end_date] = this.formState.date_range;

      const data = {
        ...this.formState,
        start_date: moment(start_date).format("YYYY-MM-DD"),
        end_date: moment(end_date).format("YYYY-MM-DD"),
      };

      delete data.date_range;

      return commonService
        .store(this.$constants.employeeCompetencyAssessmentPreview, data)
        .then((res) => {
          this.$message.success(res.message);

          return this.open({
            title: "Competency Assessment Preview",
            path: "employee.drawerForm.previewEmail",
            record: {
              data,
              email: res.data.content,
            },
            callback: () => {
              this.$emit("callback");
              this.close();
            },
          });
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => this.loadingStop());
    },
    async handleEmployeeChangeEvent() {
      this.formState.hospital_id = null;
      this.updateDynamicFormWithEmployee();
      const employeeHospitals = await this.fetchEmployeeHospitals(
        this.formState.employee_id
      );
      this.employeeHospitals = employeeHospitals.map((v) => ({
        label: v.hospital.name,
        value: v.hospital_id,
      }));
      return true;
    },

    // async handleEmployeeChangeEvent() {
    //   this.formState.hospital_id = null;
    //   const employeeHospitals = await this.fetchEmployeeHospitals(
    //     this.formState.employee_id
    //   );
    //   this.employeeHospitals = employeeHospitals.map((v) => ({
    //     label: v.hospital.name,
    //     value: v.hospital_id,
    //   }));
    //   return true;
    // },
  },
  async mounted() {
    if (!this.extra?.fromEmployees) {
      await this.fetchEmployees();
    } else {
      this.employees = this.extra.employees;
    }
    this.formState.employee_id = this.record?.employee_id;

    if (this.formState.employee_id) {
      await this.handleEmployeeChangeEvent();
    }

    this.formState.id = this.record?.id;
    this.formState.hospital_id = this.record?.hospital_id;
    this.formState.assessor_email = this.record?.assessor_email;
    this.formState.assessor_name = this.record?.assessor_name;
    if (this.record.start_date) {
      const { start_date, end_date } = this.record;
      this.formState.date_range = [
        moment(start_date, "YYYY-MM-DD"),
        moment(end_date, "YYYY-MM-DD"),
      ];
    }

    this.loading = false;
  },
};
</script>
