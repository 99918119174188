<template>
  <a-layout-content>
    <page-header>
      <template #title> Orders </template>

      <template #buttons>
        <router-link :to="{ name: 'create-order' }">
          <a-button type="primary">
            <a-space> <i class="ti ti-plus ti-lg"></i> Add Order </a-space>
          </a-button>
        </router-link>
      </template>
    </page-header>

    <a-card
      :tabList="tabList"
      :activeTabKey="activeTabKey"
      @tabChange="(key) => (activeTabKey = key)"
      size="small"
    >
      <template #customRender="item">
        {{ item.text }} ({{ item.counts }})
      </template>

      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
        :rowClassName="(record) => (record.is_flagged ? 'flagged-row' : '')"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #action="{ record }">
          <a-space :size="1">
            <router-link
              :to="{
                name: 'view-order',
                params: { order_no: record.order_no },
              }"
            >
              <a-tooltip title="View">
                <a-button type="link" size="small">
                  <i class="ti ti-eye ti-lg"></i>
                </a-button>
              </a-tooltip>
            </router-link>

            <template v-if="record.status == 0">
              <router-link
                :to="{
                  name: 'edit-order',
                  params: { order_no: record.order_no },
                }"
              >
                <a-tooltip title="Edit">
                  <a-button type="link" size="small">
                    <i class="ti ti-pencil ti-lg"></i>
                  </a-button>
                </a-tooltip>
              </router-link>

              <a-popconfirm
                v-if="false"
                title="Are you sure you want to delete this order?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="handleOrderDeleteEvent(record)"
              >
                <a-tooltip title="Delete" placement="bottom">
                  <a-button size="small" type="link" danger>
                    <i class="ti ti-trash ti-lg"></i>
                  </a-button>
                </a-tooltip>
              </a-popconfirm>
            </template>

            <template v-if="[1, 2].includes(record.status)">
              <a-tooltip title="Notes">
                <a-badge :count="record.notes.length" :offset="[-6, 0]">
                  <a-button type="link" size="small" @click="addNotes(record)">
                    <i class="ti ti-notes ti-lg"></i>
                  </a-button>
                </a-badge>
              </a-tooltip>
            </template>
          </a-space>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import { mapActions } from "vuex";
import datatableMixins from "../../mixins/datatableMixins";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.orderDatatableUrl,
      deleteRecordUrl: this.$constants.orderDeleteUrl,
      columns: [
        {
          title: "Order Number",
          dataIndex: "order_no",
          key: "order_no",
          sorter: true,
          customRender: ({ record }) => record.order_no_with_hash,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.order_no
            ? [this.$route.query?.order_no]
            : undefined,
          defaultSortOrder:
            this.$route.query.sortField == "order_no"
              ? this.$route.query.sortOrder
              : undefined,
        },
        {
          title: "Hospital",
          dataIndex: "hospital_name",
          key: "hospital_name",
          customRender: ({ record }) => record.hospital?.name,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.hospital_name
            ? [this.$route.query?.hospital_name]
            : undefined,
        },
        {
          title: "Created By",
          dataIndex: "creator_name",
          key: "creator_name",
          customRender: ({ record }) => record.creator?.full_name,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.creator_name
            ? [this.$route.query?.creator_name]
            : undefined,
        },
        {
          title: "Products/Dispatched/Received",
          dataIndex: "product_info",
          key: "product_info",
          customRender: ({ record }) => {
            return `${record.products.length}/${
              record.products.filter((v) => v.status >= 1).length
            }/${record.products.filter((v) => v.status >= 2).length}`;
          },
        },
        {
          title: "Order Date",
          dataIndex: "order_date",
          key: "order_date",
          sorter: true,
          customRender: ({ record }) => this.$customDate.mdy(record.order_date),
          defaultSortOrder: this.$route.query.sortField
            ? this.$route.query.sortField == "order_date"
              ? this.$route.query.sortOrder
              : undefined
            : "descend",
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          fixed: "right",
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "0",
      tabList: this.$constants.orderStatuses.map((item) => ({
        key: item.value.toString(),
        counts: 0,
        text: item.text,
        status: item.value,
        slots: { tab: "customRender" },
      })),
      defaultFilterParams: {
        order_no: this.$route.query?.order_no
          ? [this.$route.query?.order_no]
          : undefined,
        hospital_name: this.$route.query?.hospital_name
          ? [this.$route.query?.hospital_name]
          : undefined,
        creator_name: this.$route.query?.creator_name
          ? [this.$route.query?.creator_name]
          : undefined,
      },
    };
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    handleOrderDeleteEvent(record) {
      commonService
        .store(this.deleteRecordUrl, { id: record.id })
        .then((res) => {
          if (res.success) {
            this.refresh();
            this.$message.success(res.message);
          } else {
            this.$message.error("Something went wrong.");
          }
        });
    },

    addNotes(record) {
      this.open({
        title: `Order Notes | ${record.order_no_with_hash}`,
        path: "admin.order.notes",
        callback: (notes) => (record.notes = notes),
        record: record,
      });
    },
  },
};
</script>
