<template>
  <a-form
    ref="formRef"
    v-if="formModel"
    :model="formModel.sections"
    layout="vertical"
    @finish="handleFinish"
  >
    <a-card style="margin: 10px 10px 0 10px">
      <div style="text-align: center; font-size: large; font-weight: 500">
        {{ formModel.title }}
      </div>

      <template
        v-for="(section, index) in formModel.sections"
        :key="`${index}_${section.title}_section`"
      >
        <a-divider v-if="section.title" orientation="left">
          {{ section.title }}
        </a-divider>

        <template v-if="section.type == 'fields'">
          <a-row :gutter="[20, 0]">
            <template
              v-for="(field, idx) in section.fields"
              :key="`${idx}_${field.label}_field`"
            >
              <a-col :span="field.span ?? 24">
                <a-form-item
                  :label="field.label"
                  :name="[index, 'fields', idx, 'value']"
                  :rules="field.fillable_by !== loginUser ? null : field.rules"
                >
                  <a-input
                    v-if="field.type == 'a-input'"
                    v-model:value="field.value"
                    :placeholder="field.placeholder"
                    :disabled="field.fillable_by !== loginUser"
                  />

                  <a-date-picker
                    v-else-if="field.type == 'a-date-picker'"
                    v-model:value="field.value"
                    :placeholder="$constants.datepickerFormat"
                    :format="$constants.datepickerFormat"
                    :valueFormat="$constants.datepickerFormat"
                    :disabled="field.fillable_by !== loginUser"
                  />

                  <a-checkbox-group
                    v-else-if="field.type == 'a-checkbox-group'"
                    v-model:value="field.value"
                    :disabled="field.fillable_by !== loginUser"
                  >
                    <a-row>
                      <a-col
                        v-for="(rowOption, index) in field.options"
                        :key="index"
                        :span="rowOption.span"
                        :offset="rowOption.offset || 0"
                      >
                        <a-checkbox :value="rowOption.text">
                          {{ rowOption.text }}
                        </a-checkbox>
                      </a-col>
                    </a-row>
                  </a-checkbox-group>

                  <template v-else-if="field.type == 'a-checkbox'">
                    <a-checkbox
                      v-model:checked="field.value"
                      :disabled="field.fillable_by !== loginUser"
                      >{{ field.title }}</a-checkbox
                    >
                  </template>

                  <template v-else-if="field.type == 'a-textarea'">
                    <a-textarea
                      v-model:value="field.value"
                      :placeholder="field.placeholder"
                      :rows="field.rows"
                      :disabled="field.fillable_by !== loginUser"
                    />
                  </template>

                  <a-radio-group
                    v-else-if="field.type == 'a-radio'"
                    v-model:value="field.value"
                    :disabled="field.fillable_by !== loginUser"
                  >
                    <a-row>
                      <a-col
                        v-for="(rowOption, index) in field.options"
                        :key="index"
                        :span="rowOption.span"
                      >
                        <a-radio :value="rowOption.text">
                          {{ rowOption.text }}
                        </a-radio>
                      </a-col>
                    </a-row>
                  </a-radio-group>
                </a-form-item>
              </a-col>
            </template>
          </a-row>
        </template>

        <template v-else-if="section.type == 'signature'">
          <a-row :gutter="[20, 0]">
            <template
              v-for="(field, idx) in section.fields"
              :key="`${idx}_${field.label}_field`"
            >
              <a-col
                :span="field.span ?? 24"
                :style="{
                  cursor:
                    field.fillable_by !== loginUser ? 'not-allowed' : 'default',
                }"
              >
                <a-form-item :name="[index, 'fields', idx, 'sign']">
                  <sign-box
                    :label="field.label"
                    v-model:sign="field.sign"
                    v-model:signed_at="field.signed_at"
                    :disabled="field.fillable_by !== loginUser"
                  />
                </a-form-item>
              </a-col>
            </template>
          </a-row>
        </template>

        <template
          v-else-if="section.type == 'scoreInfo' && loginUser === 'evaluator'"
        >
          <a-space style="margin-top: 10px" direction="vertical">
            <template
              v-for="(field, idx) in section.fields"
              :key="`${idx}_field`"
            >
              <template v-if="field.type == 'inline-input'">
                <inlineInput
                  v-model="field.value"
                  :text="field.text"
                  :disabled="section.fillable_by === loginUser"
                />
              </template>
              <template v-else-if="field.type == 'text'">
                <p>{{ field.text }}</p>
              </template>
            </template>
          </a-space>
        </template>

        <template v-else-if="section.type == 'questions'">
          <div class="dynamic-questions">
            <template
              v-for="(field, idx) in section.fields"
              :key="`${idx}_${field.label}_field`"
            >
              <a-space>
                <a-form-item v-if="loginUser !== 'employee'">
                  <a-switch
                    v-model:checked="field.isAnswerCorrect"
                    @change="updateInlineInputWithQuestionCount"
                  >
                    <template #checkedChildren><check-outlined /></template>
                    <template #unCheckedChildren><close-outlined /></template>
                  </a-switch>
                </a-form-item>
                <div class="element-block">
                  <span class="element-count">{{ idx + 1 }}.</span>
                  <template v-if="field.title">
                    {{ field.title }}
                  </template>

                  <template v-if="field.type == 'fields'">
                    <a-row :gutter="[16, 8]" align="middle">
                      <template
                        v-for="(innerField, innerIdx) in field.field"
                        :key="`${innerIdx}_${innerField.label}_inner_field`"
                      >
                        <a-col
                          v-bind="innerField.labelSpan"
                          style="text-align: right; vertical-align: center"
                        >
                          {{ innerField.label }}
                        </a-col>
                        <a-col v-bind="innerField.inputSpan">
                          <a-form-item
                            :name="[
                              index,
                              'fields',
                              idx,
                              'field',
                              innerIdx,
                              'value',
                            ]"
                            :rules="
                              section.fillable_by !== loginUser
                                ? null
                                : innerField.rules
                            "
                            style="margin-bottom: 0"
                          >
                            <a-input
                              v-if="innerField.type == 'a-input'"
                              v-model:value="innerField.value"
                              :placeholder="innerField.placeholder"
                              :suffix="innerField.suffix"
                              :disabled="section.fillable_by !== loginUser"
                            />
                          </a-form-item>
                        </a-col>
                        <a-col v-bind="innerField.offsetSpan" />
                      </template>
                    </a-row>
                  </template>

                  <template v-else-if="field.type == 'a-switch'">
                    <a-space>
                      <toggleSwitch
                        v-model="field.value"
                        :disabled="section.fillable_by !== loginUser"
                      />
                      {{ field.text }}
                    </a-space>
                  </template>

                  <template v-else-if="field.type == 'draggable'">
                    <draggable
                      tag="ol"
                      class="list-group"
                      :list="field.value"
                      handle=".list-group-item"
                      v-bind="{
                        animation: 200,
                        ghostClass: 'ghost',
                      }"
                      item-key="order"
                      :disabled="section.fillable_by !== loginUser"
                    >
                      <template #item="{ element }">
                        <li
                          :class="{
                            'list-group-item':
                              section.fillable_by === loginUser,
                          }"
                        >
                          <i class="ti ti-grip-vertical"></i>
                          {{ element.text }}
                        </li>
                      </template>
                    </draggable>
                  </template>

                  <template v-else-if="field.type == 'inline-input'">
                    <inlineInput
                      v-model="field.value"
                      :text="field.text"
                      :disabled="section.fillable_by !== loginUser"
                    />
                  </template>

                  <template v-else>
                    <a-form-item
                      :label="field.label"
                      :name="[index, 'fields', idx, 'value']"
                      :rules="
                        section.fillable_by !== loginUser ? null : field.rules
                      "
                      style="margin-bottom: 0; margin-left: 16px"
                    >
                      <a-input
                        v-if="field.type == 'a-input'"
                        v-model:value="field.value"
                        :placeholder="field.placeholder"
                        :disabled="section.fillable_by !== loginUser"
                      />
                      <a-checkbox-group
                        v-else-if="field.type == 'a-checkbox-group'"
                        v-model:value="field.value"
                        :disabled="section.fillable_by !== loginUser"
                      >
                        <a-row
                          v-for="(rowOption, index) in field.options"
                          :key="index"
                        >
                          <a-col :span="rowOption.span">
                            <a-checkbox :value="rowOption.text">
                              {{ rowOption.text }}
                            </a-checkbox>
                          </a-col>
                        </a-row>
                      </a-checkbox-group>

                      <a-radio-group
                        v-else-if="field.type == 'a-radio'"
                        v-model:value="field.value"
                        :disabled="section.fillable_by !== loginUser"
                      >
                        <a-row
                          v-for="(rowOption, index) in field.options"
                          :key="index"
                        >
                          <a-col :span="rowOption.span">
                            <a-radio :value="rowOption.text">
                              {{ rowOption.text }}
                            </a-radio>
                          </a-col>
                        </a-row>
                      </a-radio-group>
                    </a-form-item>
                  </template>
                </div>
              </a-space>
            </template>
          </div>
        </template>
      </template>

      <a-button type="primary" html-type="submit" :loading="loading">
        Submit
      </a-button>
    </a-card>
  </a-form>
</template>

<script>
import { CheckOutlined, CloseOutlined } from "@ant-design/icons-vue";
import draggable from "vuedraggable";
import { mapGetters } from "vuex";
import signBox from "../components/dynamicCompetencyForm/dynamicCompetencyFormSignBox.vue";
import inlineInput from "../components/dynamicCompetencyForm/inlineInput.vue";
import tableHead from "../components/dynamicCompetencyForm/tableHead.vue";
import toggleSwitch from "../components/dynamicCompetencyForm/toggleSwitch.vue";
import { commonService } from "../services";

export default {
  components: {
    signBox,
    tableHead,
    draggable,
    toggleSwitch,
    inlineInput,
    CheckOutlined,
    CloseOutlined,
  },
  props: {
    formModel: { type: Object, required: true },
    fillableBy: { type: String, required: true },
    recordId: { type: Number, required: true },
    formState: { type: Object, required: false },
    data: { type: Object, required: false },
    // selectedForm : {type : String , required : true }
  },
  data() {
    return {
      loading: false,
      isAnswerCorrect: false,
      isFormModel: false,
      isModalVisible: false,
      formVisible: false,
      exam_data: null,
      numFields: null,
      count: 0,
      questionsSection: null,
      // selectedForm: null,
      // dynamic_form: null,
      // loginUser: "evaluator",
    };
  },

  computed: {
    ...mapGetters("auth", ["user", "isLoggedIn"]),

    loginUser() {
      return this.fillableBy;
    },

    signatureFields() {
      return this.formModel.sections.find(
        (section) => section.type === "signature"
      );
    },

    isSigned() {
      return this.signatureFields.fields.find(
        (field) => field.sign != null && field.fillable_by === this.loginUser
      );
    },
  },

  methods: {
    updateInlineInputWithQuestionCount() {
      let currectAnswerCounts;

      let questionSection = this.formModel.sections.find(
        (section) => section.type === "questions"
      );

      if (questionSection) {
        currectAnswerCounts = questionSection.fields.filter(
          (field) => field.isAnswerCorrect === true
        ).length;
      }

      this.formModel.sections = this.formModel.sections.map((section) => {
        if (section.type == "scoreInfo") {
          section.fields = section.fields.map((field) => {
            if (field.type == "inline-input") {
              field.value[0] = currectAnswerCounts;
            }

            return field;
          });
        }

        return section;
      });
    },

    handleFinish() {
      if (this.isSigned == null) {
        this.$message.error("Signature can not be empty.");
        return;
      }

      this.loading = true;
      if (this.loginUser === "employee") {
        commonService
          .store(this.$constants.CompentencyExamFormSubmit, {
            id: this.recordId,
            data: this.formModel,
          })
          .then((res) => {
            this.$message.success(res.message);
            this.$router.push({ name: "competency-exam" });
          })
          .catch((error) => {
            this.$message.error(error);
          })
          .finally(() => {
            this.loading = false;
          });
      } else if (!this.isLoggedIn) {
        commonService
          .store(this.$constants.managerCompentencyExamSubmit, {
            email: this.formState.email,
            token: this.formState.token,
            data: this.formModel,
          })
          .then((res) => {
            this.$message.success(res.message);
            this.formState.isCompleted = true;
          })
          .catch((error) => {
            this.$message.error(error);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        if (this.user.role_id === 3) {
          commonService
            .store(this.$constants.managerCompentencyExamSubmit, {
              id: this.recordId,
              data: this.formModel,
            })
            .then((res) => {
              this.$message.success(res.message);
              this.$router.push({ name: "manager.competency-exam" });
            })
            .catch((error) => {
              this.$message.error(error);
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          commonService
            .store(this.$constants.adminCompentencyExamSubmit, {
              id: this.recordId,
              data: this.formModel,
            })
            .then((res) => {
              this.$message.success(res.message);
              this.$router.push({
                name: "admin-competency-exam",
              });
            })
            .catch((error) => {
              this.$message.error(error);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }
    },

    // handleValidationFailure({ errorFields }) {
    //   console.log({ errorFields });
    //   if (errorFields && errorFields.length) {
    //     const firstErrorField = errorFields[0].name;
    //     const fieldRefName = firstErrorField.join("_");
    //     const fieldElement = document.querySelector(`[id="${fieldRefName}"]`);
    //     if (fieldElement) {
    //       fieldElement.scrollIntoView({ behavior: "smooth", block: "start" });
    //     }
    //   }
    // },
  },
};
</script>

<style lang="less">
table.bordered {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
  th,
  td {
    border: 1px solid #adadad;
    padding: 4px 10px;
  }
  th {
    text-align: center;
  }
}

.list-group {
  .list-group-item {
    cursor: move;
    margin-bottom: 0px;
    i {
      cursor: pointer;
    }
  }
  .button {
    margin-top: 35px;
  }
  .flip-list-move {
    transition: transform 0.5s;
  }
  .no-move {
    transition: transform 0s;
  }
  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
}

.dynamic-questions {
  display: flex;
  flex-flow: column;
  row-gap: 8px;
  .element-block {
    position: relative;
    padding: 4px 18px 4px 32px;
    border: 1px solid var(--ck-color-base-border);
    border-radius: var(--ck-border-radius);
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
    .element-count {
      position: absolute;
      top: 0;
      left: 0;
      width: 22px;
      height: 20px;
      background-color: var(--ck-color-base-border);
      text-align: center;
      border-top-left-radius: var(--ck-border-radius);
      border-bottom-right-radius: 8px;
      font-size: 12px;
    }
  }
}
</style>
