<template>
  <a-layout-content>
    <page-header>
      <template #title> Form W-9 </template>
      <template #buttons>
        <!--  -->
      </template>
    </page-header>

    <a-card
      :tabList="tabList"
      :activeTabKey="activeTabKey"
      @tabChange="(key) => (activeTabKey = key)"
      size="small"
    >
      <template #customRender="item">
        {{ item.text }} ({{ item.counts }})
      </template>

      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #action="{ record }">
          <a-space :size="1">
            <a-tooltip title="Preview W-9">
              <a-button type="link" size="small" @click="previewPDF(record)">
                <a-space>
                  <i class="ti ti-eye ti-lg"></i>
                </a-space>
              </a-button>
            </a-tooltip>

            <template v-if="record.status == 0">
              <a-popconfirm
                title="Are you sure you want to approve this Form W-9 submission?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="
                  handleStatusChangeEvent({ employee_id: record.employee_id })
                "
              >
                <a-tooltip title="Approve">
                  <a-button size="small" type="link">
                    <i class="ti ti-check ti-lg" style="color: green"></i>
                  </a-button>
                </a-tooltip>
              </a-popconfirm>

              <a-tooltip title="Add Comment">
                <a-button
                  size="small"
                  type="link"
                  @click="handleAddCommentEvent(record)"
                >
                  <i class="ti ti-message ti-lg" style="color: darkorange"></i>
                </a-button>
              </a-tooltip>
            </template>
          </a-space>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import { commonService } from "@/services";
import {
  EditOutlined,
  DeleteOutlined,
  RestOutlined,
  PlusOutlined,
} from "@ant-design/icons-vue";
import datatableMixins from "../../../mixins/datatableMixins";
import { mapActions } from "vuex";

export default {
  mixins: [datatableMixins],

  components: {
    EditOutlined,
    DeleteOutlined,
    RestOutlined,
    PlusOutlined,
  },

  data() {
    return {
      datatableUrl: this.$constants.formW9Datatable,
      statusChangeUrl: this.$constants.formW9Update,
      columns: [
        {
          title: "Employee",
          key: "employee_name",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "employee_name",
          },
          customRender: ({ record }) => record.employee.full_name,
        },
        {
          title: "Created Date",
          dataIndex: "created_at",
          key: "created_at",
          sorter: true,
          defaultSortOrder: "descend",
          customRender: ({ text }) => this.$customDate.ll(text),
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          fixed: "right",
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "0",
      tabList: [
        {
          key: "0",
          counts: 0,
          text: "Under Review",
          status: 0,
          slots: { tab: "customRender" },
        },
        {
          key: "1",
          counts: 0,
          text: "Approved",
          status: 1,
          slots: { tab: "customRender" },
        },
        {
          key: "2",
          counts: 0,
          text: "Terminated",
          status: 2,
          slots: { tab: "customRender" },
        },
      ],
    };
  },

  methods: {
    ...mapActions("navbarCount", ["setCountForKey"]),

    previewPDF(record) {
      commonService
        .renderFile(
          this.$constants.formW9Preview,
          record.data,
          "application/pdf;base64"
        )
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.pdfLoading = false;
        });
    },

    handleAddCommentEvent(record) {
      this.$store.dispatch("drawer/open", {
        title: "Feedback",
        path: "admin.formW9.comment",
        record,
        callback: this.refresh,
      });
    },
  },

  watch: {
    tabList: {
      handler(val) {
        const underReviewTab = val.find((tab) => tab.status === 0);
        this.setCountForKey({
          key: "total_pending_form_w9",
          count: underReviewTab.counts,
        });
      },
      deep: true,
    },
  },
};
</script>
