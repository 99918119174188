<template>
  <a-layout-content>
    <page-header>
      <template #title> Policy Rule Documents </template>

      <template #buttons>
        <router-link
          :to="{
            name: 'policy-rule-documents.store',
          }"
        >
          <a-button type="primary">
            <a-space>
              <i class="ti ti-plus ti-lg"></i> Add New Policy Rule Document
            </a-space>
          </a-button>
        </router-link>
      </template>
    </page-header>

    <a-card
      size="small"
      :tabList="tabList"
      :activeTabKey="activeTabKey"
      @tabChange="(key) => (activeTabKey = key)"
    >
      <template #customRender="item">
        {{ item.text }} ({{ item.counts }})
      </template>

      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
      >
        <template #type="{ record }">
          {{
            $comman.getValueFromObject(
              record,
              "type",
              $constants.onboardingDocTypes
            )
          }}
        </template>

        <template #is_for_all_position="{ record }">
          {{
            record.is_for_all_position ? "All" : formatEmployeePositions(record)
          }}
        </template>

        <template #action="{ record }">
          <a-space :size="1">
            <a-tooltip title="View">
              <router-link
                :to="{
                  name: 'policy-rule-documents.show',
                  params: { id: record.id },
                }"
              >
                <a-button type="link" size="small">
                  <i class="ti ti-eye ti-lg"></i>
                </a-button>
              </router-link>
            </a-tooltip>

            <a-tooltip v-if="record.status == 1" title="Edit">
              <router-link
                :to="{
                  name: 'policy-rule-documents.edit',
                  params: { id: record.id },
                }"
              >
                <a-button type="link" size="small">
                  <i class="ti ti-pencil ti-lg"></i>
                </a-button>
              </router-link>
            </a-tooltip>

            <a-tooltip
              v-if="record.is_issue_certificate"
              title="Preview Certificate"
            >
              <a-button
                :loading="record.previewCertificateLoader"
                type="link"
                size="small"
                danger
                @click="showCertificatePreview(record)"
              >
                <i class="ti ti-file-text ti-lg"></i>
              </a-button>
            </a-tooltip>

            <a-tooltip v-else title="Preview PDF">
              <a-button
                :loading="record.previewLoader"
                type="link"
                size="small"
                @click="previewPDF(record)"
              >
                <i class="ti ti-file-text ti-lg"></i>
              </a-button>
            </a-tooltip>

            <a-popconfirm
              v-if="record.status == 1"
              title="Are you sure you want to delete this policy rule?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="deletePolicyRule(record)"
            >
              <a-tooltip title="Delete">
                <a-button type="link" size="small" danger>
                  <i class="ti ti-trash ti-lg"></i>
                </a-button>
              </a-tooltip>
            </a-popconfirm>
          </a-space>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import datatableMixins from "../../../mixins/datatableMixins";
import { commonService } from "../../../services";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.policyRuleDocumentsUrl,
      columns: [
        {
          title: "#",
          dataIndex: "id",
          key: "id",
          sorter: true,
          width: 50,
          customRender: ({ index }) => index + 1,
        },
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          sorter: true,
          customRender: ({ text }) => text ?? "N/A",
        },
        {
          title: "Policy Group",
          dataIndex: "policy_group.name",
        },
        {
          title: "Type",
          dataIndex: "type",
          key: "type",
          slots: {
            customRender: "type",
          },
        },
        {
          title: "Employee Positions",
          dataIndex: "is_for_all_position",
          key: "is_for_all_position",
          slots: {
            customRender: "is_for_all_position",
          },
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? 1,
      tabList: this.$constants.policyRuleDocumentStatuses.map((item) => ({
        key: item.value.toString(),
        counts: 0,
        text: item.text,
        status: item.value,
        slots: { tab: "customRender" },
      })),

      employeePositions: [],
    };
  },

  mounted() {
    this.employeePositions = this.$comman.getEmployeePositions();
  },

  methods: {
    formatEmployeePositions(record) {
      const positions = record.employee_positions;
      if (!positions) return "No";

      const positionNames = positions.map(
        (position) => position?.name ?? "N/A"
      );
      return positionNames.join(", ");
    },
    previewPDF(record) {
      record.previewLoader = true;
      commonService
        .renderFile(
          this.$constants.policyRuleDocumentPDFPreviewUrl,
          { id: record.id },
          "application/pdf;base64"
        )
        .then((res) => {
          // this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          delete record.previewLoader;
        });
    },

    showCertificatePreview(record) {
      record.previewCertificateLoader = true;
      commonService
        .renderFile(
          this.$constants.policyRuleDocumentCertificatePreviewUrl,
          {
            certificate_content: record.certificate_content,
            certificate_name: record.name,
          },
          "application/pdf;base64"
        )
        .then((res) => {
          // this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          record.previewCertificateLoader = false;
        });
    },

    deletePolicyRule(record) {
      commonService
        .store(this.$constants.policyRuleDocumentChangeStatusUrl, {
          id: record.id,
          status: 0,
        })
        .then((res) => {
          this.$message.success(res.message);
        })
        .finally((err) => {
          this.refresh();
        });
    },
  },
};
</script>
