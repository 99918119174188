<template>
  <a-card
    :tabList="tabList"
    :activeTabKey="activeTabKey"
    @tabChange="(key) => (activeTabKey = key)"
    size="small"
  >
    <template #customRender="item">
      {{ item.text }} ({{ item.counts }})
    </template>

    <a-table
      :columns="columns"
      :rowKey="(record, index) => record.id"
      :dataSource="dataSource"
      :pagination="pagination"
      :loading="datatableLoading"
      @change="handleTableChange"
      @refresh="refresh"
      size="small"
      :scroll="scroll"
    >
      <template #filterDropdown="filterDropdown">
        <xFilterInputSearchDropdown
          :filterDropdown="filterDropdown"
          @handleSearch="handleDatatableSearch"
          @handleReset="handleDatatableReset"
        />
      </template>

      <template #filterIcon="filterIcon">
        <xFilterIcon :filterIcon="filterIcon" />
      </template>

      <template #action="{ record }">
        <a-space :size="1">
          <template
            v-if="record.status === 2 && user.email === record.assessor_email"
          >
            <router-link
              :to="{
                name: 'admin-competency-assessment-new-show',
                params: { id: record.id },
              }"
            >
              <a-tooltip title="View">
                <a-button type="link" size="small">
                  <i class="ti ti-eye ti-lg"></i>
                </a-button>
              </a-tooltip>
            </router-link>
          </template>

          <a-popconfirm
            v-if="
              [0, 1].includes(record.status) ||
              (record.status === 2 && user.email !== record.assessor_email)
            "
            title="Are you sure you want to send this assessment?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="handleSendCompetencyAssessment(record)"
          >
            <a-tooltip title="Send Mail">
              <a-button type="link" size="small">
                <i class="ti ti-mail ti-lg"></i>
              </a-button>
            </a-tooltip>
          </a-popconfirm>

          <a-tooltip v-if="record.status != 0" title="Mail Logs">
            <a-button
              type="link"
              size="small"
              @click="showOfferMailLog(record)"
            >
              <i class="ti ti-history ti-lg"></i>
            </a-button>
          </a-tooltip>
          <a-tooltip title="Print">
            <a-button type="link" size="small" @click="renderPdf(record)">
              <i class="ti ti-printer ti-lg"></i>
            </a-button>
          </a-tooltip>

          <template v-if="record.status === 0">
            <a-popconfirm
              title="Are you sure you want to delete this assessment?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleAssessmentDeleteEvent(record)"
            >
              <a-tooltip title="Delete" placement="bottom">
                <a-button size="small" type="link" danger>
                  <i class="ti ti-trash ti-lg"></i>
                </a-button>
              </a-tooltip>
            </a-popconfirm>
          </template>
        </a-space>
      </template>
    </a-table>
  </a-card>
</template>

<script>
import { PlusOutlined } from "@ant-design/icons-vue";
import datatableMixins from "../../../mixins/datatableMixins";
import { mapGetters } from "vuex";

export default {
  components: {
    PlusOutlined,
  },

  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.adminCompentencyFormUrl,
      modifyUrlQuery: false,
      columns: [
        // {
        //   title: "Hospital",
        //   dataIndex: "hospital.name",
        //   key: "hospital_name",
        //   slots: {
        //     filterDropdown: "filterDropdown",
        //     filterIcon: "filterIcon",
        //   },
        //   defaultFilteredValue: this.$route.query?.hospital_name
        //     ? [this.$route.query?.hospital_name]
        //     : undefined,
        // },
        {
          title: "Date Range",
          dataIndex: "assessor_email",
          key: "assessor_email",
          customRender: ({ record }) => {
            const startDate = this.$customDate.dmy(record.start_date);
            const endDate = this.$customDate.dmy(record.end_date);

            if (!record.start_date && !record.end_date) {
              return "N/A";
            }
            return `${startDate} to ${endDate}`;
          },
        },

        {
          title: "Assessor Name",
          dataIndex: "assessor_name",
          key: "assessor_name",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.assessor_name
            ? [this.$route.query?.assessor_name]
            : undefined,
        },
        {
          title: "Assessor Email",
          dataIndex: "assessor_email",
          key: "assessor_email",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.assessor_email
            ? [this.$route.query?.assessor_email]
            : undefined,
        },
        {
          title: "Created By",
          dataIndex: "creator_name",
          key: "creator_name",
          customRender: ({ record }) => record?.creator?.fl_name ?? "",
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          fixed: "right",
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "0",
      tabList: this.$constants.competenceAssessmentFormStatusesAdmin.map(
        (item) => ({
          key: item.value.toString(),
          counts: 0,
          text: item.text,
          status: item.value,
          slots: { tab: "customRender" },
        })
      ),
      extraFilters: {
        employee_id: parseInt(this.$route.params.id),
      },
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    //
  },

  watch: {
    "$route.params.id": function (val) {
      if (val) {
        this.refresh({ employee_id: parseInt(val) });
      }
    },
  },
};
</script>
