<template>
  <a-row type="flex" justify="center">
    <a-space direction="vertical">
      <p style="text-align: center; margin-bottom: 0; font-weight: 500">
        {{ label }}
      </p>
      <div class="signature-pad-wrapper" :class="{ disabled: disabled }">
        <template v-if="sign && disabled">
          <div style="text-align: center">
            <img :width="200" :src="sign" />
            <br />
            <br />
            <span style="color: black">{{ employeeName }}</span>
            <br />
            {{ signed_at }}
          </div>
        </template>
        <template v-else>
          <vue-signature-pad
            :disabled="disabled"
            :options="{ onEnd: saveSign }"
            ref="signPad"
            :customStyle="$constants.signPadCustomCss"
          />
        </template>
      </div>
      <a-row type="flex" justify="center">
        <a-col>
          <template v-if="hasNoteSlot">
            <slot name="note" />
          </template>
          <template v-else>
            <a-typography-paragraph>
              {{ note }}
            </a-typography-paragraph>
          </template>
        </a-col>
      </a-row>
      <a-row justify="center">
        <a-space>
          <a-button
            v-if="sign && sign.length > 0 && !disabled"
            htmlType="button"
            type="primary"
            danger
            ghost
            @click="clearSign"
          >
            Clear
          </a-button>

          <slot name="buttons" />
        </a-space>
      </a-row>
    </a-space>
  </a-row>
</template>

<script>
import { useSlots } from "vue";

export default {
  inject: ["employeeName"],
  name: "dynamic-competency-form-sign-box",
  props: {
    label: {
      type: [String, null],
    },
    sign: {
      type: [String, null],
      required: true,
    },
    signed_at: {
      type: [String, null],
      required: true,
    },
    note: {
      type: String,
      default:
        "Signature indicates that the information reported is complete and accurate.",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:sign", "update:signed_at"],
  computed: {
    hasNoteSlot() {
      const slots = useSlots();
      return slots.note;
    },
  },

  methods: {
    clearSign() {
      if (this.disabled) return;
      this.$emit("update:sign", null);
      this.$emit("update:signed_at", null);
      this.$refs.signPad.clearSignature();
    },
    saveSign() {
      if (this.disabled) return;
      const { isEmpty, data } = this.$refs.signPad.saveSignature();
      if (isEmpty) {
        this.$message.error("Signature can not be empty.");
        return Promise.reject();
      }
      this.$emit("update:sign", data);
      this.$emit(
        "update:signed_at",
        this.$customDate.mdyhis(this.$comman.momentTz())
      );
      return Promise.resolve();
    },
  },
};
</script>
<style scoped>
.signature-pad-wrapper {
  position: relative;
}

.signature-pad-wrapper.disabled {
  pointer-events: none;
  opacity: 0.6;
}
</style>
